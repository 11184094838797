export const PlusIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M11.0371 7.46265V14.6111"
      stroke={props.stroke || '#2D3139'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.6148 11.0368H7.45898"
      stroke={props.stroke || '#2D3139'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.2998 11.0369C1.2998 3.73479 3.73454 1.30005 11.0366 1.30005C18.3388 1.30005 20.7735 3.73479 20.7735 11.0369C20.7735 18.339 18.3388 20.7737 11.0366 20.7737C3.73454 20.7737 1.2998 18.339 1.2998 11.0369Z"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
