export const OpenMessage = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
    <g id="_18-Mail" data-name="18-Mail">
      <path d="M28,32H4a4,4,0,0,1-4-4V12A4,4,0,0,1,4,8H6a1,1,0,0,1,0,2H4a2,2,0,0,0-2,2V28a2,2,0,0,0,2,2H28a2,2,0,0,0,2-2V12a2,2,0,0,0-2-2H26a1,1,0,0,1,0-2h2a4,4,0,0,1,4,4V28A4,4,0,0,1,28,32Z" />
      <path d="M16,22a1,1,0,0,1-.618-.214l-14-11A1,1,0,1,1,2.618,9.214L16,19.728,29.382,9.214a1,1,0,1,1,1.236,1.572l-14,11A1,1,0,0,1,16,22Z" />
      <path d="M2,31a1,1,0,0,1-.707-1.707l11-11a1,1,0,0,1,1.414,1.414l-11,11A1,1,0,0,1,2,31Z" />
      <path d="M30,31a1,1,0,0,1-.707-.293l-11-11a1,1,0,0,1,1.414-1.414l11,11A1,1,0,0,1,30,31Z" />
      <path d="M26,14a1,1,0,0,1-1-1V2H7V13a1,1,0,0,1-2,0V2A2,2,0,0,1,7,0H25a2,2,0,0,1,2,2V13A1,1,0,0,1,26,14Z" />
      <path d="M21,7H11a1,1,0,0,1,0-2H21a1,1,0,0,1,0,2Z" />
      <path d="M21.064,12H11a1,1,0,0,1,0-2H21.064a1,1,0,0,1,0,2Z" />
    </g>
  </svg>
);
