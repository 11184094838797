import { ForwardedRef, forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

// TODO: font family

export const NoteTypes = {
  xl: 'text-2xl',
  l: 'text-lg',
  m: 'text-base',
  s: 'text-sm',
  xs: 'text-xs',
};

export type NoteSizeType = keyof typeof NoteTypes;

interface INote {
  type: NoteSizeType;
  className?: string;
}

export const commonClassName = 'transistion-colors break-words mb-0';

function NoteComponent(
  { children, type, className }: React.PropsWithChildren<INote>,
  ref: ForwardedRef<HTMLSpanElement>,
) {
  return (
    <span
      ref={ref}
      className={twMerge(
        commonClassName,
        'text-note font-inter font-normal',
        NoteTypes[type],
        className,
      )}>
      {children}
    </span>
  );
}

export const Note = forwardRef<HTMLSpanElement, React.PropsWithChildren<INote>>(NoteComponent);

export const TextTypes = {
  xl: 'text-xl font-semibold',
  l: 'text-lg',
  m: 'text-base',
  s: 'text-sm',
  xs: 'text-xs',
};

type TextType = keyof typeof TextTypes;

export interface IText {
  type: TextType;
  className?: string;
  style?: React.CSSProperties;
}

export function Text({ children, type, className, style }: React.PropsWithChildren<IText>) {
  return (
    <p
      className={twMerge(commonClassName, 'text-subtitle font-inter', TextTypes[type], className)}
      style={style}>
      {children}
    </p>
  );
}

export const TitleSizes = {
  xl: 'text-5xl font-black',
  l: 'text-4xl font-black',
  mm: 'text-3xl font-extrabold',
  m: 'text-2xl font-extrabold',
  sm: 'text-xl font-semibold',
  s: 'text-lg font-semibold',
  xs: 'text-base font-semibold',
};

interface ITitle {
  type: keyof typeof TitleSizes;
  className?: string;
}

export function Title({ children, type, className }: React.PropsWithChildren<ITitle>) {
  return (
    <h1 className={twMerge(commonClassName, 'text-title font-nunito', TitleSizes[type], className)}>
      {children}
    </h1>
  );
}

const SubtitleSizes = {
  m: 'text-base',
};

interface ISubtitle {
  type: keyof typeof SubtitleSizes;
  className?: string;
}

export function Subtitle({ children, type, className }: React.PropsWithChildren<ISubtitle>) {
  return (
    <h4
      className={twMerge(
        commonClassName,
        'text-subtitle font-inter font-medium',
        SubtitleSizes[type],
        className,
      )}>
      {children}
    </h4>
  );
}

const LabelSizes = {
  m: 'text-xl',
};

export type LabelSyzeType = keyof typeof SubtitleSizes;

interface ILabel {
  type: LabelSyzeType;
  className?: string;
}

export function Label({ children, type, className }: React.PropsWithChildren<ILabel>) {
  return (
    <label
      className={twMerge(
        commonClassName,
        'text-title font-nunito block font-semibold',
        LabelSizes[type],
        className,
      )}>
      {children}
    </label>
  );
}
