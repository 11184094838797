'use client';
import { IMessage, IMessagesQueryParams } from '@ui/components/organisms/message';
import { PostItemAuthor } from './post-item-author';
import { ContentType, ContextUserType } from '@ui/constants/context';
import { useMessageContext } from '@ui/components/organisms/message';
import { MessageItemDescription } from '@ui/components/organisms/message/components/message-list/message-item-description';
import { PostItemsMoreOptions } from './post-item-more-option';
import { MessageItemFiles } from '@ui/components/organisms/message/components/message-list/message-item-files';
import Link from 'next/link';
import React, { ReactNode, useRef, forwardRef, useId, useCallback, useState } from 'react';
import { PostItemReactions } from './post-item-reactions';
import { twMerge } from 'tailwind-merge';
import { useMessageUpdateFormContext } from '@ui/contexts/message-update-form-context';
import PostItemAnalyticsModal from './post-item-analytics';
import { ItemUpdateMessageContentWrapper } from '../common/item-update-message-content-wrapper';
import { Icon } from '@ui/components';

interface Props {
  post: IMessage;
  userType: ContextUserType;
  userId: string;
  creator_id: string;
  communityPostRoute: string;
  isOwnPage?: boolean;
  isLastPost?: boolean;
  isPinned?: boolean;
  query: IMessagesQueryParams;
  contentType: ContentType;
}

interface ConditionalWrapperProps {
  condition: boolean;
  wrapper: (children: ReactNode) => JSX.Element;
  children: ReactNode;
  id: string;
}

const ConditionalPostLinkWrapper = forwardRef<HTMLDivElement, ConditionalWrapperProps>(
  ({ condition, wrapper, children }, ref) =>
    condition ? <div ref={ref}>{wrapper(children)}</div> : <div ref={ref}>{children}</div>,
);

ConditionalPostLinkWrapper.displayName = 'ConditionalPostLinkWrapper';

const PostItem = ({
  post,
  userType,
  userId,
  communityPostRoute,
  isOwnPage,
  isLastPost,
  query,
  creator_id,
  contentType,
  isPinned,
}: Props) => {
  const id = useId();
  const updateRef = useRef<HTMLDivElement>(null);
  const { setTargetMessage } = useMessageUpdateFormContext();
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const messageState = useMessageContext('creator');

  const onUpdateTrigger = useCallback(() => {
    if (updateRef.current) {
      setTargetMessage({
        ...post,
        isPost: true,
        targetRef: updateRef.current,
        type: 'post',
        query,
      });
    }
  }, [post, setTargetMessage]);

  const onOpenPostAnalytics = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      messageState.onGetPostAnalytics(post?.id);
      setTimeout(() => {
        setIsModalOpen(true);
      }, 0);
    },
    [post?.id, messageState],
  );

  return (
    <ItemUpdateMessageContentWrapper ref={updateRef}>
      <ConditionalPostLinkWrapper
        id={id}
        condition={!isOwnPage}
        wrapper={(children) => (
          <Link
            href={`${communityPostRoute}?slug=${post.slug || post.id}`}
            className={twMerge(
              'border-input hover:bg-input/50 block space-y-4 border-b px-4 py-2 pb-4 md:rounded-md md:border ',
              isLastPost && 'border-b-0',
              isPinned && 'border-primaryLight',
            )}>
            {children}
          </Link>
        )}>
        <div className={twMerge('space-y-4', 'flex flex-col justify-between')}>
          <div className="flex justify-between">
            <PostItemAuthor
              post={post}
              owner={post.user_id === creator_id ? 'creator' : 'fan'}
              userType={userType}
              creatorId={creator_id}
            />
            {userType === 'creator' && (
              <div className="flex items-center">
                <div
                  className="p-2 cursor-pointer rounded hover:bg-gray-200"
                  onClick={onOpenPostAnalytics}>
                  <Icon type="slimGraph" />
                </div>

                <PostItemsMoreOptions
                  post={post}
                  userType={userType}
                  userId={userId}
                  enableContentFlagging={false}
                  onUpdateTrigger={onUpdateTrigger}
                  dropdownOpen={dropDownOpen}
                  setDropdownOpen={setDropDownOpen}
                  contentType={contentType}
                />
              </div>
            )}
          </div>
          {post.name && <p className="font-nunito text-md font-bold">{post.name}</p>}
          <MessageItemDescription
            truncate
            amountOfWords={isPinned ? 35 : undefined}
            message={post}
            isOwnPage={isOwnPage}
            useCase="posts"
            className="max-w-fit rounded-none bg-transparent p-0"
          />
          <MessageItemFiles files={post.files} className="max-w-full" isPinned={isPinned} />
          <PostItemReactions
            post={post}
            userType={userType}
            hierarchyType="post"
            onTriggerReplyForm={() => {}}
            enableReply={contentType === 'post'} // TODO: true will enable reply to replies or nested reply
          />
        </div>
        {isModalOpen && (
          <PostItemAnalyticsModal
            isOpen={isModalOpen}
            isLoading={messageState?.isAnalyticsLoading}
            data={messageState?.analyticsData}
            postName={post.name}
            onClose={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setIsModalOpen(false);
            }}
          />
        )}
      </ConditionalPostLinkWrapper>
    </ItemUpdateMessageContentWrapper>
  );
};

export default React.memo(PostItem);
