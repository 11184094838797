export type TViewState = 'empty' | 'list' | 'loading' | 'form' | 'error' | 'settings';

export const VIEW_STATE: Record<TViewState, TViewState> = {
  empty: 'empty',
  list: 'list',
  loading: 'loading',
  form: 'form',
  error: 'error',
  settings: 'settings',
};
