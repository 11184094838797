export const DeleteIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={19} height={21} fill="none" {...props}>
    <path
      stroke="#CB2401"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M15.889 8.555c0 8.019 1.154 11.643-6.61 11.643S2.693 16.575 2.693 8.555M17.365 5.48H1.215M12.715 5.48s.528-3.765-3.426-3.765c-3.953 0-3.425 3.766-3.425 3.766"
    />
  </svg>
);
