import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  ReactNode,
  useEffect,
} from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Button, Icon } from '@ui/components';
import { useClickOutside } from '../clicks/use-click-outside';

interface ImagePreviewContextProps {
  setImagePreview: (src: string) => void;
  closePreview: () => void;
}

const ImagePreviewContext = createContext<ImagePreviewContextProps | undefined>(undefined);

export const useImagePreviewContext = (): ImagePreviewContextProps => {
  const context = useContext(ImagePreviewContext);
  if (!context) {
    throw new Error('useImagePreviewContext must be used within an ImagePreviewProvider');
  }
  return context;
};

interface ImagePreviewProviderProps {
  children: ReactNode;
}

export const ImagePreviewProvider: React.FC<ImagePreviewProviderProps> = ({ children }) => {
  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const setImagePreview = useCallback((src: string) => setImageSrc(src), []);
  const closePreview = useCallback(() => setImageSrc(null), []);
  const ref = useClickOutside<HTMLImageElement>(() => closePreview());

  useEffect(() => {
    if (!imageSrc) return;

    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        closePreview();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [imageSrc, closePreview]);

  return (
    <ImagePreviewContext.Provider value={{ setImagePreview, closePreview }}>
      {children}
      <AnimatePresence>
        {imageSrc && (
          <motion.div
            key="preview"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 z-50 flex items-center justify-center backdrop-blur-sm"
            style={{ margin: 0 }}>
            <img
              ref={ref}
              src={imageSrc}
              alt="Preview"
              className="max-h-full max-w-full object-contain sm:max-h-[90vh] sm:max-w-[90vw]"
            />

            <Button type="icon" className="absolute right-4 top-4" onClick={closePreview}>
              <Icon type="close" />
            </Button>
          </motion.div>
        )}
      </AnimatePresence>
    </ImagePreviewContext.Provider>
  );
};
