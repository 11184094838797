import { Note, Text } from '@ui/components/atoms';
import React from 'react';
import { useRouter } from 'next/navigation';
import { IEngagementScoreResponse } from '../engagement-typings';
import { CustomTooltip } from '@ui/components/moleculas';

interface ModalScoreProp {
  score: number | undefined;
  label: string;
  link?: string;
  allScoreResponse: IEngagementScoreResponse;
  setIsOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  isProfile?: boolean;

  // isCreator: boolean;
}

const ModalScore: React.FC<ModalScoreProp> = ({
  score,
  label,
  link,
  allScoreResponse,
  setIsOpen,
  isProfile,
}) => {
  const router = useRouter();
  return (
    <div className="flex w-full justify-between items-end p-3 bg-[#f9f9f9] min-h-[76px] ">
      <div className="">
        <Text type="l" className="">
          {score?.toFixed(3)}
        </Text>
        <div className="flex items-center space-x-2">
          <Note type="xs">{label}</Note>
          {!isProfile && (
            <CustomTooltip
              small
              message="Note: Your score can change when other users delete comments or replies you've interacted with."
              className=" text-note"
            />
          )}
        </div>
      </div>
      {!isProfile && (
        <div
          className={`text-primary text-xs hover:text-primaryHover ${
            link?.includes('badges') && 'cursor-pointer underline '
          }`}
          onClick={() => {
            link?.includes('badges') && router.push(`/creator/?id=${allScoreResponse?.creator_id}`);
            setIsOpen && setIsOpen(false);
          }}>
          {link}
        </div>
      )}
    </div>
  );
};

export default ModalScore;
