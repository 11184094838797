import { forwardRef, ReactNode, isValidElement } from 'react';

interface Props {
  children: ReactNode;
}

export const ItemUpdateMessageContentWrapper = forwardRef<HTMLDivElement, Props>(
  ({ children }, ref) => {
    if (!isValidElement(children)) {
      throw new Error(
        'ItemUpdateMessageContentWrapper only accepts valid React elements as children',
      );
    }

    return <div ref={ref}> {children} </div>;
  },
);

ItemUpdateMessageContentWrapper.displayName = 'ItemUpdateMessageContentWrapper';
