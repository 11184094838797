'use client';
import React, { useState, KeyboardEvent, useCallback, useRef, useMemo } from 'react';
import Joi from 'joi';
import { Button } from '@ui/components/ui/button';
import { X } from 'lucide-react';

export interface IEasyTag {
  id: string;
  text: string;
}

interface EasyTagInputProps {
  placeholder?: string;
  validationSchema?: Joi.StringSchema;
  onTagAdd?: (tag: IEasyTag) => void;
  onTagRemove?: (tag: IEasyTag) => void;
  tags: IEasyTag[];
  onChange?: (updatedTags: IEasyTag[]) => void;
}

const EasyTagInput: React.FC<EasyTagInputProps> = React.memo(
  ({
    placeholder = 'Add a tag',
    validationSchema = Joi.string()
      .email({ tlds: { allow: false } })
      .messages({ 'string.email': 'Invalid email format' }),
    onTagAdd,
    onTagRemove,
    tags,
    onChange,
  }) => {
    const [inputValue, setInputValue] = useState('');
    const [error, setError] = useState<string | null>(null);
    const inputRef = useRef<HTMLInputElement>(null);

    const handleKeyDown = useCallback(
      (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
          e.preventDefault();
          const trimmedValue = inputValue.trim();
          const { error: validationError } = validationSchema.validate(trimmedValue);

          if (validationError) {
            setError(validationError.message);
            return;
          }

          setError(null);

          if (trimmedValue && !tags.find((tag) => tag.text === trimmedValue)) {
            const newTag = { id: trimmedValue, text: trimmedValue };
            onTagAdd?.(newTag);
            setInputValue('');
          } else {
            setError('Already added');
          }
        }
      },
      [inputValue, tags, onTagAdd, validationSchema],
    );

    const handleRemoveTag = useCallback(
      (tagToRemove: IEasyTag) => {
        const updatedTags = tags.filter((tag) => tag.id !== tagToRemove.id);
        onChange?.(updatedTags);
        onTagRemove?.(tagToRemove);
      },
      [tags, onChange, onTagRemove],
    );

    const handleInputChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => setInputValue(e.target.value),
      [],
    );
    const handleClick = () => inputRef.current?.focus();

    const tagList = useMemo(() => tags, [tags]);

    return (
      <div className="border rounded p-2" onClick={handleClick}>
        <div className="flex flex-wrap gap-1 max-h-[20vh] overflow-y-auto items-center">
          {tagList.map((tag) => (
            <span
              key={tag.id}
              className="flex items-center w-fit h-fit text-xs bg-black rounded pl-2 text-white">
              {tag.text}
              <Button
                className="hover:bg-bgBlack p-0 m-0"
                variant="ghost"
                size="icon"
                onClick={() => handleRemoveTag(tag)}>
                <X className="w-3 h-3" />
              </Button>
            </span>
          ))}

          <div>
            <input
              type="text"
              value={inputValue}
              ref={inputRef}
              placeholder={placeholder}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              className="text-xs ring-0 outline-none w-auto"
            />
            {error && (
              <p className="text-xs text-error" style={{ color: 'red' }}>
                {error}
              </p>
            )}
          </div>
        </div>
      </div>
    );
  },
);

EasyTagInput.displayName = 'EasyTagInput';

export default EasyTagInput;
