'use client';
import { Input } from '@ui/components/moleculas';
import {
  IMessagePayload,
  useMessageContext,
  useToast,
  ContextUserType,
  IMessage,
  IMessagesQueryParams,
} from '@ui/components';
import { useEffect, useMemo, useState, useCallback, useRef } from 'react';
import { useFiles } from '@ui/hooks';
import { MAX_FILES } from '@ui/hooks/file/constants';
import dynamic from 'next/dynamic';

const RichTextEditor = dynamic(
  () => import('@ui/components/ui/rte-editor/rte-editor').then((mod) => mod.RichTextEditor),
  { ssr: false },
);

interface Props {
  message: IMessage;
  type: 'post' | 'comment' | 'reply';
  userType: ContextUserType;
  query: IMessagesQueryParams;
  main_post_ref: string;
  onDismiss: () => void;
}

export const MessageUpdateForm = ({
  message: initialMessage,
  type,
  userType,
  onDismiss,
  query,
  main_post_ref,
}: Props) => {
  const toast = useToast();
  const messageState = useMessageContext(userType);
  const [message, setMessage] = useState<IMessagePayload>(() => ({
    name: initialMessage.name,
    description: initialMessage.description,
    id: initialMessage.id,
  }));
  const { files, onSelectFiles, deleteFile, onDiscardFiles, addInitialFiles } = useFiles({
    maxFiles: type === 'post' ? MAX_FILES : 1,
  });
  const filesInitializedRef = useRef(false);

  useEffect(() => {
    if (!filesInitializedRef.current && initialMessage.files) {
      addInitialFiles(initialMessage.files.images || []);
      filesInitializedRef.current = true;
    }
  }, [initialMessage, addInitialFiles]);

  useEffect(() => {
    setMessage((state) => ({ ...state, files: { images: files } }));
  }, [files]);

  const onSubmit = useCallback(
    async (description: string, mentions: string[], clearEditorContent: () => void) => {
      try {
        const res = await messageState.onUpdateMessage(
          {
            ...message,
            description,
            slug: initialMessage.slug,
            mentions,
            type,
            main_post_ref,
          },
          query,
        );
        if (res?.data?.id) {
          onDiscardFiles();
          clearEditorContent();
          onDismiss();
        } else {
          toast({ title: `Error updating ${type}, please try again` }, { type: 'error' });
        }
      } catch (e) {
        toast({ title: `Error updating ${type}, please try again` }, { type: 'error' });
      }
    },
    [message, messageState, onDiscardFiles, onDismiss, toast, type],
  );

  const isSubmitButtonDisabled = useMemo(() => {
    if (type === 'post') return !Boolean(message.description) || !Boolean(message.name);
    return !Boolean(message.description);
  }, [message.description, message.name, type]);

  const handleInputChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setMessage((prevMessage) => ({ ...prevMessage, name: event.target.value }));
  }, []);

  return (
    <div className="flex flex-col gap-1">
      {type === 'post' && (
        <Input
          value={message.name}
          type="text"
          isHideError={true}
          placeholder="Title"
          onChange={handleInputChange}
        />
      )}

      <RichTextEditor
        deleteFile={deleteFile}
        onSelectFiles={onSelectFiles}
        isSubmitButtonDisabled={isSubmitButtonDisabled}
        onChange={onSubmit}
        value={message.description || ''}
        userType={userType}
        files={files}
        isLoading={messageState.isCreating || messageState.isPostingImage}
        exceededFileLimit={files.length >= MAX_FILES}
        placeholder={`Update your ${type}...`}
        editorLabel=""
        buttonLabel="Submit"
        maxFiles={type === 'post' ? MAX_FILES : 1}
        showCancelButton
        onCancel={onDismiss}
        enableSuggestions={type === 'post' ? false : true}
      />
    </div>
  );
};

export default MessageUpdateForm;
