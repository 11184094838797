import { LoadingSkeleton } from '@ui/components/atoms';

export const MessageListSkeleton = () => {
  const items = Array(5).fill(0);

  return (
    <div className="space-y-6">
      {items.map((_, index) => (
        <div
          className="flex gap-2"
          key={`message-skeleton-${index}`}
          style={{ flexDirection: index % 2 === 0 ? 'row' : 'row-reverse' }}>
          <LoadingSkeleton className="h-8 w-8" />
          <div
            className="flex w-full flex-col gap-2"
            style={{ alignItems: index % 2 === 0 ? 'flex-start' : 'flex-end' }}>
            <LoadingSkeleton className="h-4 w-8 rounded-sm" />
            <LoadingSkeleton className="h-8 w-full max-w-[300px] rounded-sm md:w-2/3" />
            <LoadingSkeleton className="h-16 w-full max-w-[300px] rounded-sm md:w-2/3" />
            <LoadingSkeleton className="h-4 w-full max-w-[300px] rounded-sm md:w-2/3" />
          </div>
        </div>
      ))}
    </div>
  );
};
