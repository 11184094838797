import { twMerge } from 'tailwind-merge';
import defaultAvatar from '@ui/assets/images/DefaultAvatar.png';
import { LoadingSkeleton } from './loading-skeleton';
import { ContextUserType } from '@ui/constants/context';

const GRADIENT_STYLE: Record<string, string> = {
  fan: 'bg-gradient-to-r from-[#8F00FF] to-[#8F00FF]',
  creator: 'bg-gradient-to-r from-[#8F00FF] to-[#FFA500]',
};

const AVATAR_SIZE = {
  xxl: 'w-44 h-44',
  xl: 'w-32 h-32',
  l: 'h-24 w-24',
  ml: 'h-20 w-20',
  m: 'h-16 w-16',
  sm: 'h-12 w-12',
  s: 'h-8 w-8',
  xs: 'h-6 w-6',
};

const BORDER_SIZE = {
  xxl: 'border-4',
  xl: 'border-4',
  l: 'border-4',
  ml: 'border-2',
  m: 'border-2',
  sm: 'border-2',
  s: 'border-2',
  xs: 'border-2',
};

const GRADIENT_SIZE = {
  xxl: 'p-1',
  xl: 'p-1',
  l: 'p-1',
  ml: 'p-1',
  m: 'p-1',
  sm: 'p-0.5',
  s: 'p-0.5',
  xs: 'p-0.5',
};

export type IAvatarSize = keyof typeof AVATAR_SIZE;

export interface IAvatar {
  src?: string;
  size?: IAvatarSize;
  type?: ContextUserType;
  className?: string;
  wrapperClassName?: string;
  withGradient?: boolean;
  isLoading?: boolean;
}

export function Avatar({
  size,
  src,
  className,
  isLoading,
  withGradient,
  wrapperClassName,
  type,
}: IAvatar) {
  const key = size || 'm';
  const sizeStyle = AVATAR_SIZE[key];
  const borderStyle = BORDER_SIZE[key];
  const gradientSize = GRADIENT_SIZE[key];
  const image = src ? src : defaultAvatar.src;
  const gradientStyle = GRADIENT_STYLE[type || ''];

  return (
    <div
      className={twMerge(
        'aspect-square h-max w-max rounded-full bg-transparent bg-gradient-to-br from-[#8F00FF] to-[#FFA500]',
        gradientSize,
        withGradient && !isLoading ? '' : 'from-transparent to-transparent',
        wrapperClassName,
        gradientStyle,
      )}>
      {isLoading ? (
        <LoadingSkeleton
          className={twMerge(
            'aspect-square rounded-full border-white',
            sizeStyle,
            borderStyle,
            className,
          )}
        />
      ) : (
        <img
          className={twMerge(
            `aspect-square rounded-full border-white bg-white object-cover`,
            sizeStyle,
            borderStyle,
            className,
          )}
          src={image}
        />
      )}
    </div>
  );
}
