import { useState, useEffect } from 'react';

export const useMobile = (matchMedia?: string) => {
  const [isMobile, setIsMobile] = useState<boolean>(false);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const mediaQuery = window.matchMedia(matchMedia || '(max-width: 767px)');

      const handleChange = (e: MediaQueryListEvent) => {
        setIsMobile(e.matches);
      };

      setIsMobile(mediaQuery.matches);
      mediaQuery.addEventListener('change', handleChange);

      return () => mediaQuery.removeEventListener('change', handleChange);
    }
  }, []);

  return isMobile;
};
