import {
  CommentOrReplyForm,
  ContextUserType,
  IMessagesQueryParams,
  useMessageContext,
} from '@ui/components';
import { useReplyRefContext } from '@ui/contexts';
import React from 'react';
import { createPortal } from 'react-dom';

interface Props {
  userType: ContextUserType;
  creator_id: string;
  user_id: string;
  queryParams: IMessagesQueryParams;
  main_post_ref: string;
}
export const ReplyFormPortal = (props: Props) => {
  const { setTargetRef, targetRef, message, setMessage } = useReplyRefContext();
  const messageState = useMessageContext(props.userType);

  if (!targetRef) return null;

  const onDismiss = () => {
    setTargetRef(null);
    setMessage(null);
  };

  return (
    <Portal targetRef={targetRef}>
      <div>
        <CommentOrReplyForm
          creator_id={message?.creator_id || messageState.creator_id}
          thread_id={message?.child_thread_id}
          user_id={messageState.user_id}
          contentType="reply"
          userType={props.userType}
          replyTo={message ? { label: message?.user_username, id: message?.user_id } : undefined}
          activateToolbarOnFocus={false}
          onDismiss={onDismiss}
          createThreadIfNotExist
          message_id={message?.id}
          conversation_id={message?.conversation_id}
          queryParam={message?.queryParams || props.queryParams}
          main_post_ref={props?.main_post_ref}
        />
      </div>
    </Portal>
  );
};

const Portal = ({
  children,
  targetRef,
}: {
  children: React.ReactNode;
  targetRef: HTMLDivElement;
}) => {
  return createPortal(children, targetRef);
};
