import { LoadingSkeleton } from '@ui/components/atoms';

export const CommentItemSkeleton = () => {
  return (
    <div className="flex items-start gap-2">
      <LoadingSkeleton className="h-10 w-10" />
      <div className="flex flex-col gap-1">
        <LoadingSkeleton className="h-4 w-[30vw] rounded-sm md:w-[9vw]" />
        <LoadingSkeleton className="h-4 w-[85vw] rounded-sm md:w-[18vw]" />
        <LoadingSkeleton className="h-4 w-[70vw] rounded-sm md:w-[15vw]" />
      </div>
    </div>
  );
};
