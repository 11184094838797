'use client';
import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Note, Text, LoadingSkeleton } from '@ui/components/atoms';
import { TrendingUp } from 'lucide-react';
import { IEngagementScoreResponse } from '../engagement-typings';
import { twMerge } from 'tailwind-merge';
import { Plus } from 'lucide-react';

interface ScoreWidgetProp {
  score: IEngagementScoreResponse;
  size?: 'sm' | 'md' | 'lg';
  Title?: string;
  border: boolean | undefined;
  showIncrease?: boolean;
  increaseAmount?: number;
}

const ScoreWidget: React.FC<ScoreWidgetProp> = ({
  score,
  size = 'md',
  Title = 'Score',
  border,
  showIncrease,
  increaseAmount,
}) => {
  const sizeClasses = {
    sm: {
      container: 'h-12 w-12',
      icon: 'w-[20px] h-[20px]',
      text: 'text-[15px]',
      scoreLoad: 'w-12 h-5',
    },
    md: {
      container: 'h-[50px] w-[50px]',
      icon: 'w-[27px] h-[27px]',
      text: 'text-[20px]',
      scoreLoad: 'w-10 h-4',
    },
    lg: {
      container: 'h-[70px] w-[70px]',
      icon: 'w-[35px] h-[35px]',
      text: 'text-[40px]',
      scoreLoad: 'w-8 h-4',
    },
  };

  return (
    <div className={`flex flex-grow-0 gap-2 cursor-pointer items-center`}>
      <div
        className={twMerge(
          `bg-tabActive flex items-center justify-center rounded-full ${sizeClasses[size].container}`,
          `${border && 'border border-primary'}`,
        )}>
        <TrendingUp className={`text-primary ${sizeClasses[size].icon}`} />
      </div>
      <div className="flex flex-col relative">
        <AnimatePresence>
          {showIncrease && (
            <motion.div
              initial={{ scale: 0.5, opacity: 0.2, filter: 'blur(0px)' }}
              animate={{ scale: 0.9, opacity: 1, filter: 'blur(0.5px)' }}
              exit={{ scale: 1.3, opacity: 0, filter: 'blur(0px)' }}
              transition={{ duration: 1 }}
              className={`text-green-600 flex items-center space-x-1 font-bold absolute top-[-4px] right-[-20px]`}>
              {/* <span>{increaseAmount}</span> */}
              <Plus className="w-5 h-5" strokeWidth={4} />
            </motion.div>
          )}
        </AnimatePresence>
        <Text
          type="m"
          className={`leading-[0.4rem]! font-extrabold text-left ${sizeClasses[size].text}`}>
          <span>
            {score?.overall_score?.toFixed(2) ?? (
              <LoadingSkeleton className={`${sizeClasses[size].scoreLoad} mb-1`} />
            )}
          </span>
        </Text>
        <Note type="s">{Title}</Note>
      </div>
    </div>
  );
};

export default ScoreWidget;
