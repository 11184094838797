export const GemIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="25"
    height="26"
    viewBox="0 0 25 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M6.2487 3.625H18.7487L22.9154 9.875L12.4987 23.4167L2.08203 9.875L6.2487 3.625Z"
      stroke="#FFD700"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.457 3.625L8.33203 9.875L12.4987 23.4167L16.6654 9.875L13.5404 3.625"
      stroke="#FFD700"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.08203 9.875H22.9154"
      stroke="#FFD700"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
