export const SlimGraph = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="11"
    viewBox="0 0 24 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M3 1.375V8.70833C3 8.95145 3.21071 9.18461 3.58579 9.35651C3.96086 9.52842 4.46957 9.625 5 9.625H21"
      stroke="black"
      strokeWidth="0.666667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7 7.33073C7.5 6.41406 8.5 4.1224 11 4.1224C13 4.1224 13 5.4974 15 5.4974C17.5 5.4974 19.5 3.20573 20 2.28906"
      stroke="black"
      strokeWidth="0.666667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
