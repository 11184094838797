'use client';
import React, { createContext, useContext, useState, ReactNode } from 'react';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  Icon,
} from '@ui/components';

interface LoaderContextType {
  isOpen: boolean;
  openLoader: (placeholder?: string) => void;
  closeLoader: () => void;
}

const LoaderContext = createContext<LoaderContextType | undefined>(undefined);

interface LoaderProviderProps {
  children: ReactNode;
}

export const LoaderProvider: React.FC<LoaderProviderProps> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [placeholder, setPlaceholder] = useState<string>('');

  const openLoader = (placeholder?: string) => {
    setIsOpen(true);
    setPlaceholder(placeholder || 'Please wait...');
  };
  const closeLoader = () => setIsOpen(false);

  return (
    <LoaderContext.Provider value={{ isOpen, openLoader, closeLoader }}>
      {children}

      <Dialog open={isOpen}>
        <DialogContent
          className="h-full w-full border-none shadow-none focus-visible:outline-none"
          showCloseButton={false}
          style={{ outline: 'none' }}>
          <DialogHeader>
            <DialogTitle></DialogTitle>
            <DialogDescription> </DialogDescription>
          </DialogHeader>
          <div className="absolute top-0 flex h-full w-full flex-col items-center justify-center">
            <Icon type="loadingSpinner" className="text-primary h-24 w-24" />
            <p className="text-md max-w-sm text-center font-bold text-white">{placeholder}</p>
          </div>
        </DialogContent>
      </Dialog>
    </LoaderContext.Provider>
  );
};

export const useLoader = (): LoaderContextType => {
  const context = useContext(LoaderContext);
  if (context === undefined) {
    throw new Error('useLoader must be used within a LoaderProvider');
  }
  return context;
};
