import { IPaymentTransaction } from '@monetization/service';
import { DATE_FORMATS } from '@ui/constants';
import dayjs from 'dayjs';
import { ArrowUpFromDot } from 'lucide-react';
import { ArrowDownToDot } from 'lucide-react';
import { useMemo } from 'react';

interface Props {
  transaction: IPaymentTransaction;
  user_id: string;
  index: number;
}

export const PaymentTransactionItem = ({ transaction, user_id, index }: Props) => {
  const isSender = useMemo(() => transaction.sender_id === user_id, [transaction, user_id]);

  return (
    <div className="grid grid-cols-2 md:grid-cols-6 gap-2 p-2 border-b last:border-none border-gray-200">
      <div className="text-sm md:text-base text-gray-700">#{index}</div>
      <div className="flex justify-between items-center text-sm md:block md:text-base text-gray-600">
        <div className="flex items-center gap-2">
          {isSender ? (
            <ArrowUpFromDot className="h-4 w-4" />
          ) : (
            <ArrowDownToDot className="h-4 w-4" />
          )}
          <p>{isSender ? 'Sent' : 'Received'}</p>
        </div>
        <p className="font-medium text-gray-900 md:hidden">${transaction.amount}</p>
      </div>
      <div className="hidden md:block font-medium text-gray-900">${transaction.amount}</div>
      <div className="text-sm md:text-base text-gray-500 capitalize">
        {transaction.transaction_type}
      </div>
      <div
        className={`text-sm md:text-base ${
          transaction.status === 'succeeded'
            ? 'text-green-600'
            : transaction.status === 'failed'
            ? 'text-red-600'
            : 'text-gray-500'
        }`}>
        {transaction.status}
      </div>
      <div className="text-sm md:text-base text-gray-500">
        {dayjs(transaction.createdAt).format(DATE_FORMATS.full)}
      </div>
    </div>
  );
};
