export const LockIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M16.4687 9.40335V7.25435C16.4377 4.73535 14.3697 2.71935 11.8517 2.75035C9.38466 2.78135 7.38966 4.76735 7.34766 7.23435V9.40335"
      stroke="#2D3139"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.9102 14.1562V16.3772"
      stroke="#2D3139"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.91 8.82812C6.165 8.82812 4.25 10.3961 4.25 15.0991C4.25 19.8031 6.165 21.3711 11.91 21.3711C17.655 21.3711 19.571 19.8031 19.571 15.0991C19.571 10.3961 17.655 8.82812 11.91 8.82812Z"
      stroke="#2D3139"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
