import { CustomTooltip, Note, LoadingSkeleton } from '@ui/components';
import React from 'react';
import { Modal } from '@ui/components';
import { IPostAnalyticsResponse } from '@ui/components';
import { formatFansNumber } from '@ui/lib';

interface AnalyticsModalProps {
  onClose(e: React.MouseEvent): void;
  isOpen: boolean;
  data: IPostAnalyticsResponse;
  isLoading: boolean;
  postName: string;
}

const PostItemAnalyticsModal: React.FC<AnalyticsModalProps> = ({
  onClose,
  data,
  isLoading,
  isOpen,
  postName,
}) => {
  return (
    <Modal
      onClose={onClose}
      title={`${postName}`}
      size="m"
      isOpen={isOpen}
      className="h-fit min-w-full overflow-hidden  mt-5 md:mt-0 pt-5 pb-5 px-5">
      <div>
        {/* Title */}
        <Note type="s" className="font-semibold text-start">
          Engagement Analytics
        </Note>

        {/* Engagement Circle */}
        <div className="flex justify-center items-center mt-2">
          <div className="relative">
            <div className="w-40 h-40 bg-gradient-to-r from-orange-400 to-gray-300 rounded-full animate-spin-slow"></div>

            {/* Inner Circle */}
            <div className="absolute inset-0 flex justify-center items-center">
              <div className="w-32 h-32 bg-white rounded-full flex justify-center items-center">
                {isLoading ? (
                  <LoadingSkeleton className="h-6 w-10" />
                ) : (
                  <span className="text-xl font-bold">
                    {data?.engagement_ratio?.toFixed(0) ?? 0}%
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>

        <p className="text-center flex flex-row items-center justify-center gap-1 text-sm text-gray-600 mt-2">
          Engagement Rate{' '}
          <span className="flex items-center">
            <CustomTooltip
              place="top"
              small={true}
              message="Engagement rate is a metric that measures how actively users are interacting with content."
              className="font-sans text-xs"
            />{' '}
            :
          </span>
          {isLoading ? (
            <LoadingSkeleton className="h-6 w-10" />
          ) : (
            `${data?.engagement_ratio?.toFixed(0) ?? 0}%`
          )}
        </p>

        {/* Analytics Details */}
        <div className="grid grid-cols-1 gap-3 mt-5">
          <div className="flex justify-between items-center bg-gray-100 p-3 rounded-md">
            <span className="font-medium flex flex-row gap-1 items-center">
              Total Fans{' '}
              <CustomTooltip
                place="right"
                small={true}
                message="The total number of users who own at least one badge from your collection."
                className="font-sans text-xs"
              />
            </span>
            {isLoading ? (
              <LoadingSkeleton className="h-6 w-10" />
            ) : (
              <span className="bg-white text-black font-bold px-2 py-1 rounded-sm shadow-sm">
                {formatFansNumber(data?.total_fans ?? 0)}
              </span>
            )}
          </div>
          <div className="flex justify-between items-center bg-gray-100 p-3 rounded-md">
            <span className="font-medium flex flex-row gap-1 items-center">
              Total Engagement{' '}
              <CustomTooltip
                place="right"
                small={true}
                message="A broad metric that sums up all user interactions, which include votes, reactions, comments, and replies on a post"
                className="font-sans text-xs"
              />
            </span>
            {isLoading ? (
              <LoadingSkeleton className="h-6 w-10" />
            ) : (
              <span className="bg-white text-black font-bold px-3 py-1 rounded-sm shadow-sm">
                {formatFansNumber(data?.total_engagement ?? 0)}
              </span>
            )}
          </div>
          <div className="flex justify-between items-center bg-gray-100 p-3 rounded-md">
            <span className="font-medium flex flex-row gap-1 items-center">
              Comments & Replies{' '}
              <CustomTooltip
                place="right"
                small={true}
                message="Total number of comments and replies associated with the post."
                className="font-sans text-xs"
              />
            </span>
            {isLoading ? (
              <LoadingSkeleton className="h-6 w-10" />
            ) : (
              <span className="bg-white text-black font-bold px-3 py-1 rounded-sm shadow-sm">
                {formatFansNumber(data?.total_messages ?? 0)}
              </span>
            )}
          </div>
          <div className="flex justify-between items-center bg-gray-100 p-3 rounded-md">
            <span className="font-medium flex flex-row gap-1 items-center">
              Votes{' '}
              <CustomTooltip
                place="right"
                small={true}
                message="The total number of votes received, accumulated from all related comments and replies."
                className="font-sans text-xs"
              />
            </span>
            {isLoading ? (
              <LoadingSkeleton className="h-6 w-10" />
            ) : (
              <span className="bg-white text-black font-bold px-3 py-1 rounded-sm shadow-sm">
                {formatFansNumber(data?.total_votes ?? 0)}
              </span>
            )}
          </div>
          <div className="flex justify-between items-center bg-gray-100 p-3 rounded-md">
            <span className="font-medium flex flex-row gap-1 items-center">
              Total Reactions{' '}
              <CustomTooltip
                place="right"
                small={true}
                message="Total number of reactions associated with the post."
                className="font-sans text-xs"
              />
            </span>
            {isLoading ? (
              <LoadingSkeleton className="h-6 w-10" />
            ) : (
              <span className="bg-white text-black font-bold px-3 py-1 rounded-sm shadow-sm">
                {formatFansNumber(data?.total_reactions ?? 0)}
              </span>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default PostItemAnalyticsModal;
