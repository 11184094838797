import { Note, Text } from '@ui/components/atoms';
import { BadgeCheckIcon, Gem, TrendingUp } from 'lucide-react';
import React from 'react';
import { cn } from '@ui/lib/utils';

interface LeaderboardScoreInfoProps {
  label: string;
  score: string | number;
  bgColor?: string;
}

const LeaderboardScoreInfo: React.FC<LeaderboardScoreInfoProps> = ({
  label = 'Engagement',
  score,
  bgColor = 'bg-[rgba(255,255,255,0.3)]',
}) => {
  const Icon = {
    Engagement: <Gem className="w-3 h-3 sm:w-4 sm:h-4 text-subtitle" />,
    Badges: <BadgeCheckIcon className="w-3 h-3 sm:w-4 sm:h-4 text-subtitle" />,
    'Overall Score': <TrendingUp className="w-3 h-3 sm:w-4 sm:h-4 text-subtitle" />,
  }[label];
  return (
    <div
      className={cn(
        'flex flex-col items-start justify-start w-full rounded-sm py-1 px-2',
        bgColor,
      )}>
      <div className="flex items-center">
        <Note type="xs" className="text-[10.5px] text-subtitle">
          {label}
        </Note>
        <div className="md:ml-2 ml-1">{Icon}</div>
      </div>
      <Note type="xs" className="text-[10px] text-subtitle font-semibold">
        {score}
      </Note>
    </div>
  );
};

export default LeaderboardScoreInfo;
