import { EditorProvider } from '@tiptap/react';
import type { EditorProviderProps, HTMLContent } from '@tiptap/react';
import { Provider } from 'jotai';
import { forwardRef, useRef } from 'react';
import type { FC, ReactNode } from 'react';
import tunnel from 'tunnel-rat';
import { editorStore } from '../utils/store';
import { EditorCommandTunnelContext } from './editor-command';

export interface EditorProps {
  readonly children: ReactNode;
  readonly className?: string;
}

interface EditorRootProps {
  readonly children: ReactNode;
}

export const EditorRoot: FC<EditorRootProps> = ({ children }) => {
  const tunnelInstance = useRef(tunnel()).current;

  return (
    <Provider store={editorStore}>
      <EditorCommandTunnelContext.Provider value={tunnelInstance}>
        {children}
      </EditorCommandTunnelContext.Provider>
    </Provider>
  );
};

export type EditorContentProps = Omit<EditorProviderProps, 'content'> & {
  readonly children?: ReactNode;
  readonly className?: string;
  readonly initialContent?: HTMLContent;
};

export const NotebookEditorContent = forwardRef<HTMLDivElement, EditorContentProps>(
  ({ className, children, initialContent, ...rest }, ref) => {
    return (
      <div ref={ref} className={className}>
        <EditorProvider {...rest} content={initialContent}>
          {children}
        </EditorProvider>
      </div>
    );
  },
);

NotebookEditorContent.displayName = 'EditorNotebookContent';
