'use client';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import { IMessage } from '@ui/components';
import { Sheet, SheetContent, SheetDescription, SheetHeader, SheetTitle } from '@ui/components/ui';
import { useClickOutside } from '@ui/hooks';
import { useCallback } from 'react';

export interface ISelectedEmoji {
  id: string;
  name: string;
  native: string;
  unified: string;
  keywords: string[];
  shortcodes: string;
  aliases: string[];
  emoticons: string[];
}

interface IProps {
  onEmojiSelect: (emoji: ISelectedEmoji) => void;
  setOpen: (value: boolean) => void;
  open: boolean;
  message: IMessage;
}

export const EmojiPickerActionSheet = ({ onEmojiSelect, setOpen, open }: IProps) => {
  const handleSelect = useCallback(
    (emoji: ISelectedEmoji) => {
      onEmojiSelect(emoji);
      setOpen(false);
    },
    [onEmojiSelect, setOpen],
  );

  const ref = useClickOutside(() => setOpen(false));

  return (
    <>
      {open && (
        <div
          className="fixed inset-0 z-40 h-full w-full bg-black bg-opacity-50"
          style={{ zIndex: 997 }}
          onClick={() => setOpen(false)}
        />
      )}
      <Sheet open={open} onOpenChange={setOpen} modal={false}>
        <SheetContent
          side="bottom"
          className="z-50 m-0 w-full border-none bg-transparent p-0 md:m-auto md:w-fit"
          closeButtonClassName="hidden"
          style={{ zIndex: 999, pointerEvents: 'auto' }}
          ref={ref}>
          <SheetHeader>
            <SheetTitle></SheetTitle>
            <SheetDescription> </SheetDescription>
          </SheetHeader>
          <div className="ui-controlled-emoji-mart max-h-[500px] w-full overflow-y-auto">
            <Picker
              data={data}
              onEmojiSelect={handleSelect}
              theme="light"
              previewPosition="none"
              skinTonePosition="none"
              style={{ width: '100%' }}
              dynamicWidth={true}
            />
          </div>
        </SheetContent>
      </Sheet>
    </>
  );
};
