'use client';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  Spinner,
  useConversationContext,
} from '@ui/components';

interface IProps {
  open: boolean;
  onClose: () => void;
}
export const ConversationDeleteActionModal = ({ open, onClose }: IProps) => {
  const state = useConversationContext();

  const handleDelete = async () => {
    await state.onDelete();
    onClose();
  };

  return (
    <AlertDialog open={open}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
          <AlertDialogDescription>
            This action cannot be undone. This will permanently delete your chat history.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter onClick={onClose}>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <AlertDialogAction
            onClick={handleDelete}
            disabled={state.isDeletingConversation}
            className="bg-primary hover:bg-primaryHover">
            {state.isDeletingConversation && (
              <Spinner className="mr-2 animate-spin" fill="#2D3139" />
            )}
            Delete
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
