import { useEffect, useRef } from 'react';

export const useClickOutside = <T extends HTMLElement = HTMLDivElement>(handler: () => void) => {
  const ref = useRef<T | null>(null);

  useEffect(() => {
    const listener = (event: MouseEvent | TouchEvent) => {
      if (!ref.current || ref.current.contains(event.target as Node)) {
        return;
      }
      handler();
    };

    document.addEventListener('pointerdown', listener, { passive: true });
    return () => {
      document.removeEventListener('pointerdown', listener);
    };
  }, [handler]);

  return ref;
};
