'use client';
import {
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  IConversation,
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
  useConversationContext,
} from '@ui/components';
import React, { useEffect, useMemo, useState } from 'react';
import { Lightbulb, Hand, Users } from 'lucide-react';
import { UpdateConversationDetails } from './update-conversation-details';

interface IProps {
  children: React.ReactNode;
}
export const ConversationSettings = ({ children }: IProps) => {
  const state = useConversationContext();
  const [conversation, setConversation] = useState<IConversation>({
    description: '',
    name: '',
    fan_ids: [],
    creator_id: state.creator_id,
    id: '',
    public: true,
    main_thread: '',
  });
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (state.selectedConversationSet) {
      setConversation(state.selectedConversationSet.conversation);
    }
  }, [state.selectedConversationSet]);

  const isDisabled = useMemo(() => {
    return state.isUpdatingConversation;
  }, [state.isUpdatingConversation]);

  const onChangeDetails = (conversation: IConversation) => {
    setConversation((state) => ({ ...state, ...conversation }));
  };

  const handleSave = async () => {
    await state.onUpdate(conversation);
    setIsOpen(false);
  };

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        <span>{children}</span>
      </DialogTrigger>
      <DialogContent
        className="gap-0 bg-white p-0 sm:max-w-2xl sm:rounded"
        style={{ cursor: state.isUpdatingConversation ? 'progress' : 'default' }}>
        <DialogHeader className="border-b p-4">
          <DialogTitle className="text">Message Settings</DialogTitle>
        </DialogHeader>

        <div>
          <Tabs defaultValue="details" className="flex h-full min-h-[400px] flex-row">
            <TabsList className="bg-input flex h-full w-1/3 flex-col justify-start space-y-2 rounded-none p-2">
              <TabsTrigger
                value="details"
                className="w-full justify-start rounded-sm p-2 text-left">
                <Lightbulb className="mr-2 h-4 w-4" /> Details
              </TabsTrigger>
              {/* <TabsTrigger
                disabled
                value="restrictions"
                className="w-full justify-start rounded-sm p-2 text-left">
                <Hand className="mr-2 h-4 w-4" />
                Restrictions
              </TabsTrigger> */}
              <TabsTrigger
                disabled
                value="members"
                className="w-full justify-start rounded-sm p-2 text-left">
                <Users className="mr-2 h-4 w-4" />
                Members
              </TabsTrigger>
            </TabsList>

            <div className="flex w-2/3 flex-col">
              <TabsContent value="details" className=" h-full">
                <UpdateConversationDetails
                  conversation={conversation}
                  onChangeDetails={onChangeDetails}
                />
              </TabsContent>

              <DialogFooter className="justify-end p-2">
                <Button
                  type="primary"
                  size="small"
                  isLoading={state.isUpdatingConversation}
                  disabled={isDisabled}
                  onClick={handleSave}>
                  Save
                </Button>
              </DialogFooter>
            </div>
          </Tabs>
        </div>
      </DialogContent>
    </Dialog>
  );
};
