export const PeopleIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="29"
    height="24"
    viewBox="0 0 29 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M21 21V19C21 17.9391 20.5786 16.9217 19.8284 16.1716C19.0783 15.4214 18.0609 15 17 15H11C9.93913 15 8.92172 15.4214 8.17157 16.1716C7.42143 16.9217 7 17.9391 7 19V21"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14 11C16.2091 11 18 9.20914 18 7C18 4.79086 16.2091 3 14 3C11.7909 3 10 4.79086 10 7C10 9.20914 11.7909 11 14 11Z"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M27 20.9989V18.9989C26.9989 18.1126 26.5073 17.2517 25.6023 16.5512C24.6973 15.8508 23.4302 15.3505 22 15.1289"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21 5C21.8582 5.19898 22.6188 5.65096 23.162 6.28467C23.7052 6.91838 24 7.69778 24 8.5C24 9.30222 23.7052 10.0816 23.162 10.7153C22.6188 11.349 21.8582 11.801 21 12"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1 20.87V18.87C1.0011 17.9837 1.49274 17.1228 2.39772 16.4223C3.30271 15.7219 4.56978 15.2216 6 15"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7 5C6.14183 5.19898 5.38119 5.65096 4.83801 6.28467C4.29483 6.91838 4 7.69778 4 8.5C4 9.30222 4.29483 10.0816 4.83801 10.7153C5.38119 11.349 6.14183 11.801 7 12"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
