import React, { useMemo } from 'react';
import { IMessage, IMessagesQueryParams } from '@ui/components';
import { ContextUserType, useMessageContext } from '@ui/components';
import { twMerge } from 'tailwind-merge';
import { PostCommentReactionPanel } from './post-comment-reaction-panel';
import { sendGAEvent } from '@next/third-parties/google';
import { formatFansNumber } from '@ui/lib';

interface IProps {
  message: IMessage;
  contextUserType: ContextUserType;
  query: IMessagesQueryParams;
  triggerScoreAnimation: (value: number) => void;
}

export const PostCommentReactions: React.FC<IProps> = React.memo(
  ({ message, contextUserType, query, triggerScoreAnimation }) => {
    const messageState = useMessageContext(contextUserType);

    const onReactToMessage = (emoji: string) => {
      const userReactions = message?.user_reactions?.[messageState.user_id] || [];
      const isRemoving = userReactions.includes(emoji);
      messageState.onReactToMessage(message.id, message.slug, 'reaction', emoji, query);
      // Send GA events
      sendGAEvent('event', 'UserReaction', {
        event: 'emoji_reaction',
        event_action: isRemoving ? 'Remove Emoji' : 'Add Emoji',
        event_label: emoji,
        post_id: message.id,
        user_type: contextUserType,
      });

      !isRemoving && triggerScoreAnimation(0.5);
    };

    const reactionList = useMemo(() => {
      if (!message?.reaction || !message?.reaction?.reactions_list) {
        return null;
      }

      const userReactions = message.user_reactions?.[messageState.user_id] || [];

      return Object.entries(message.reaction.reactions_list).map(
        ([emoji, count]) =>
          count > 0 && (
            <div
              onClick={() => onReactToMessage(emoji)}
              key={emoji}
              className={`bg-input flex h-fit w-fit min-w-[50px] cursor-pointer items-center justify-center space-x-1 rounded-lg border py-[1px] px-[3px] text-xs hover:bg-black/20 ${
                userReactions.includes(emoji) ? 'border-orange-200 bg-orange-100' : ''
              }`}>
              <span className="text-sm sm:text-lg">{emoji}</span>
              {<span className="ml-1 text-xs font-medium">{formatFansNumber(count)}</span>}
            </div>
          ),
      );
    }, [message.reaction, message.user_reactions, messageState.user_id]);

    if (!reactionList) {
      return null;
    }

    return (
      <div
        className={twMerge(
          'flex flex-wrap gap-x-2 gap-y-2 sm:gap-y-0',
          reactionList.length ? '' : '',
        )}>
        {reactionList}
        <PostCommentReactionPanel
          message={message}
          contextUserType={contextUserType}
          query={query || messageState.query}
          triggerScoreAnimation={() => triggerScoreAnimation(0.5)}
        />
      </div>
    );
  },
);

PostCommentReactions.displayName = 'PostCommentReactions';
