import { useState, useCallback } from 'react';

type AnimationPosition = 'upvote' | 'downvote' | 'comment' | 'reaction' | 'custom';

export interface ScoreAnimation {
  value: number;
  isVisible: boolean;
  position: AnimationPosition;
}

export const useScoreAnimation = () => {
  const [scoreAnimation, setScoreAnimation] = useState<ScoreAnimation>({
    value: 0,
    isVisible: false,
    position: 'custom',
  });

  const triggerScoreAnimation = useCallback((value: number, position: AnimationPosition) => {
    setScoreAnimation({ value, isVisible: true, position });
    setTimeout(() => setScoreAnimation({ value: 0, isVisible: false, position: 'custom' }), 1000);
  }, []);

  return { scoreAnimation, triggerScoreAnimation };
};
