'use client';
import {
  IMessagePayload,
  useMessageContext,
  useToast,
  ContextUserType,
  IMessagesQueryParams,
} from '@ui/components';
import { useEffect, useState } from 'react';
import { useFiles } from '@ui/hooks';
import { sendGAEvent } from '@next/third-parties/google';
import dynamic from 'next/dynamic';
import { ContentType } from '@ui/constants/context';

const RichTextEditor = dynamic(
  () => import('@ui/components/ui/rte-editor/rte-editor').then((mod) => mod.RichTextEditor),
  { ssr: false },
);
const maxFiles = 1;
const initialState: IMessagePayload = {
  send_email: false,
  files: {
    images: [],
  },
  description: '',
};
interface Props {
  creator_id: string;
  user_id: string;
  contentType: ContentType;
  userType: ContextUserType;
  replyTo?: { id: string; label: string };
  activateToolbarOnFocus: boolean;
  createThreadIfNotExist: boolean;
  thread_id?: string;
  message_id?: string;
  conversation_id?: string;
  queryParam: IMessagesQueryParams;
  main_post_ref: string;
  onDismiss?: () => void;
}
export const CommentOrReplyForm = (props: Props) => {
  const toast = useToast();
  const [loadingType, setLoadingType] = useState<ContentType | null>(null);
  const messageState = useMessageContext(props.userType);
  const [message, setMessage] = useState<IMessagePayload>(initialState);
  const { files, onSelectFiles, deleteFile, onDiscardFiles } = useFiles({ maxFiles });

  useEffect(() => {
    setMessage((state) => ({ ...state, files: { images: files } }));
  }, [files]);

  const onSubmit = async (
    description: string,
    mentions: string[],
    clearEditorContent: () => void,
  ) => {
    setLoadingType(props.contentType);
    try {
      let createdThread = null;
      if (
        props.createThreadIfNotExist &&
        !props.thread_id &&
        props.message_id &&
        props.conversation_id
      ) {
        // create comment thread
        const threadResponse = await messageState.onCreateMessageThread({
          message_id: props.message_id,
          conversation_id: props.conversation_id,
          queryParams: props.queryParam,
        });
        createdThread = threadResponse?.data?.message_thread?.id;
      }

      const creator_main_id =
        props.creator_id !== null ? props.creator_id : messageState.singleMessage?.creator_id;

      const res = await messageState.onCreateMessage({
        ...message,
        description,
        creator_id: creator_main_id,
        thread_id: createdThread || props.thread_id,
        user_id: props.user_id,
        mentions,
        type: props.contentType,
        main_post_ref: props.main_post_ref,
      });

      if (!res?.error) {
        setMessage(initialState);
        onDiscardFiles();
        clearEditorContent();
        // Send GA Event for successful comment creation
        if (props.contentType === 'comment') {
          sendGAEvent('event', 'CreateCommentSuccessful', {
            event_label: 'Comment Creation Successful',
            creatorId: props.creator_id,
            postId: props.message_id,
          });
        }
        if (props.onDismiss) props.onDismiss();
      } else {
        toast(
          { title: `Error creating ${props.contentType}, please try again` },
          { type: 'error' },
        );
      }
    } catch (e) {
      console.error(e);
      toast({ title: `Error creating ${props.contentType}, please try again` }, { type: 'error' });

      // Send GA Event for failed comment creation
      if (props.contentType === 'comment') {
        sendGAEvent('event', 'CreateCommentFailed', {
          event_label: 'Comment Creation Failed',
          creatorId: props.creator_id,
          postId: props.message_id,
        });
      }
    } finally {
      setLoadingType(null);
    }
  };

  const onCancelEditor = () => {
    setMessage(initialState);
    onDiscardFiles();
    if (props.onDismiss) props.onDismiss();
  };

  return (
    <div className="flex flex-col gap-1">
      <RichTextEditor
        deleteFile={deleteFile}
        onSelectFiles={onSelectFiles}
        isSubmitButtonDisabled={false}
        onChange={onSubmit}
        value={message.description || ''}
        userType="creator"
        currentUser={props.userType}
        files={files}
        exceededFileLimit={files.length >= maxFiles}
        placeholder={`Type your ${props.contentType}...`}
        editorLabel=""
        buttonLabel={props.contentType === 'comment' ? 'Comment' : 'Reply'}
        editorOnly
        maxFiles={maxFiles}
        activateToolbarOnFocus={props.activateToolbarOnFocus}
        isLoading={loadingType === props.contentType}
        showCancelButton
        onCancel={onCancelEditor}
        enableSuggestions
        initialMention={props.replyTo || undefined}
      />
    </div>
  );
};
