import {
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerDescription,
  DrawerTitle,
  Button,
  FLAGGED_MESSAGE_CATEGORIES,
  IMessage,
} from '@ui/components';
import { useClickOutside } from '@ui/hooks';

interface IProps {
  message: IMessage;
  open: boolean;
  setOpen: (value: boolean) => void;
  onReportMessage: (reason: string, message: IMessage) => void;
}

export const MessageItemReportMessageActionSheet = ({
  message,
  setOpen,
  open,
  onReportMessage,
}: IProps) => {
  const handleReportMessage = (reason: string) => {
    onReportMessage(reason, message);
    setOpen(false);
  };

  const ref = useClickOutside(() => setOpen(false));

  return (
    <Drawer open={open} onClose={() => setOpen(false)}>
      <DrawerContent className="m-auto md:max-w-sm" ref={ref}>
        <DrawerHeader className="border-b">
          <DrawerTitle>Report Message</DrawerTitle>
          <DrawerDescription>Select a reason to report this message</DrawerDescription>
        </DrawerHeader>

        <div className="mt-4 h-full max-h-96 overflow-y-auto">
          {FLAGGED_MESSAGE_CATEGORIES.map((category, index) => (
            <Button
              type="secondary"
              key={index}
              onClick={() => handleReportMessage(category)}
              className="w-full rounded-none bg-white text-sm">
              {category}
            </Button>
          ))}
        </div>
      </DrawerContent>
    </Drawer>
  );
};
