import { twMerge } from 'tailwind-merge';
import { CustomImage, Title, Button, Note, Icon } from '../atoms';
import Link from 'next/link';

interface IErrorPage {
  title: string;
  message: string;
  isLoading?: boolean;
  type?: '404' | '500';
  buttonText?: React.ReactNode | string;
  href?: string;
  className?: string;
}

export function ErrorPage({
  title,
  message,
  type = '500',
  buttonText,
  href,
  className,
  isLoading = false,
}: IErrorPage) {
  return (
    <div
      className={twMerge(
        'relative flex h-screen flex-col items-center px-4 py-8 lg:justify-center',
        className,
      )}>
      <Icon type="logo" className="mb-10 lg:absolute lg:left-16 lg:top-16" />
      <div className="flex flex-col items-center">
        <CustomImage type={type} className="mb-10" />
        <Title type="xl" className="mb-5 text-center">
          {title}
        </Title>
        <Note className="mb-10 text-center" type="l">
          {message}
        </Note>
        {buttonText && href && (
          <Link href={href}>
            <Button type="primary" isLoading={isLoading}>
              {buttonText}
            </Button>
          </Link>
        )}
      </div>
    </div>
  );
}
