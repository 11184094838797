import { twMerge } from 'tailwind-merge';
import { Title, Subtitle } from '../atoms';
import { Badge, IBadgeSize } from '../organisms';
import { BadgeType } from '@ui/constants';

export interface IBadgeImageInfo {
  image: string;
  name: string;
  type: BadgeType;
  size?: IBadgeSize;
  caption: string;
  className?: string;
  imageClassName?: string;
  color: string;
  badgeNumber?: number;
  badgeAmount?: number;
  showSpinner?: boolean;
  onImageLoad?: () => void;
}

export function BadgeImageInfo({
  name,
  caption,
  size = 'l',
  type,
  image,
  color,
  badgeNumber,
  className,
  imageClassName,
  badgeAmount,
  showSpinner,
  onImageLoad,
}: IBadgeImageInfo) {
  return (
    <div
      className={twMerge(
        'flex w-full flex-col items-center justify-center p-4 lg:p-16',
        className,
      )}>
      <Badge
        image={image}
        size={size}
        type={type}
        color={color}
        serialNumber={badgeNumber}
        badgeAmount={badgeAmount}
        className={twMerge('mb-3 lg:mb-8', imageClassName)}
        showSpinner={showSpinner}
        onImageLoad={onImageLoad}
      />
      <div className="flex flex-col items-center gap-12">
        <div className="w-full">
          <Title type="mm" className="mb-1 text-center lg:mb-2">
            {name}
          </Title>
          <Subtitle type="m" className="text-center">
            {caption}
          </Subtitle>
        </div>
      </div>
    </div>
  );
}
