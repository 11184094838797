'use client';
import React, { useState, useEffect } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { ChevronDown, ChevronUp } from 'lucide-react';
import { createPortal } from 'react-dom';
import ScoreWidget from './score-widget';
import EngagementMetrics from './engagement-metric-wrapper';
import { useScoreIncrease } from '../hooks/use-score-increase';
import { AnimatedShadowWrapper } from './animated-shadow-wrapper';
import { IEngagementScoreResponse } from '../engagement-typings';
import { EasyTab, EasyTabContent, EasyTabItem } from '@ui/components/moleculas';
import { Gem, BadgeCheckIcon } from 'lucide-react';
import ModalScore from './modal-score';
import { Badge } from '../../badge';
import badges from '../../../../assets/images/badges.png';

interface EngagementScoreFooterProp {
  score: IEngagementScoreResponse;
}

const EngagementScoreFooter: React.FC<EngagementScoreFooterProp> = ({ score }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [portalRoot, setPortalRoot] = useState<HTMLDivElement | null>(null);

  const { showIncrease, increaseAmount } = useScoreIncrease(score);

  useEffect(() => {
    const portalElement = document.createElement('div');
    portalElement.id = 'engagement-footer-portal';
    document.body.appendChild(portalElement);
    setPortalRoot(portalElement);

    return () => {
      document.body.removeChild(portalElement);
    };
  }, []);

  const content = (
    <div className="fixed bottom-0 left-0 right-0 z-10 pointer-events-none block lg:hidden pt-1">
      <AnimatedShadowWrapper showAnimation={showIncrease} hasBorder className={'rounded-t-lg'}>
        <div className="bg-white pointer-events-auto rounded-t-lg shadow-2xl shadow-black">
          <div
            onClick={() => setIsOpen(!isOpen)}
            className="w-full text-black py-3 px-4 rounded-t-lg relative">
            <ScoreWidget
              score={score}
              border
              size="md"
              increaseAmount={increaseAmount}
              showIncrease={showIncrease}
              Title="Overall score"
            />
            <div className="absolute right-2 top-4">{isOpen ? <ChevronDown /> : <ChevronUp />}</div>
          </div>
          <AnimatePresence>
            {isOpen && (
              <motion.div
                initial={{ height: 0 }}
                animate={{ height: 'auto' }}
                exit={{ height: 0 }}
                transition={{
                  type: 'spring',
                  damping: 30,
                  stiffness: 300,
                }}
                className="bg-white rounded-t-lg shadow-lg overflow-hidden">
                <EasyTab defaultTab="Engagement" className="p-2">
                  <div className="flex justify-between space-x-2 w-full mb-4">
                    <EasyTabItem
                      label="Engagement"
                      className="flex justify-center p-3 w-[48%] border border-primaryLight rounded-lg "
                      useBottomActiveBorder={false}
                      useFullBorderWhenActive
                      icon={Gem}
                    />
                    <EasyTabItem
                      label="Badges"
                      className="flex justify-center p-3 w-[48%] border border-primaryLight rounded-lg"
                      useBottomActiveBorder={false}
                      useFullBorderWhenActive
                      icon={BadgeCheckIcon}
                    />
                  </div>
                  <EasyTabContent label="Engagement" className="min-h-fit">
                    <div className="w-full mb-4">
                      <ModalScore
                        score={score?.total_engagement_score}
                        label="Your engagement score"
                        allScoreResponse={score}
                      />
                    </div>
                    <div className="max-h-[350px] overflow-auto min-h-[350px] scrollbar-none">
                      <EngagementMetrics score={score} />
                    </div>
                  </EasyTabContent>
                  <EasyTabContent label="Badges" className="min-h-fit">
                    <div className="w-full mb-4">
                      <ModalScore
                        score={score?.badges_score}
                        label="Your badge score"
                        link="View all badges"
                        allScoreResponse={score}
                        setIsOpen={setIsOpen}
                      />
                    </div>
                    <div className="max-h-[350px] min-h-[350px] w-full rounded-sm flex justify-center items-center">
                      <img
                        src={badges.src}
                        alt="badges"
                        className="h-[280px] md:h-[330px] object-cover rounded-sm w-[100%]"
                      />
                    </div>
                  </EasyTabContent>
                </EasyTab>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </AnimatedShadowWrapper>
    </div>
  );

  return portalRoot ? createPortal(content, portalRoot) : null;
};

export default EngagementScoreFooter;
