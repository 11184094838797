export enum BadgeSource {
  Undefined = 0,
  YouTube = 1,
  Facebook = 2,
  Twitter = 3,
  Instagram = 4,
  Merch = 5,
  Event = 6,
  Other = 7,
  Linktree = 8,
  Beacons = 9,
  Custom = 10,
  Open = 11,
}

export enum BadgeType {
  Engagement = 1,
  Prize = 2,
  Community = 3,
  Purchase = 4,
}

export const EngagementSources = [
  BadgeSource.YouTube,
  BadgeSource.Twitter,
  BadgeSource.Facebook,
  BadgeSource.Instagram,
  BadgeSource.Linktree,
  //BadgeSource.Beacons,
  BadgeSource.Custom,
  BadgeSource.Open,
] as const;

const PurchaseSources = [BadgeSource.Merch, BadgeSource.Event, BadgeSource.Other] as const;

export const BADGE_SOURCE_BY_TYPE: Record<BadgeType, ReadonlyArray<BadgeSource>> = {
  [BadgeType.Engagement]: EngagementSources,
  [BadgeType.Prize]: EngagementSources,
  [BadgeType.Community]: EngagementSources,
  [BadgeType.Purchase]: PurchaseSources,
};

type EngagementSourceKey = (typeof EngagementSources)[number];
export const BADGE_SOURCE_URLS: Record<EngagementSourceKey, string[]> = {
  [BadgeSource.YouTube]: ['youtube.com', 'youtu.be', 'www.youtube.com', 'm.youtube.com'],
  [BadgeSource.Facebook]: ['facebook.com'],
  [BadgeSource.Twitter]: ['t.co'],
  [BadgeSource.Instagram]: ['l.instagram.com'],
  [BadgeSource.Linktree]: ['linktr.ee'],
  //[BadgeSource.Beacons]: ['beacons.ai'],
  [BadgeSource.Custom]: [''],
  [BadgeSource.Open]: [''],
};

export const BADGE_SOURCE_NAME_MAP: Record<Exclude<BadgeSource, BadgeSource.Undefined>, string> = {
  [BadgeSource.YouTube]: 'YouTube',
  [BadgeSource.Facebook]: 'Facebook',
  [BadgeSource.Twitter]: 'X (Twitter)',
  [BadgeSource.Instagram]: 'Instagram',
  [BadgeSource.Linktree]: 'Linktree',
  [BadgeSource.Beacons]: 'Beacons',
  [BadgeSource.Custom]: 'Custom',
  [BadgeSource.Open]: 'Open',
  [BadgeSource.Merch]: 'Merch',
  [BadgeSource.Event]: 'Event',
  [BadgeSource.Other]: 'Other',
};
