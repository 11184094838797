export const StripeLogoIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="360"
    height="150"
    viewBox="0 0 360 150"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M360.003 77.4016C360.003 51.8016 347.603 31.6016 323.903 31.6016C300.103 31.6016 285.703 51.8016 285.703 77.2016C285.703 107.302 302.703 122.502 327.103 122.502C339.003 122.502 348.003 119.802 354.803 116.002V96.0016C348.003 99.4016 340.203 101.502 330.303 101.502C320.603 101.502 312.003 98.1016 310.903 86.3016H359.803C359.803 85.0016 360.003 79.8016 360.003 77.4016ZM310.603 67.9016C310.603 56.6016 317.503 51.9016 323.803 51.9016C329.903 51.9016 336.403 56.6016 336.403 67.9016H310.603Z"
      fill="#6772E5"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M247.103 31.6016C237.303 31.6016 231.003 36.2016 227.503 39.4016L226.203 33.2016H204.203V149.802L229.203 144.502L229.303 116.202C232.903 118.802 238.203 122.502 247.003 122.502C264.903 122.502 281.203 108.102 281.203 76.4016C281.103 47.4016 264.603 31.6016 247.103 31.6016ZM241.103 100.502C235.203 100.502 231.703 98.4016 229.303 95.8016L229.203 58.7016C231.803 55.8016 235.403 53.8016 241.103 53.8016C250.203 53.8016 256.503 64.0016 256.503 77.1016C256.503 90.5016 250.303 100.502 241.103 100.502Z"
      fill="#6772E5"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M169.797 25.7L194.897 20.3V0L169.797 5.3V25.7Z"
      fill="#6772E5"
    />
    <path d="M194.897 33.3008H169.797V120.801H194.897V33.3008Z" fill="#6772E5" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M142.903 40.6993L141.303 33.2993H119.703V120.799H144.703V61.4993C150.603 53.7993 160.603 55.1993 163.703 56.2993V33.2993C160.503 32.0993 148.803 29.8993 142.903 40.6993Z"
      fill="#6772E5"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M92.8984 11.6016L68.4984 16.8016L68.3984 96.9016C68.3984 111.702 79.4984 122.602 94.2984 122.602C102.498 122.602 108.498 121.102 111.798 119.302V99.0016C108.598 100.302 92.7984 104.902 92.7984 90.1016V54.6016H111.798V33.3016H92.7984L92.8984 11.6016Z"
      fill="#6772E5"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.3 58.7016C25.3 54.8016 28.5 53.3016 33.8 53.3016C41.4 53.3016 51 55.6016 58.6 59.7016V36.2016C50.3 32.9016 42.1 31.6016 33.8 31.6016C13.5 31.6016 0 42.2016 0 59.9016C0 87.5016 38 83.1016 38 95.0016C38 99.6016 34 101.102 28.4 101.102C20.1 101.102 9.5 97.7016 1.1 93.1016V116.902C10.4 120.902 19.8 122.602 28.4 122.602C49.2 122.602 63.5 112.302 63.5 94.4016C63.4 64.6016 25.3 69.9016 25.3 58.7016Z"
      fill="#6772E5"
    />
  </svg>
);
