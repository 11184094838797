import { motion } from 'framer-motion';

interface IProps {
  children: React.ReactNode;
  onShowPanel?: () => void;
  onHidePanel?: () => void;
  gesture: 'hover' | 'tap';
}

export const MessageItemWrapper = ({ children, onShowPanel, onHidePanel, gesture }: IProps) => {
  return (
    <motion.div
      onHoverStart={gesture === 'hover' ? onShowPanel : undefined}
      onHoverEnd={gesture === 'hover' ? onHidePanel : undefined}
      onTap={gesture === 'tap' ? onShowPanel : undefined}
      whileTap={gesture === 'tap' ? { scale: 0.998 } : undefined}
      transition={{ type: 'spring', stiffness: 400, damping: 17 }}>
      {children}
    </motion.div>
  );
};
