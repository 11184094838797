'use client';
import { ReactNode, useMemo, useState, useCallback } from 'react';
import {
  Button as KorButton,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  TConversationParticipantSource,
  useConversationContext,
  Icon,
} from '@ui/components';
import EasyTagInput, {
  IEasyTag,
} from '@ui/components/moleculas/easy-tag-input/easy-tag-input-component';
import Joi from 'joi';

export interface IProps {
  onAddMembers: (members: TConversationParticipantSource) => void;
  children: ReactNode;
}
export const EmailsSelectionModal = ({ children, onAddMembers }: IProps) => {
  const state = useConversationContext();
  const [selections, setSelections] = useState<IEasyTag[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const isDisabled = useMemo(() => state.isGroupLoading, [state.isGroupLoading]);

  const onAdd = useCallback(() => {
    onAddMembers({
      type: 'email',
      emails: selections.map((s) => s.text),
      count: selections.length,
      label: 'Emails',
    });
    setIsOpen(false);
  }, [onAddMembers, selections]);

  const onClear = useCallback(() => setSelections([]), []);

  const emailRegex = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/g;

  const handleFileChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file && file.name.endsWith('.csv')) {
      const reader = new FileReader();
      reader.onload = () => {
        const text = reader.result as string;
        const emails = text.match(emailRegex) || [];
        const filteredEmail = Array.from(new Set(emails));

        setSelections((prevSelections) => {
          const currentEmails = prevSelections.map((e) => e.text);
          const newEmails = filteredEmail.filter((email) => !currentEmails.includes(email));
          const updatedSelections = [
            ...prevSelections,
            ...newEmails.map((email) => ({ id: email, text: email })),
          ];

          return updatedSelections;
        });

        e.target.value = '';
      };
      reader.readAsText(file);
    } else {
      alert('Please upload a valid CSV file.');
    }
  }, []);

  const handleTagChange = useCallback((updatedTags: IEasyTag) => {
    setSelections((state) => [...state, updatedTags]);
  }, []);
  const handleTagRemove = useCallback((tag: IEasyTag) => {
    setSelections((state) => state.filter((t) => t.id !== tag.id));
  }, []);

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        <span>{children}</span>
      </DialogTrigger>
      <DialogContent
        className="bg-white p-6 sm:max-w-lg"
        style={{ cursor: state.isGroupLoading ? 'progress' : 'default' }}>
        <DialogHeader>
          <DialogTitle className="text-2xl">Add Emails</DialogTitle>
        </DialogHeader>

        <div>
          <EasyTagInput
            placeholder="Type and ↵"
            validationSchema={Joi.string().email({ tlds: { allow: false } })}
            tags={selections}
            onTagAdd={handleTagChange}
            onTagRemove={handleTagRemove}
          />

          <div className="mt-4">
            <label
              htmlFor="csvUpload"
              className="w-full text-xs cursor-pointer inline-flex items-center justify-center rounded-sm h-9 border px-4 py-2 bg-white text-gray-600 hover:bg-gray-100">
              <Icon type="plus" className="mr-2 rounded-full" /> Add from CSV
            </label>
            <input
              id="csvUpload"
              type="file"
              accept=".csv"
              onChange={handleFileChange}
              className="hidden"
            />
          </div>
        </div>

        <DialogFooter className="justify-end">
          <KorButton type="outline" size="small" onClick={onClear}>
            Clear
          </KorButton>

          <KorButton
            type="primary"
            size="small"
            disabled={isDisabled || selections.length === 0}
            onClick={onAdd}>
            Add Emails
          </KorButton>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
