'use client';
import React, { useState, createContext, useContext } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { twMerge } from 'tailwind-merge';
import { LucideIcon } from 'lucide-react';

const EasyTabContext = createContext<
  | {
      selectedTab: string;
      setSelectedTab: (tab: string) => void;
    }
  | undefined
>(undefined);

interface EasyTabProps {
  children: React.ReactNode;
  defaultTab?: string;
  className?: string;
  onTabChanged?: (tab: string) => void;
}

export const EasyTab: React.FC<EasyTabProps> = ({
  children,
  defaultTab,
  className,
  onTabChanged,
}) => {
  const [selectedTab, setSelectedTab] = useState(defaultTab || '');

  const handleTabChange = (tab: string) => {
    setSelectedTab(tab);
    if (onTabChanged) {
      onTabChanged(tab);
    }
  };

  return (
    <EasyTabContext.Provider value={{ selectedTab, setSelectedTab: handleTabChange }}>
      <div className={className}>{children}</div>
    </EasyTabContext.Provider>
  );
};

interface EasyTabItemProps {
  label: string;
  className?: string;
  useBottomActiveBorder?: boolean;
  useFullBorderWhenActive?: boolean;
  icon?: LucideIcon;
  hideLabel?: boolean;
  disabled?: boolean; // New prop for disabled state
}

export const EasyTabItem: React.FC<EasyTabItemProps> = ({
  label,
  className,
  useBottomActiveBorder = true,
  useFullBorderWhenActive = false,
  icon: Icon,
  disabled = false, // Default to false
}) => {
  const context = useContext(EasyTabContext);

  if (!context) {
    throw new Error('EasyTabItem must be used within an EasyTab');
  }

  const { selectedTab, setSelectedTab } = context;
  const isActive = selectedTab === label;

  return (
    <div
      className={twMerge(
        'cursor-pointer',
        disabled && 'cursor-not-allowed opacity-50',
        className,
        useFullBorderWhenActive ? (isActive ? 'border-primary border bg-tabActive' : '') : '',
      )}
      onClick={() => {
        if (!disabled) {
          setSelectedTab(label);
        }
      }}>
      <div
        className={twMerge(
          'text-sm capitalize font-medium flex items-center justify-center gap-2',
          isActive && !disabled ? 'text-primary' : '',
          disabled ? 'text-gray-400' : '',
        )}>
        {Icon && <Icon size={16} />}
        {label}
      </div>
      {isActive && useBottomActiveBorder && !disabled && (
        <motion.div
          className="underline border border-primary"
          layoutId="underline"
          transition={{ duration: 0.3 }}
        />
      )}
    </div>
  );
};

interface EasyTabContentProps {
  label: string;
  children: React.ReactNode;
  className?: string;
}

export const EasyTabContent: React.FC<EasyTabContentProps> = ({ label, children, className }) => {
  const context = useContext(EasyTabContext);

  if (!context) {
    throw new Error('EasyTabContent must be used within an EasyTab');
  }

  const { selectedTab } = context;

  return (
    <AnimatePresence mode="wait">
      <motion.div
        key={selectedTab}
        initial={{ y: 8, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: -8, opacity: 0 }}
        transition={{ duration: 0.2 }}
        className={twMerge(className, selectedTab === label ? '' : 'hidden')}>
        {children}
      </motion.div>
    </AnimatePresence>
  );
};
