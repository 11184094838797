'use client';

import * as React from 'react';
import * as SelectPrimitive from '@radix-ui/react-select';
import { cn } from '@ui/lib/utils';
import { Icon } from '../atoms';
import { twMerge } from 'tailwind-merge';

// DelayedSelect component to introduce a delay before closing the dropdown
const DelayedSelect: React.FC<React.PropsWithChildren<any>> = ({ children, ...props }) => {
  const [delayedOpen, setDelayedOpen] = React.useState(false);

  const handleOpenChange = (newOpenState: boolean) => {
    if (newOpenState) {
      setDelayedOpen(newOpenState);
    } else {
      setTimeout(() => {
        setDelayedOpen(newOpenState);
      }, 100); // Delay of 100ms
    }
  };

  return (
    <SelectPrimitive.Root open={delayedOpen} onOpenChange={handleOpenChange} {...props}>
      {children}
    </SelectPrimitive.Root>
  );
};

const SelectTrigger = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Trigger> & {
    customTriggerIcon?: React.ReactNode;
  }
>(({ className, children, customTriggerIcon, ...props }, ref) => (
  <SelectPrimitive.Trigger
    ref={ref}
    className={cn(
      'bg-input hover:bg-secondaryHover focus:bg-secondaryHover active:bg-inputActive placeholder:text-note flex w-full items-center justify-between rounded-md border border-transparent px-4 py-3 text-base focus:outline-none disabled:cursor-not-allowed disabled:opacity-50',
      className,
    )}
    {...props}>
    {children}
    <SelectPrimitive.Icon asChild>
      {customTriggerIcon || <Icon type="back" className="ml-4 inline-block -rotate-90" />}
    </SelectPrimitive.Icon>
  </SelectPrimitive.Trigger>
));
SelectTrigger.displayName = SelectPrimitive.Trigger.displayName;

const SelectContent = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Content>
>(({ className, children, position = 'popper', ...props }, ref) => (
  <SelectPrimitive.Portal>
    <SelectPrimitive.Content
      ref={ref}
      className={cn(
        'data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2 bg-input relative z-50 min-w-[8rem] overflow-hidden rounded-md text-black',
        position === 'popper' &&
          'data-[side=bottom]:translate-y-1 data-[side=left]:-translate-x-1 data-[side=right]:translate-x-1 data-[side=top]:-translate-y-1',
        className,
      )}
      position={position}
      {...props}>
      <SelectPrimitive.Viewport
        className={cn(
          'p-1',
          position === 'popper' &&
            'h-[var(--radix-select-trigger-height)] w-full min-w-[var(--radix-select-trigger-width)]',
        )}>
        {children}
      </SelectPrimitive.Viewport>
    </SelectPrimitive.Content>
  </SelectPrimitive.Portal>
));
SelectContent.displayName = SelectPrimitive.Content.displayName;

const SelectLabel = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Label>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Label>
>(({ className, ...props }, ref) => (
  <SelectPrimitive.Label
    ref={ref}
    className={cn('text-inter text-md px-2 py-1.5 font-medium', className)}
    {...props}
  />
));
SelectLabel.displayName = SelectPrimitive.Label.displayName;

const SelectItem = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Item>
>(({ className, children, ...props }, ref) => (
  <SelectPrimitive.Item
    ref={ref}
    className={cn(
      'focus:bg-inputActive active:bg-inputActive relative flex w-full cursor-default select-none items-center rounded-md py-3 pl-4 pr-8 text-sm outline-none data-[disabled]:pointer-events-none data-[disabled]:opacity-50',
      className,
    )}
    {...props}>
    <span className="absolute right-2 flex h-3.5 w-3.5 items-center justify-center">
      <SelectPrimitive.ItemIndicator>
        <Icon type="check" />
      </SelectPrimitive.ItemIndicator>
    </span>
    <SelectPrimitive.ItemText>{children}</SelectPrimitive.ItemText>
  </SelectPrimitive.Item>
));
SelectItem.displayName = SelectPrimitive.Item.displayName;

const SelectSeparator = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Separator>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Separator>
>(({ className, ...props }, ref) => (
  <SelectPrimitive.Separator
    ref={ref}
    className={cn('-mx-1 my-1 h-px bg-slate-100 dark:bg-slate-800', className)}
    {...props}
  />
));
SelectSeparator.displayName = SelectPrimitive.Separator.displayName;

export interface ISelectProps {
  placeholder?: string;
  defaultValue?: string;
  value?: string | string[];
  options: Array<{ value: string; label: string }>;
  disabled?: boolean;
  error?: boolean;
  onChange?(value: any): void;
  triggerClassName?: string;
  selectContentClassName?: string;
  renderOption?: (option: any) => React.ReactNode;
  customTriggerContent?: string;
  customTriggerIcon?: React.ReactNode;
}

export function Select({
  placeholder,
  disabled,
  options,
  value,
  error,
  defaultValue,
  onChange,
  triggerClassName,
  selectContentClassName,
  renderOption,
  customTriggerContent,
  customTriggerIcon,
}: ISelectProps) {
  return (
    <DelayedSelect
      disabled={disabled}
      onValueChange={onChange}
      value={value}
      defaultValue={defaultValue}>
      <SelectTrigger
        className={twMerge(error ? 'border-error' : '', triggerClassName)}
        customTriggerIcon={customTriggerIcon}>
        {customTriggerContent ? (
          customTriggerContent
        ) : (
          <SelectPrimitive.Value placeholder={placeholder} />
        )}
      </SelectTrigger>
      <SelectContent className={selectContentClassName}>
        {options.map((option, index) => (
          <SelectItem value={option.value} key={index}>
            {renderOption ? renderOption(option) : option.label}
          </SelectItem>
        ))}
      </SelectContent>
    </DelayedSelect>
  );
}
