'use client';
import { IMessage, IMessagesQueryParams } from '@ui/components';
import { createContext, useContext, useState, ReactNode, useCallback, useMemo } from 'react';

type TMessage =
  | (IMessage & {
      queryParams: IMessagesQueryParams;
    })
  | null;
type ReplyRefContextType = {
  message: TMessage;
  targetRef: HTMLDivElement | null;
  expandedList: Record<string, boolean>;
  setExpanded: (id: string, expanded: boolean) => void;
  setMessage: (message: TMessage) => void;
  setTargetRef: (targetRef: HTMLDivElement | null) => void;
};

const RefContext = createContext<ReplyRefContextType | undefined>(undefined);

type RefProviderProps = {
  children: ReactNode;
};

export const ReplyRefProvider = ({ children }: RefProviderProps) => {
  const [message, setMessageInternal] = useState<TMessage>(null);
  const [targetRef, setRefInternal] = useState<HTMLDivElement | null>(null);
  const [expandedList, setExpandedList] = useState<Record<string, boolean>>({});
  const setTargetRef = useCallback((newRef: HTMLDivElement | null) => setRefInternal(newRef), []);
  const setMessage = useCallback((message: TMessage) => {
    setMessageInternal(message);
    setExpandedList((state) => ({ ...state, ...(message ? { [message.id]: true } : {}) }));
  }, []);
  const setExpanded = useCallback((id: string, expanded: boolean) => {
    setExpandedList((state) => ({ ...state, [id]: expanded }));
  }, []);
  const value = useMemo(
    () => ({ targetRef, setTargetRef, message, setMessage, setExpanded, expandedList }),
    [targetRef, setTargetRef, message, setMessage, expandedList],
  );
  return <RefContext.Provider value={value}>{children}</RefContext.Provider>;
};

export const useReplyRefContext = (): ReplyRefContextType => {
  const context = useContext(RefContext);
  if (context === undefined) {
    throw new Error('useReplyRefContext must be used within a ReplyRefProvider');
  }
  return context;
};
