import { twMerge } from 'tailwind-merge';
import { Note } from '../atoms';

interface ISteps {
  steps: string[];
  active: string;
  className?: string;
}
export function FormSteps({ steps, active, className }: ISteps) {
  return (
    <div className={twMerge('flex', className)}>
      {steps.map((step, i) => (
        <div key={i} className="group flex flex-col">
          <Note
            type="s"
            className={`mb-1 text-center font-normal ${
              active === step && 'text-primary font-medium'
            }`}>
            {step}
          </Note>
          <div
            className={`bg-input h-3 w-28 group-first-of-type:rounded-l group-last-of-type:rounded-r`}>
            <div
              className={`h-full w-28 rounded transition-colors ${
                step === active ? 'bg-primary' : 'bg-transparent'
              } `}></div>
          </div>
        </div>
      ))}
    </div>
  );
}
