export const HomeIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={22} height={22} fill="none" {...props}>
    <path
      stroke="#2D3139"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M8.08 15.137h5.814"
    />
    <path
      stroke="#2D3139"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M1.4 12.713c0-5.631.614-5.238 3.92-8.303C6.764 3.246 9.014 1 10.957 1c1.942 0 4.237 2.235 5.696 3.41 3.305 3.065 3.918 2.672 3.918 8.303C20.572 21 18.613 21 10.986 21 3.36 21 1.4 21 1.4 12.713Z"
      clipRule="evenodd"
    />
  </svg>
);
