'use client';
import './styles/main.css';
import './styles/prosemirror.css';
import { useEffect, useRef, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import {
  EditorCommand,
  EditorCommandEmpty,
  EditorCommandItem,
  EditorCommandList,
  EditorInstance,
  EditorRoot,
  HTMLContent,
  NotebookEditorContent,
} from './components';
import { defaultExtensions } from './extensions';
import { slashCommand, suggestionItems } from './components/slash-command';
import { handleCommandNavigation, ImageResizer } from '../rte-editor/extensions';
import { handleImageDrop, handleImagePaste } from './plugins';
import { uploadFn } from './components/image-upload';
import MenuSwitch from './components/menu-switch';
import { Separator } from '../separator';
import { NodeSelector } from './components/selectors/node-selector';
import { LinkSelector } from './components/selectors/link-selector';
import { MathSelector } from './components/selectors/math-selector';
import { TextButtons } from './components/selectors/text-buttons';
import { ColorSelector } from './components/selectors/color-selector';

interface Props {
  content: string;
  onUploadImage: (file: File) => Promise<string>;
  onSave: (json_text: string, html_text: string, rich_text_preview: string) => void;
}

const processPreviewContent = (html: string): string => {
  try {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');

    // Process all regular images
    const images = doc.querySelectorAll('img');
    images.forEach((img) => {
      // Add blur effect
      img.style.filter = 'blur(4px)';
      // Prevent interaction
      img.style.pointerEvents = 'none';
      img.style.userSelect = 'none';
      img.style.cursor = "none";
      // Add protective overlay
      const wrapper = doc.createElement('div');
      wrapper.style.position = 'relative';
      img.parentNode?.insertBefore(wrapper, img);
      wrapper.appendChild(img);

      const overlay = doc.createElement('div');
      overlay.style.position = 'absolute';
      overlay.style.inset = '0';
      wrapper.appendChild(overlay);

      // Prevent right-click and dragging
      img.setAttribute('draggable', 'false');
      img.setAttribute('oncontextmenu', 'return false');
    });

    const iframes = doc.querySelectorAll('iframe');
    iframes.forEach((iframe) => {
      const src = iframe.getAttribute('src');
      if (!src) return;

      const videoIdMatch = src.match(
        /(?:youtube\.com\/(?:embed\/|v\/|watch\?v=)|youtu\.be\/)([\w-]+)/,
      );
      const videoId = videoIdMatch?.[1];

      if (videoId) {
        const thumbnailDiv = doc.createElement('div');
        thumbnailDiv.innerHTML = `
          <div class="relative w-full aspect-video cursor-pointer">
            <div style="position: relative;">
              <img 
                src="https://img.youtube.com/vi/${videoId}/hqdefault.jpg" 
                alt="YouTube thumbnail" 
                class="w-full h-full object-cover rounded-lg"
                style="filter: blur(4px); pointer-events: none; user-select: none;"
                draggable="false"
                oncontextmenu="return false"
              />
              <div style="position: absolute; inset: 0; cursor: not-allowed;"></div>
            </div>
            <div class="absolute inset-0 flex items-center justify-center">
              <div class="text-white hover:text-gray-200">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="70" height="70" viewBox="0 0 256 256" xml:space="preserve">
                <defs>
                </defs>
                <g style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: none; fill-rule: nonzero; opacity: 1;" transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)" >
                  <path d="M 88.119 23.338 c -1.035 -3.872 -4.085 -6.922 -7.957 -7.957 C 73.144 13.5 45 13.5 45 13.5 s -28.144 0 -35.162 1.881 c -3.872 1.035 -6.922 4.085 -7.957 7.957 C 0 30.356 0 45 0 45 s 0 14.644 1.881 21.662 c 1.035 3.872 4.085 6.922 7.957 7.957 C 16.856 76.5 45 76.5 45 76.5 s 28.144 0 35.162 -1.881 c 3.872 -1.035 6.922 -4.085 7.957 -7.957 C 90 59.644 90 45 90 45 S 90 30.356 88.119 23.338 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(255,0,0); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
                  <polygon points="36,58.5 59.38,45 36,31.5 " style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(255,255,255); fill-rule: nonzero; opacity: 1;" transform="  matrix(1 0 0 1 0 0) "/>
                </g>
                </svg>
              </div>
            </div>
          </div>
        `;

        iframe.replaceWith(thumbnailDiv);
      }
    });

    return doc.body.innerHTML.slice(0, 2500) + (doc.body.innerHTML.length > 2500 ? '...' : '');
  } catch (error) {
    console.error('Error processing preview content:', error);
    return html;
  }
};

export const RTENotebookEditor = ({ content, onUploadImage, onSave }: Props) => {
  const extensions = [...defaultExtensions, slashCommand(onUploadImage)];
  const [initialContent, setInitialContent] = useState<HTMLContent | undefined>();
  const [openNode, setOpenNode] = useState(false);
  const [openColor, setOpenColor] = useState(false);
  const [openLink, setOpenLink] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const editorContentKey = useRef(Date.now());

  const debouncedUpdates = useDebouncedCallback(async (editor: EditorInstance) => {
    const html = editor.getHTML();
    const json = editor.getJSON();
    const rich_text_preview = processPreviewContent(html);
    onSave(JSON.stringify(json), html, rich_text_preview);
  }, 500);

  useEffect(() => {
    if (content) {
      try {
        setInitialContent(JSON.parse(content));
        editorContentKey.current = Date.now();
      } catch (e) {
        console.error(e);
      }
    }
  }, [content]);

  return (
    <div className="relative w-full notebook-editor-container" key={editorContentKey.current}>
      <EditorRoot>
        <NotebookEditorContent
          immediatelyRender
          initialContent={initialContent}
          extensions={extensions}
          className="relative min-h-[500px] w-full border-muted bg-background sm:mb-[calc(20vh)]"
          editorProps={{
            handleDOMEvents: {
              keydown: (_view, event) => handleCommandNavigation(event),
            },
            handlePaste: (view, event) => handleImagePaste(view, event, uploadFn(onUploadImage)),
            handleDrop: (view, event, _slice, moved) =>
              handleImageDrop(view, event, moved, uploadFn(onUploadImage)),
            attributes: {
              class:
                'prose prose-lg dark:prose-invert prose-headings:font-title font-default focus:outline-none max-w-full',
            },
          }}
          onUpdate={({ editor }) => debouncedUpdates(editor)}
          slotAfter={<ImageResizer />}>
          <EditorCommand className="z-50 h-auto rounded-md border border-muted bg-white px-1 py-2 shadow-md transition-all">
            <EditorCommandEmpty className="px-2 text-muted-foreground">
              No results
            </EditorCommandEmpty>
            <EditorCommandList className="max-h-[330px] overflow-y-auto">
              {suggestionItems(onUploadImage).map((item: any) => (
                <EditorCommandItem
                  value={item.title}
                  onCommand={(val) => item.command(val)}
                  className="flex w-full items-center space-x-2 rounded-md p-2 text-left text-sm hover:bg-input aria-selected:bg-inputActive"
                  key={item.title}>
                  <div className="flex h-10 w-10 items-center justify-center rounded-md border border-muted bg-background">
                    {item.icon}
                  </div>
                  <div>
                    <p className="font-medium">{item.title}</p>
                    <p className="text-xs text-muted-foreground">{item.description}</p>
                  </div>
                </EditorCommandItem>
              ))}
            </EditorCommandList>
          </EditorCommand>

          <MenuSwitch open={menuOpen} onOpenChange={setMenuOpen}>
            <Separator orientation="vertical" />
            <NodeSelector open={openNode} onOpenChange={setOpenNode} />
            <Separator orientation="vertical" />
            <LinkSelector open={openLink} onOpenChange={setOpenLink} />
            <Separator orientation="vertical" />
            <MathSelector />
            <Separator orientation="vertical" />
            <TextButtons />
            <Separator orientation="vertical" />
            <ColorSelector open={openColor} onOpenChange={setOpenColor} />
          </MenuSwitch>
        </NotebookEditorContent>
      </EditorRoot>
    </div>
  );
};
