export const ChatIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.667}
      d="M13.284 10.346h.007M9.94 10.346h.008M6.6 10.344h.008"
    />
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.25}
      d="M15.892 15.893a8.338 8.338 0 0 1-9.404 1.67c-.456-.183-3.404.633-4.044-.006-.64-.64.177-3.589-.006-4.045a8.34 8.34 0 0 1 1.67-9.405 8.334 8.334 0 0 1 11.784 11.786Z"
      clipRule="evenodd"
    />
  </svg>
);
