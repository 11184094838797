export const PostNotification = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <circle cx="10" cy="10" r="10" fill="#4D93E9" />
    <g clipPath="url(#clip0_1474_144111)">
      <path
        d="M8.79651 12.0392C8.74443 11.8373 8.63921 11.6531 8.49178 11.5057C8.34436 11.3583 8.16013 11.253 7.95826 11.201L4.37951 10.2781C4.31845 10.2608 4.26471 10.224 4.22645 10.1734C4.18818 10.1227 4.16748 10.061 4.16748 9.99754C4.16748 9.93407 4.18818 9.87234 4.22645 9.8217C4.26471 9.77106 4.31845 9.73429 4.37951 9.71696L7.95826 8.79354C8.16006 8.74151 8.34424 8.63638 8.49166 8.48906C8.63907 8.34175 8.74434 8.15764 8.79651 7.95587L9.71934 4.37712C9.7365 4.31583 9.77323 4.26182 9.82394 4.22335C9.87466 4.18489 9.93656 4.16406 10.0002 4.16406C10.0639 4.16406 10.1258 4.18489 10.1765 4.22335C10.2272 4.26182 10.2639 4.31583 10.2811 4.37712L11.2033 7.95587C11.2554 8.15775 11.3606 8.34198 11.5081 8.4894C11.6555 8.63682 11.8397 8.74205 12.0416 8.79413L15.6203 9.71637C15.6819 9.73335 15.7362 9.77005 15.7748 9.82084C15.8135 9.87163 15.8345 9.9337 15.8345 9.99754C15.8345 10.0614 15.8135 10.1235 15.7748 10.1742C15.7362 10.225 15.6819 10.2617 15.6203 10.2787L12.0416 11.201C11.8397 11.253 11.6555 11.3583 11.5081 11.5057C11.3606 11.6531 11.2554 11.8373 11.2033 12.0392L10.2805 15.618C10.2634 15.6793 10.2266 15.7333 10.1759 15.7717C10.1252 15.8102 10.0633 15.831 9.99963 15.831C9.93598 15.831 9.87407 15.8102 9.82336 15.7717C9.77265 15.7333 9.73591 15.6793 9.71876 15.618L8.79651 12.0392Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1474_144111">
        <rect width="14" height="14" fill="white" transform="translate(3 3)" />
      </clipPath>
    </defs>
  </svg>
);
