import { twMerge } from 'tailwind-merge';
import { CustomImage, Title, Button, Note } from '../atoms';

export interface IEmptyScreen {
  title: string;
  message?: string;
  button?: React.ReactNode | string;
  href?: string;
  className?: string;
  onClick?: VoidFunction;
  titleClassName?: string;
}

export function EmptyScreen({
  title,
  message,
  button,
  href,
  onClick,
  className,
  titleClassName,
}: IEmptyScreen) {
  return (
    <div
      className={twMerge(
        'lg:bg-input flex h-full w-full items-center justify-center rounded-2xl lg:py-32',
        className,
      )}>
      <div className="flex flex-col items-center">
        <CustomImage type="empty" className="mb-6 h-24 w-24" />
        <Title type="sm" className={twMerge('mb-2 text-center', titleClassName)}>
          {title}
        </Title>
        <Note className="mb-6 text-center" type="s">
          {message}
        </Note>
        {(button || href) && (
          <Button type="primary" href={href} onClick={onClick}>
            {button}
          </Button>
        )}
      </div>
    </div>
  );
}
