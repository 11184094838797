import { twMerge } from 'tailwind-merge';
import { BadgeBorder } from './components/borders';
import { BadgePattern } from './components/pattern';
import { BADGE_SIZE_MAP } from './badge.constants';
import { validateType } from './badge.utils';
import { BadgeType } from '@ui/constants';
import { formatBadgeNumber } from '@ui/lib/utils';
import { Spinner } from '@ui/components';
import DefaultBannerImage from '@ui/assets/images/DefaultBanner.png';

export type IBadgeSize = 'xl' | 'l' | 'md' | 'xs' | 'xxs' | 'xxxs';

export type IBadgeRarity = 'common';

export interface IBadgeComponent {
  type?: BadgeType;
  image?: string;
  size: IBadgeSize;
  color?: string;
  showSerialNumber?: boolean;
  rarity?: IBadgeRarity;
  className?: string;
  serialNumber?: number;
  onClick?: VoidFunction;
  badgeAmount?: number;
  showSpinner?: boolean;
  onImageLoad?: () => void;
}

export function Badge({
  type: propsType = 1,
  rarity = 'common',
  image,
  showSerialNumber = true,
  size = 'l',
  color,
  className,
  onClick,
  serialNumber = 0,
  badgeAmount = 0,
  showSpinner = false,
  onImageLoad,
}: IBadgeComponent) {
  const type = validateType(propsType);

  const { wrapperSize, borderMap, badgeSize, circleSize, snSize } = BADGE_SIZE_MAP[size];
  const borderSize = borderMap[type];

  const handleImageError = (event: React.SyntheticEvent<HTMLImageElement>) => {
    event.currentTarget.src = DefaultBannerImage.src;
    // event.currentTarget.alt = 'Error loading badge';
  };

  return (
    <div
      className={twMerge('relative flex items-center justify-center', wrapperSize, className)}
      onClick={onClick}>
      <BadgeBorder
        badgeType={type}
        className={twMerge(
          'absolute left-1/2 top-1/2 z-0 -translate-x-1/2 -translate-y-1/2',
          borderSize,
        )}
        fill={color}
      />
      <div
        className={twMerge(
          'relative flex h-52 w-52 items-center justify-center overflow-hidden rounded-full bg-white bg-opacity-60',
          circleSize,
        )}>
        <BadgePattern
          fill={color}
          className="absolute left-1/2 top-1/2 z-0 -translate-x-1/2 -translate-y-1/2"
        />
        {showSpinner && <Spinner className="absolute z-10 animate-spin" fill={color} />}
        {image && (
          <img
            src={image}
            className={twMerge(
              'relative rounded-2xl border text-center text-md bg-white object-cover',
              badgeSize,
            )}
            style={{ borderColor: color }}
            onLoad={onImageLoad}
            onError={handleImageError}
          />
        )}
      </div>
      {showSerialNumber && (
        <div
          className={twMerge(
            'absolute top-[74%] max-w-[120px] overflow-hidden text-ellipsis rounded-lg px-[6px] py-[4px] text-white shadow-md backdrop-blur-lg',
          )}>
          {badgeAmount
            ? '# ' + formatBadgeNumber(serialNumber, badgeAmount)
            : `#${formatBadgeNumber(serialNumber, badgeAmount)}`}
        </div>
      )}
    </div>
  );
}
