import { Avatar, LoadingSkeleton, Note, Text, Title } from '@ui/components/atoms';
import { EngagementScore, IEngagementScoreResponse } from '../../engagement-score';
import { usePostHogFeatureFlag } from '@ui/hooks';

export interface IFanProfileUser {
  avatar: string;
  name: string;
  rank: number;
  score: IEngagementScoreResponse;
  badgesAmount: number;
  fansAmount: number;
  memberDate: string;
  creatorName: string;
  userId?: string;
  isLoading: boolean;
}

export function FanProfileUser({
  avatar,
  name,
  rank,
  badgesAmount,
  fansAmount,
  creatorName,
  memberDate,
  isLoading,
  score,
  userId,
}: IFanProfileUser) {
  const flagEnabled = usePostHogFeatureFlag('score-engagement');

  return (
    <div className="lg:border-border flex h-fit w-full flex-col  gap-4 rounded-xl border border-transparent px-8 py-7 lg:max-w-xs">
      <div className={`flex w-full justify-left lg:justify-center`}>
        {isLoading ? (
          <LoadingSkeleton className="h-28 w-28 rounded-full" />
        ) : (
          <div className="flex items-start justify-between w-full space-x-2">
            <Avatar size="l" withGradient={true} src={avatar} />
          </div>
        )}
      </div>
      <div>
        {isLoading ? <LoadingSkeleton className="h-8 w-48" /> : <Title type="s">@{name}</Title>}
      </div>
      {score?.fan_reference_id !== userId && (
        <div>
          {flagEnabled && (
            <div className="w-fit">
              {!isLoading && (
                <EngagementScore
                  score={score}
                  border
                  isProfile
                  isOtherProfile
                  size="sm"
                  Title="Score"
                />
              )}
            </div>
          )}
        </div>
      )}
      <div>
        {isLoading ? (
          <LoadingSkeleton className="h-8 w-65 rounded-lg" />
        ) : (
          <Text type="m" className="w-full">
            LeaderBoard Position: {rank}/{fansAmount}
          </Text>
        )}
      </div>
      <div>
        {isLoading ? (
          <LoadingSkeleton className="h-8 w-40" />
        ) : (
          <Note type="m" className="w-full">
            Crew since: {memberDate}
          </Note>
        )}
      </div>
      <div>
        {isLoading ? (
          <LoadingSkeleton className="h-8 w-32" />
        ) : (
          <Note type="m" className="w-full">
            Rank: {rank}
          </Note>
        )}
      </div>
      <div>
        {isLoading ? (
          <LoadingSkeleton className="h-8 w-32" />
        ) : (
          <Note type="m" className="w-full">
            Badges: {badgesAmount}
          </Note>
        )}
      </div>
    </div>
  );
}
