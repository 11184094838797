import { LoadingSkeleton } from '@ui/components/atoms';

export function BadgeClaimInfoLoader() {
  return (
    <div className="mb-4 flex w-full flex-col gap-2">
      <LoadingSkeleton className="h-[100px]" />
      <LoadingSkeleton className="h-[60px]" />
      <div className="flex gap-2">
        <LoadingSkeleton className="h-16 basis-1/2" />
        <LoadingSkeleton className="h-16 basis-1/2" />
      </div>
    </div>
  );
}
