import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';

export interface ResetPasswordForm {
  password: string;
}

export interface IForgotPassword {
  onReset(form: ResetPasswordForm, errorCb: (e: string) => void): void;
  getTokenInfo(errorCb: (e: string) => void): void;
}

export function useResetPasswordState({ onReset, getTokenInfo }: IForgotPassword) {
  const { control, handleSubmit, setError } = useForm<ResetPasswordForm>({
    defaultValues: {
      password: '',
    },
    mode: 'all',
  });

  useEffect(() => {
    fetchTokenInfo();
  }, []);

  const [isLoading, setLoading] = useState(false);

  function handleError(message: string) {
    setError('password', { message });
  }

  async function fetchTokenInfo() {
    setLoading(true);
    try {
      const response = await getTokenInfo(handleError);
    } catch (e) {
    } finally {
      setLoading(false);
    }
  }

  async function handleResetPassword(form: ResetPasswordForm) {
    setLoading(true);
    try {
      await onReset(form, handleError);
    } catch (e) {
    } finally {
      setLoading(false);
    }
  }

  return {
    isLoading,
    password: {
      name: 'password' as 'password',
      control,
      rules: {
        required: 'Please enter your password',
      },
    },
    onSubmit: handleSubmit(handleResetPassword),
  };
}
