import { Avatar, IAvatarSize } from '@ui/components/atoms';
import { formattedDateWithDateAgo, IMessage } from '@ui/components/organisms/message';
import { ContextUserType } from '@ui/constants/context';
import { BadgeCheck, PinIcon } from 'lucide-react';
import { useMemo } from 'react';
import PostItemAuthorWrap from './post-item-author-wrap';

interface Props {
  userType: ContextUserType;
  owner: ContextUserType;
  post: IMessage;
  creatorId: string;
  avatarSize?: IAvatarSize;
  IsPinned?: boolean;
}
export const PostItemAuthor = ({
  userType,
  post,
  avatarSize = 'xs',
  creatorId,
  owner,
  IsPinned,
}: Props) => {
  const formattedDate = useMemo(() => {
    if (post.updated_at) {
      return formattedDateWithDateAgo(post.updated_at);
    } else {
      return formattedDateWithDateAgo(post.created_at);
    }
  }, [post.created_at, post.updated_at]);

  const url = useMemo(() => {
    if (owner === 'creator' && userType === 'fan') {
      return `/creator/?id=${creatorId}`;
    }
    if (userType === 'fan' && owner === 'fan') {
      return `/fan-profile/?fanId=${post?.user_id}&creatorId=${creatorId}`;
    }
    if (userType === 'creator' && owner === 'fan') {
      return `/fan-profile/?fanId=${post?.user_id}`;
    }
    return null;
  }, [post?.user_id, creatorId, userType, owner]);

  return (
    <PostItemAuthorWrap url={url}>
      <Avatar size={avatarSize} src={post.user_avatar_url} type={owner} />
      <div>
        <div className="flex flex-row items-center gap-1">
          <div className="flex items-center gap-1">
            <p className="font-nunito text-sm font-semibold">{post.user_username}</p>
            {owner === 'creator' && <BadgeCheck className="text-primary h-4 w-4" />}
          </div>

          <span className="bg-note block h-1 w-1 rounded-full"></span>
          {post?.content_updated_at && (
            <>
              <p
                className="font-nunito text-note text-xs font-light"
                style={{ fontSize: 'x-small' }}>
                updated
              </p>
            </>
          )}
          <p className="font-nunito text-note text-xs font-light" style={{ fontSize: 'x-small' }}>
            {formattedDate}
          </p>
          {IsPinned && (
            <div className="flex items-center">
              <div className="flex items-center bg-orange-100 h-5 w-5 rounded-full justify-center border border-primaryLight">
                <PinIcon className="text-primaryActive font-thin h-3 w-3 fill-white" />
              </div>
            </div>
          )}
        </div>
        {owner === 'creator' && (
          <p
            className="font-nunito text-note -mt-1 text-xs font-light"
            style={{ fontSize: 'x-small' }}>
            Creator
          </p>
        )}
      </div>
    </PostItemAuthorWrap>
  );
};
