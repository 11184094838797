import { twMerge } from 'tailwind-merge';

interface ISidebarLayout {
  wrapperClassName?: string;
  className?: string;
}

export function SidebarLayout({
  children,
  wrapperClassName,
  className,
}: React.PropsWithChildren<ISidebarLayout>) {
  return (
    <div className={twMerge('relative', wrapperClassName)}>
      <div className="w-72 bg-transparent"></div>
      <div
        className={twMerge(
          'fixed left-0 top-0 flex h-screen w-72 flex-col justify-between',
          className,
        )}>
        {children}
      </div>
    </div>
  );
}
