'use client';

import { Icon } from '../atoms';
import dayjs from 'dayjs';

import { DateRange } from 'react-day-picker';

import { cn } from '@ui/lib/utils';
import { Button } from '@ui/components/ui/button';
import { Calendar } from '@ui/components/ui/calendar';
import { Popover, PopoverContent, PopoverTrigger } from '@ui/components/ui/popover';
import { DATE_FORMATS } from '@ui/constants';

interface IDatePicker {
  range: DateRange;
  onChange(date: DateRange): void;
  className?: string;
}

export function DatePicker({ range, onChange, className }: IDatePicker) {
  function onChangeDate(date?: DateRange) {
    if (!date) {
      return;
    }
    onChange(date);
  }

  return (
    <div className={cn('grid gap-2', className)}>
      <Popover>
        <PopoverTrigger asChild>
          <Button
            id="date"
            variant={'secondary'}
            className={cn(
              'w-full min-w-[270px] justify-start text-left font-normal',
              !range && 'text-muted-foreground',
            )}>
            <div className="mr-2 text-base">
              {range?.from ? (
                range.to ? (
                  <>
                    {dayjs(range.from).format(DATE_FORMATS.full)} -{' '}
                    {dayjs(range.to).format(DATE_FORMATS.full)}
                  </>
                ) : (
                  dayjs(range.from).format(DATE_FORMATS.full)
                )
              ) : (
                <span>Pick a date</span>
              )}
            </div>
            <Icon type="calendar" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0" align="start">
          <Calendar
            initialFocus
            mode="range"
            defaultMonth={range?.from}
            selected={range}
            onSelect={onChangeDate}
            numberOfMonths={2}
            toDate={new Date()}
          />
        </PopoverContent>
      </Popover>
    </div>
  );
}
