'use client';
import { Title, Button, Icon, Text, InputForm } from '@ui/components';
import { useForgotPasswordState, ForgotForm } from './forgot-password.state';

interface IForgotPassword {
  onForgot(form: ForgotForm, errorCb: (e: string) => void): void;
  onLogin(): void;
}

export function ForgotPasswordComponent({ onLogin, onForgot }: IForgotPassword) {
  const { email, isLoading, onSubmit } = useForgotPasswordState(onForgot);
  return (
    <div className="flex h-full w-full flex-col justify-between">
      <Button
        type="icon"
        onClick={onLogin}
        className="hidden self-end lg:flex"
        disabled={isLoading}>
        <Icon type="close" />
      </Button>
      <div className="w-full">
        <Title type="l" className="mb-4 text-center lg:text-left">
          Password Recovery
        </Title>
        <Text type="m" className="mb-6 block text-center lg:mb-10 lg:text-left">
          If you can&apos;t remember your password, we can send you an email with a link to reset it
        </Text>

        <div className="mb-10 lg:mb-20">
          <InputForm label="Email" form={email} disabled={isLoading} />
        </div>

        <Button type="primary" className="w-full" onClick={onSubmit} isLoading={isLoading}>
          Reset Password
        </Button>
        <Button
          type="secondary"
          className="mt-4 w-full lg:hidden"
          onClick={onLogin}
          isLoading={isLoading}>
          Back
        </Button>
      </div>
      <div className="hidden h-12 lg:block"></div>
    </div>
  );
}
