import React from 'react';
import Pattern from '@ui/assets/images/MessagePattern.png';

interface IProps {
  children: React.ReactNode;
}
export const ConversationBackground = ({ children }: IProps) => {
  return (
    <div
      style={{
        background: `linear-gradient(0deg, #FFF2D6 0%, #FFF2D6 100%), url(${Pattern.src}), lightgray`,
        backgroundBlendMode: 'hard-light',
        backgroundRepeat: 'repeat',
        backgroundSize: '250px 250px',
        backgroundPosition: '0% 0%',
      }}
      className="h-full w-full flex-auto">
      <div
        className="flex h-full w-full flex-auto flex-col"
        style={{
          background:
            'linear-gradient(135deg, rgba(255, 151, 81, 0.30) 3.65%, rgba(255, 214, 51, 0.30) 48.96%, rgba(255, 143, 45, 0.30) 100%)',
          backgroundBlendMode: 'lighten, normal',
        }}>
        <div className="h-full">{children}</div>
      </div>
    </div>
  );
};
