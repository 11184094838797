import { LoadingSkeleton } from '@ui/components/atoms';
import { ContextUserType, useMessageContext } from '@ui/components/organisms';
import { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { twMerge } from 'tailwind-merge';

export interface MentionListProps {
  userType: ContextUserType;
  query: string;
  command: (item: { id: string; label: string }) => void;
  closePopup: () => void;
}

export interface MentionListHandle {
  onKeyDown: ({ event }: { event: KeyboardEvent }) => boolean;
}

const MentionList = forwardRef<MentionListHandle, MentionListProps>((props, ref) => {
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const { suggestions, debouncedSetUserSearchQuery, isLoadingSuggestions, singleMessage } =
    useMessageContext(props.userType);

  const items = useMemo(() => {
    if (!suggestions || suggestions.length === 0) {
      return [];
    }

    const filteredSuggestions = suggestions
      .filter((item) => item.userName?.toLowerCase().startsWith(props.query?.toLowerCase()))
      .slice(0, 8);

    const filteredInjectedRecord =
      singleMessage &&
      singleMessage.creator_display_name &&
      singleMessage?.creator_display_name?.toLowerCase()?.startsWith(props.query.toLowerCase())
        ? [
            {
              _id: singleMessage.creator_id,
              userName: singleMessage.creator_display_name,
            },
          ]
        : [];
    return [...filteredSuggestions, ...filteredInjectedRecord].slice(0, 8);
  }, [suggestions, props.query]);

  useEffect(() => {
    if (props.query.length > 0) {
      debouncedSetUserSearchQuery({ prefix: props.query, stage: 'initial' });
      const timeoutId = setTimeout(() => {
        debouncedSetUserSearchQuery({ prefix: props.query, stage: 'intermediate' });
      }, 100);
      return () => {
        clearTimeout(timeoutId);
        debouncedSetUserSearchQuery({ prefix: props.query, stage: 'terminate' });
      };
    }
  }, [props.query, debouncedSetUserSearchQuery]);

  const selectItem = (index: number) => {
    const item = items[index];
    if (item) {
      props.command({ id: item._id, label: item.userName });
    }
  };

  const upHandler = () => setSelectedIndex((selectedIndex + items.length - 1) % items.length);
  const downHandler = () => setSelectedIndex((selectedIndex + 1) % items.length);
  const enterHandler = () => selectItem(selectedIndex);

  useImperativeHandle(ref, () => ({
    onKeyDown: ({ event }) => {
      if (event.key === 'ArrowUp') {
        upHandler();
        return true;
      }

      if (event.key === 'ArrowDown') {
        downHandler();
        return true;
      }

      if (event.key === 'Enter') {
        enterHandler();
        return true;
      }

      return false;
    },
  }));

  return (
    <>
      {items.length > 0 ? (
        <div className="autocomplete-dropdown w-full md:max-w-md">
          {items.map((item, index) => (
            <button
              className={twMerge(
                index === selectedIndex ? 'is-selected' : '',
                'font-nunito rounded-sm px-2 py-1 text-sm flex flex-col justify-start items-start',
              )}
              key={item._id}
              onClick={() => selectItem(index)}>
              <p className="text-xs font-medium">@{item.userName}</p>
            </button>
          ))}
        </div>
      ) : isLoadingSuggestions ? (
        <div className="autocomplete-dropdown w-full md:max-w-md">
          <LoadingSkeleton className="h-5 w-[125px]" />
          <LoadingSkeleton className="h-5 w-[120px]" />
          <LoadingSkeleton className="h-5 w-[100px]" />
          <LoadingSkeleton className="h-5 w-[105px]" />
        </div>
      ) : (
        <></>
      )}
    </>
  );
});

export default MentionList;

MentionList.displayName = 'MentionList';
