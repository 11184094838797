export const formatFansNumber = (n: number | any): string => {
  const BILLION = 1_000_000_000;
  const MILLION = 1_000_000;
  const THOUSAND = 1_000;

  if (n >= BILLION) {
    const b = Math.floor(n / BILLION);
    const remainder = n % BILLION;
    if (remainder >= 100_000_000) {
      const decimal = Math.floor(remainder / 100_000_000) / 10;
      return `${b + decimal}B`;
    }
    return `${b}B`;
  } else if (n >= MILLION) {
    const m = Math.floor(n / MILLION);
    const remainder = n % MILLION;
    if (remainder >= 100_000) {
      const decimal = Math.floor(remainder / 100_000) / 10;
      return `${m + decimal}M`;
    }
    return `${m}M`;
  } else if (n >= THOUSAND) {
    const k = Math.floor(n / THOUSAND);
    const remainder = n % THOUSAND;
    if (remainder >= 100) {
      const decimal = Math.floor(remainder / 100) / 10;
      return `${k + decimal}K`;
    }
    return `${k}K`;
  } else {
    return n?.toString();
  }
};
