export const SearchIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.2758 1.71436C15.0034 1.71436 18.8367 5.54674 18.8367 10.2753C18.8367 15.0039 15.0034 18.8363 10.2758 18.8363C5.54722 18.8363 1.71484 15.0039 1.71484 10.2753C1.71484 5.54674 5.54722 1.71436 10.2758 1.71436Z"
      stroke={props.stroke || '#2D3139'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.8997 17.4878C19.6788 17.4878 20.3102 18.1202 20.3102 18.8983C20.3102 19.6783 19.6788 20.3097 18.8997 20.3097C18.1207 20.3097 17.4883 19.6783 17.4883 18.8983C17.4883 18.1202 18.1207 17.4878 18.8997 17.4878Z"
      stroke={props.stroke || '#2D3139'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
