import { twMerge } from 'tailwind-merge';
import { Note, Icon, UIIconType } from '../atoms';
import { BadgeSource } from '@ui/constants';

interface IAdaptedSource {
  icon: UIIconType;
  name: string;
  text?: string;
}

const SOURCE_MAP: Record<string, IAdaptedSource> = {
  [BadgeSource.YouTube]: {
    name: 'YouTube',
    icon: 'youtube',
    text: 'Add badge link to your video',
  },
  [BadgeSource.Instagram]: {
    name: 'Instagram',
    icon: 'instagram',
    text: 'Add badge link to your content',
  },
  [BadgeSource.Twitter]: {
    name: 'X (Twitter)',
    icon: 'twitter',
    text: 'Add badge link to your tweet',
  },
  [BadgeSource.Facebook]: {
    name: 'Facebook',
    icon: 'facebook',
    text: 'Add badge link to your post',
  },
  [BadgeSource.Merch]: {
    name: 'Merch',
    icon: 'bag',
    text: 'Add badge link to your merch/sponsored products',
  },
  [BadgeSource.Event]: {
    name: 'Event',
    icon: 'ticket',
    text: 'Tie badge link to special event or occasion',
  },
  [BadgeSource.Linktree]: {
    name: 'Linktree',
    icon: 'linktree',
    text: 'Add badge link to your linktree',
  },
  [BadgeSource.Beacons]: {
    name: 'Beacons',
    icon: 'beacons',
    text: 'Add a badge link to your beacons',
  },
  [BadgeSource.Custom]: {
    name: 'Custom',
    icon: 'custom',
    text: 'Add a custom URL',
  },
  [BadgeSource.Open]: {
    name: 'Open',
    icon: 'open',
    text: 'Badges can be claimed from anywhere',
  },
  [BadgeSource.Other]: {
    name: 'Other',
    icon: 'circleSquare',
    text: 'Add badge link to your custom source',
  },
};

interface ISocialItem {
  source: BadgeSource | string;
  className?: string;
  wrapperClassName?: string;
  iconClassName?: string;
  textClassName?: string;
  color?: string;
  withText?: boolean;
  isHiddenName?: boolean;
}

export function SourceItem({
  source,
  className,
  color,
  wrapperClassName,
  iconClassName,
  textClassName,
  withText,
  isHiddenName,
}: ISocialItem) {
  const item = SOURCE_MAP[source] ? SOURCE_MAP[source] : source;

  return (
    <div
      className={twMerge(
        'social-item flex min-w-fit items-center gap-2 text-center',
        wrapperClassName,
      )}>
      {typeof item === 'object' ? (
        <>
          <div className="flex min-w-[20px] items-center justify-center">
            <Icon type={item.icon} color={color || '#2D3139'} className={iconClassName} />
          </div>
          {isHiddenName ? null : (
            <Note type="m" className={twMerge('text-subtitle', className)}>
              {item.name}
            </Note>
          )}

          {withText && (
            <Note type="m" className={textClassName}>
              {item.text}
            </Note>
          )}
        </>
      ) : (
        <Note type="m" className="w-full text-center capitalize">
          {item}
        </Note>
      )}
    </div>
  );
}
