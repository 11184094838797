'use client';
import React from 'react';
import { IMessage } from '@ui/components';
import { TextWithLinks } from '@ui/components/moleculas/message-link';
import { twMerge } from 'tailwind-merge';

interface IProps {
  message: IMessage;
  className?: string;
  truncate?: boolean;
  amountOfWords?: number;
  isOwnPage?: boolean;
  isComment?: boolean;
  useCase?: 'posts' | 'messaging';
}

export const MessageItemDescription = React.memo(
  ({ message, className, truncate, amountOfWords, isOwnPage, isComment, useCase }: IProps) => {
    return (
      <div className={twMerge('max-w-[300px] rounded bg-white p-3', className)}>
        <div
          className="font-nunito text-sm"
          style={{ wordWrap: 'break-word', whiteSpace: 'pre-wrap' }}>
          <TextWithLinks
            text={message.description}
            useCase={useCase}
            isComment={isComment}
            isSinglePost={isOwnPage}
            amountOfWords={amountOfWords}
          />
        </div>
      </div>
    );
  },
);

MessageItemDescription.displayName = 'MessageItemDescription';
