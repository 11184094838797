import { Inter, Nunito_Sans } from 'next/font/google';

const inter = Inter({
  subsets: ['latin'],
  variable: '--font-inter',
});

// const nunito_sans = Nunito_Sans({
//   subsets: ['latin'],
//   variable: '--font-nunito-sans',
//   display: 'swap'
// });

export const fontClassName = `${inter.variable}`;

export function Body({ children }: React.PropsWithChildren<{}>) {
  return <body className={fontClassName}>{children}</body>;
}
