export const LogoIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.style?.width || 108}
    height={props.style?.height || 26}
    fill="none"
    {...props}>
    <path
      fill={props.fill || '#2D3139'}
      d="M2.643 25.085a1.88 1.88 0 0 1-1.88-1.88V2.395a1.88 1.88 0 0 1 1.88-1.88h6.266a1.88 1.88 0 0 1 1.88 1.88v6.493L20.2.957A1.88 1.88 0 0 1 21.41.514h5.006c1.746 0 2.55 2.173 1.223 3.309l-16.85 14.43v4.951a1.88 1.88 0 0 1-1.881 1.88H2.643Zm17.66 0a1.88 1.88 0 0 1-1.387-.61l-8.77-9.582 7.034-6.28 12.193 13.322c1.104 1.206.248 3.15-1.387 3.15h-7.683ZM32.054 12.285v.735c0-4.407 2.07-7.676 6.207-9.806C42.411 1.07 47.578 0 53.761 0c6.194 0 11.366 1.071 15.517 3.214 4.15 2.13 6.225 5.399 6.225 9.806v-.735c0 4.555-2.081 7.915-6.244 10.082-4.15 2.155-9.316 3.232-15.499 3.232-6.182 0-11.349-1.077-15.499-3.232-4.138-2.167-6.207-5.527-6.207-10.082Zm11.68.827c0 1.897.906 3.275 2.718 4.132 1.811.857 4.26 1.303 7.345 1.34 3.049.037 5.485-.392 7.309-1.285 1.824-.894 2.736-2.271 2.736-4.132v-.882c0-1.75-.912-3.024-2.736-3.82-1.824-.795-4.26-1.211-7.309-1.248-3.097-.037-5.552.361-7.364 1.194-1.8.832-2.7 2.118-2.7 3.856v.845ZM80.579 25.085a1.88 1.88 0 0 1-1.88-1.88V2.395a1.88 1.88 0 0 1 1.88-1.88h15.05c3.784 0 6.734.734 8.852 2.203 2.13 1.457 3.195 3.55 3.195 6.28 0 2.73-1.083 4.879-3.25 6.446-2.155 1.555-5.111 2.363-8.87 2.424h-6.831v5.337a1.88 1.88 0 0 1-1.88 1.88h-6.266Zm8.09-13.48h5.473c1.188 0 2.05-.164 2.59-.495.55-.343.832-.918.844-1.726 0-.857-.275-1.439-.826-1.745-.55-.318-1.42-.477-2.608-.477H88.67v4.444Zm10.47 13.48a1.88 1.88 0 0 1-1.614-.917l-5.843-9.79 10.136-.495 5.486 8.283c.828 1.25-.068 2.919-1.568 2.919H99.14Z"
    />
  </svg>
);
