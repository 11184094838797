'use client';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';

import { Popover, PopoverContent, PopoverTrigger } from '@ui/components';
import { useState, useEffect } from 'react';

export interface ISelectedEmoji {
  id: string;
  name: string;
  native: string;
  unified: string;
  keywords: string[];
  shortcodes: string;
  aliases: string[];
  emoticons: string[];
}

interface IProps {
  onEmojiSelect: (emoji: ISelectedEmoji) => void;
  children: React.ReactNode;
  className?: string;
  side?: 'left' | 'right' | 'top' | 'bottom';
  open: boolean;
  setOpen: (value: boolean) => void;
}

export const EmojiPicker = ({
  onEmojiSelect,
  children,
  className,
  side,
  open,
  setOpen,
}: IProps) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleSelect = (emoji: ISelectedEmoji) => {
    onEmojiSelect(emoji);
    setOpen(false);
  };

  return (
    <Popover open={open}>
      <PopoverTrigger className={className} asChild>
        <span>{children}</span>
      </PopoverTrigger>
      <PopoverContent
        className={`z-40 border-none bg-transparent ${
          isMobile ? 'fixed bottom-0 left-0 right-0 h-1/2' : ''
        }`}
        side={isMobile ? 'bottom' : side}>
        <Picker
          className="z-50"
          data={data}
          onEmojiSelect={handleSelect}
          theme="light"
          previewPosition="none"
          skinTonePosition="none"
          onClickOutside={(event: PointerEvent) => {
            const emojiPicker = document.querySelector('em-emoji-picker');
            const clickedInsideEmojiPicker = event.composedPath().includes(emojiPicker!);
            if (!clickedInsideEmojiPicker) {
              setOpen(false);
            }
          }}
        />
      </PopoverContent>
    </Popover>
  );
};
