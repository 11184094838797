import { twMerge } from 'tailwind-merge';

interface Condition {
  condition: boolean;
  color: string;
}

interface StatusDotProps {
  conditions?: Condition[];
  defaultColor?: string;
  className?: string;
}

export const StatusDot = ({
  conditions,
  defaultColor = 'bg-gray-600',
  className,
}: StatusDotProps) => {
  const getStatusColor = () => {
    const matchedCondition = conditions?.find(({ condition }) => condition);
    return matchedCondition ? matchedCondition.color : defaultColor;
  };

  return (
    <div className={twMerge('block h-[6px] w-[6px] rounded-full', getStatusColor(), className)} />
  );
};
