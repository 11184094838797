import { useState } from 'react';
import { Text, Button, IButtonType, Note, Icon, CustomLink } from '@ui/components/atoms';
import { LEGAL_LINKS } from '@ui/constants';
import { IBadgeClaimState } from '../../../badge-claim.typings';
import linkImg from '@ui/assets/images/Link.png';
import { sendGAEvent } from '@next/third-parties/google';
import { BadgeType } from '@ui/constants';
import { twMerge } from 'tailwind-merge';

export interface IBadgeClaimSubmit {
  badgeState?: IBadgeClaimState;
  isLoading?: boolean;
  isClaimExpired?: boolean;
  badgeType?: number | null;
  publicLink?: string | null;
  hasAchieved?: boolean;
  onClick?(): void;
  sponsors?: string[];
  openChallenge?(): void;
}

export function BadgeClaimSubmit({
  badgeState = 'default',
  isLoading,
  isClaimExpired,
  onClick,
  badgeType,
  sponsors = [],
  openChallenge,
  hasAchieved,
  publicLink,
}: IBadgeClaimSubmit) {
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [showTermsError, setShowTermsError] = useState(false);

  const handleButtonClick = () => {
    if (sponsors?.length > 0 && !termsAccepted) {
      setShowTermsError(true);
      return;
    }
    if (onClick) {
      onClick();
    }

    setShowTermsError(false);
    sendGAEvent('event', 'collectBadgeClicked', {
      event_label: 'collectBadgeClicked',
      value: JSON.stringify({
        badgeState,
        timestamp: new Date().toISOString(),
      }),
    });
  };

  const stateValue =
    badgeState !== 'collected' &&
    badgeState !== 'out-of-stock' &&
    badgeState !== 'go-to-content' &&
    badgeState !== 'already-collected';
  const isPrizeBadge = badgeType === BadgeType.Prize && !hasAchieved;
  const isPublicLinkMissing = !publicLink && badgeState === 'go-to-content';
  const buttonParamsMap: Record<
    IBadgeClaimState,
    { text: string; type: IButtonType; onClick?: () => void; error?: string }
  > = {
    default: {
      text: isPrizeBadge
        ? 'This is a Prize badge'
        : isClaimExpired
        ? 'Badge Claim Expired'
        : 'Collect Badge',
      type:
        isPrizeBadge || isClaimExpired || (sponsors?.length > 0 && !termsAccepted)
          ? 'notActive'
          : 'primary',
      onClick: isPrizeBadge || isClaimExpired ? undefined : handleButtonClick,
    },
    'go-to-content': {
      text: isClaimExpired ? 'Badge Claim Expired' : 'Go to Content to claim',
      type: isClaimExpired ? 'notActive' : 'primary',
      onClick: isClaimExpired ? undefined : handleButtonClick,
    },
    collected: {
      text: 'Badge Collected',
      type: 'notActive',
    },
    'already-collected': {
      text: 'Already Collected',
      type: 'notActive',
      error: 'Sorry you can only collect one of these badges',
    },
    'out-of-stock': {
      text: 'Out Of Stock',
      type: 'notActive',
    },

    loading: {
      text: '',
      type: 'notActive',
    },
  };

  const buttonClassName = twMerge(
    'min-h-[60px] w-full lg:max-w-[372px]',
    badgeState === 'out-of-stock' && 'text-black',
    isPublicLinkMissing && 'opacity-30 cursor-not-allowed',
  );

  return (
    <>
      <div className="mt-auto">
        <div className="flex w-full flex-col items-center bg-white">
          {isPrizeBadge && (
            <div className="font-nunito items-center text-center my-2 max-w-[370px]">
              <Note type="s" className="font-nunito">
                Earn this exclusive Prize badge by completing the challenge! Ready to prove
                yourself?{' '}
              </Note>{' '}
              <br />
              <Note type="s" className="font-nunito">
                <span
                  onClick={openChallenge}
                  className="text-primary font-semibold cursor-pointer gap-2">
                  Join the Challenge!
                </span>
              </Note>
            </div>
          )}
          {sponsors?.length > 0 && (
            <div className="flex items-center text-center my-2">
              <Text type="m">
                This badge is sponsored by{' '}
                {sponsors.length === 1 && <span className="font-bold">{sponsors[0]}</span>}
                {sponsors.length === 2 && (
                  <>
                    <span className="font-bold">{sponsors[0]}</span> and{' '}
                    <span className="font-bold">{sponsors[1]}</span>
                  </>
                )}
                {sponsors.length > 2 && (
                  <>
                    {sponsors.slice(0, -1).map((sponsor, index) => (
                      <span key={index} className="font-bold">
                        {sponsor}
                        {index < sponsors.length - 2 ? ', ' : ''}
                      </span>
                    ))}
                    , and <span className="font-bold">{sponsors[sponsors.length - 1]}</span>
                  </>
                )}
                .
              </Text>
            </div>
          )}
          {sponsors?.length > 0 && stateValue && (
            <div className="flex flex-col items-center mb-2">
              <div className="flex flex-row gap-1 items-center">
                <div onClick={() => setTermsAccepted((prev) => !prev)} className="cursor-pointer">
                  {termsAccepted ? <Icon type="checkbox" /> : <Icon type="square" />}
                </div>
                <Text type="s">
                  I have read and agreed to KOR Crew&apos;s{' '}
                  <CustomLink type="s" href={LEGAL_LINKS.terms} target="_blank">
                    Terms of Service
                  </CustomLink>
                  {' and '}
                  <CustomLink type="s" href={LEGAL_LINKS.privacy} target="_blank">
                    Privacy Policy
                  </CustomLink>
                </Text>
              </div>
              {showTermsError && !termsAccepted && (
                <Text type="s" className="text-error mt-2">
                  You must accept the Terms of Service and Privacy Policy to claim this badge.
                </Text>
              )}
            </div>
          )}
          <Button
            isLoading={isLoading}
            className={buttonClassName}
            type={buttonParamsMap[badgeState].type}
            onClick={buttonParamsMap[badgeState].onClick}>
            {buttonParamsMap[badgeState]?.text === 'Go to Content to claim' && (
              <img src={linkImg.src} alt="link" className="mr-2 h-[20px] w-[20px]" />
            )}
            {buttonParamsMap[badgeState].text}
          </Button>
          {buttonParamsMap[badgeState].error && (
            <Text type="s" className={`text-error mt-2 text-center font-semibold`}>
              {buttonParamsMap[badgeState].error}
            </Text>
          )}
        </div>
      </div>
    </>
  );
}
