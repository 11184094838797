import { twMerge } from 'tailwind-merge';
import './spinner.css';

export function Spinner(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={21}
      height={21}
      fill="none"
      className={twMerge('spinner', props.className)}>
      <path
        fill={props.fill || '#fff'}
        d="M1.66 7.9c-.531-.15-.846-.704-.645-1.218a10 10 0 1 1 7.589 13.496c-.544-.095-.854-.653-.705-1.184.149-.532.7-.836 1.247-.754A8 8 0 1 0 2.953 7.225c-.214.51-.76.823-1.292.674Z"
      />
    </svg>
  );
}
