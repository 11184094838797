import login from '@ui/assets/images/Login.png';
import empty from '@ui/assets/images/SleepyFace.png';
import image404 from '@ui/assets/images/error404.png';
import image500 from '@ui/assets/images/error500.png';

const imageMap = {
  login,
  empty,
  '404': image404,
  '500': image500,
};

export type IImageType = keyof typeof imageMap;

interface IIcon extends React.ImgHTMLAttributes<any> {
  type: IImageType;
}

export function CustomImage({ type, ...props }: IIcon) {
  const image = imageMap[type];
  return <img src={image.src} {...props} />;
}
