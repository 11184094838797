'use client';
import {
  FilePreview,
  IMessagePayload,
  InputFileWithTrigger,
  ISelectedEmoji,
  Spinner,
  useConversationContext,
  useMessageContext,
  useToast,
} from '@ui/components';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Paperclip, Smile, Send } from 'lucide-react';
import { Button } from '@ui/components';
import { useFiles } from '@ui/hooks';
import { MAX_FILES } from '@ui/hooks/file/constants';
import dynamic from 'next/dynamic';
import { twMerge } from 'tailwind-merge';

const EmojiPicker = dynamic(
  () => import('@ui/components/moleculas/emoji-picker/emoji-picker').then((mod) => mod.EmojiPicker),
  { ssr: false },
);

const initialState: IMessagePayload = {
  user_id: '',
  description: '',
  files: {
    images: [],
  },
  send_email: true,
};

interface Props {
  className?: string;
  innerClassName?: string;
  textAreaClassName?: string;
  fileRenderDivClassName?: string;
  useInputOnly?: boolean;
  onChange?: (message: IMessagePayload) => void;
}
export const ConversationMessageInput = ({
  className,
  innerClassName,
  useInputOnly,
  onChange,
  textAreaClassName,
  fileRenderDivClassName,
}: Props) => {
  const [openEmoji, setOpenEmoji] = useState(false);
  const state = useConversationContext();
  const messageState = useMessageContext('creator');
  const toast = useToast();
  const { files, onSelectFiles, deleteFile, onDiscardFiles } = useFiles();
  const ref = useRef<HTMLTextAreaElement>(null);
  const [message, setMessage] = useState<IMessagePayload>(initialState);

  useEffect(() => {
    setMessage((state) => ({ ...state, files: { images: files } }));
    if (onChange) onChange({ files: { images: files } });
  }, [files]);

  const onTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessage((state) => ({ ...state, description: e.target.value }));
    if (onChange) onChange({ description: e.target.value });
    adjustTextareaHeight(e.target);
  };

  const adjustTextareaHeight = (textarea: HTMLTextAreaElement) => {
    textarea.style.height = 'auto';
    textarea.style.height = `${textarea.scrollHeight}px`;
  };

  const onEmojiSelect = (emoji: ISelectedEmoji) => {
    if (!ref.current) return;

    const start = ref.current.selectionStart;
    const end = ref.current.selectionEnd;
    const text = ref.current.value;
    const newText = text.substring(0, start) + emoji.native + text.substring(end);

    setMessage((state) => ({ ...state, description: newText }));
    ref.current.focus();
    ref.current.setSelectionRange(start + emoji.native.length, start + emoji.native.length);
    adjustTextareaHeight(ref.current);
  };

  const FilesRender = useMemo(() => {
    return files.map((file) => {
      return <FilePreview key={file.id} file={file} onDelete={() => deleteFile(file.id)} />;
    });
  }, [files]);

  const onSubmit = async () => {
    if (!useInputOnly) {
      try {
        if (isDisableSend) return;
        const res = await messageState.onCreateMessage({
          ...message,
          thread_id: state.selectedConversationSet?.conversation.main_thread,
          creator_id: state.creator_id,
          user_id: state.creator_id,
          single_message: true,
        });

        if (!res?.error) {
          setMessage(initialState);
          onDiscardFiles();
          if (ref.current) {
            ref.current.style.height = 'auto';
          }
        } else {
          toast({ title: 'Failed to send message' }, { type: 'error' });
        }
      } catch (e) {
        toast({ title: 'Failed to send message' }, { type: 'error' });
      }
    }
  };

  const isDisableSend = useMemo(() => {
    return !message.description && files.length === 0;
  }, [message, files]);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      onSubmit();
    }
  };

  useEffect(() => {
    if (ref.current) {
      adjustTextareaHeight(ref.current);
    }
  }, []);

  const isLoading = useMemo(() => {
    return messageState.isCreating || messageState.isPostingImage;
  }, [messageState.isCreating, messageState.isPostingImage]);

  return (
    <div className={twMerge('sticky bottom-0 w-full flex-auto overflow-hidden', className)}>
      {files.length > 0 && (
        <div
          className={twMerge(
            'border-input flex w-full flex-row gap-2 overflow-x-auto rounded-t-lg border bg-white p-4',
            fileRenderDivClassName,
          )}>
          {FilesRender}
        </div>
      )}
      <div className={twMerge('flex w-full flex-row space-x-2 bg-white p-4', innerClassName)}>
        <div className="bg-input flex w-full flex-row space-x-2 rounded p-2">
          <div className="flex flex-shrink-0 flex-row">
            <InputFileWithTrigger
              maxFiles={MAX_FILES}
              className="flex h-[32px] items-center"
              exceededFileLimit={files.length >= MAX_FILES}
              onSelectFiles={onSelectFiles}>
              <Button
                type="icon-secondary"
                className="h-6 w-6 rounded-full border-none bg-transparent">
                <Paperclip className="h-4 w-4" />
              </Button>
            </InputFileWithTrigger>
            {!useInputOnly && (
              <EmojiPicker
                onEmojiSelect={onEmojiSelect}
                className="flex h-[32px] items-center"
                side="top"
                open={openEmoji}
                setOpen={setOpenEmoji}>
                <Button
                  type="icon-primary"
                  className="h-6 w-6 rounded-full border-none bg-transparent"
                  onClick={() => setOpenEmoji(true)}>
                  <Smile className="h-4 w-4" />
                </Button>
              </EmojiPicker>
            )}
          </div>
          <textarea
            ref={ref}
            rows={1}
            placeholder="Write a message..."
            tabIndex={0}
            onChange={onTextAreaChange}
            onKeyDown={handleKeyDown}
            value={message.description}
            className={twMerge(
              'm-0 max-h-[25dvh] w-full resize-none overflow-y-auto border-0 bg-transparent px-0 outline-none focus:ring-0 focus-visible:ring-0',
              textAreaClassName,
            )}
            style={{ lineHeight: '30px' }}
          />
        </div>

        {!useInputOnly && (
          <div className="flex flex-shrink-0 flex-row space-x-2">
            <Button
              onClick={onSubmit}
              size="smallIcon"
              disabled={isDisableSend || isLoading}
              type="primary"
              className="min-w-[40px] rounded">
              {isLoading ? <Spinner className="animate-spin" /> : <Send className="h-4 w-4" />}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};
