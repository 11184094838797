'use client';

import { useState } from 'react';

import { InputForm, IInputForm } from './input-form';
import { Icon } from '@ui/components/atoms';

import { FieldValues } from 'react-hook-form';

interface IInputPassword<T extends FieldValues> extends IInputForm<T> {}

export function InputPassword<T extends FieldValues>(props: IInputPassword<T>) {
  const [isShow, setShow] = useState(false);

  function onShow() {
    setShow(true);
  }

  function onHide() {
    setShow(false);
  }

  return (
    <InputForm {...props} type={isShow ? 'text' : 'password'}>
      <div className="absolute bottom-0 right-3 top-3 flex h-full items-center">
        <div className="hover:bg-secondaryActive cursor-pointer rounded-full bg-transparent p-1">
          <Icon type={isShow ? 'show' : 'hide'} onClick={isShow ? onHide : onShow} />
        </div>
      </div>
    </InputForm>
  );
}
