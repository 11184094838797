import React from 'react';
import EngagementMetricCard from './engagement-metric-card';
import { IEngagementScoreResponse } from '../engagement-typings';

interface EngagementScoreFooterProp {
  score: IEngagementScoreResponse;
}

const EngagementMetrics: React.FC<EngagementScoreFooterProp> = ({ score }) => {
  return (
    <div className="grid grid-cols-2 lg:grid-cols-3 gap-4 gap-y-6">
      <EngagementMetricCard title={'Comments'} metric={score?.breakdown?.comment_given} />
      <EngagementMetricCard title={'Replies Received'} metric={score?.breakdown?.reply_received} />
      <EngagementMetricCard title={'Replies Given'} metric={score?.breakdown?.reply_given} />
      <EngagementMetricCard title={'Downvotes Given'} metric={score?.breakdown?.downvote_given} />
      <EngagementMetricCard
        title={'Downvotes Received'}
        metric={score?.breakdown?.downvote_received}
      />
      <EngagementMetricCard title={'Reactions Given'} metric={score?.breakdown?.reaction_given} />
      <EngagementMetricCard
        title={'Reactions Received'}
        metric={score?.breakdown?.reaction_received}
      />
      <EngagementMetricCard title={'Upvotes Given'} metric={score?.breakdown?.upvote_given} />
      <EngagementMetricCard title={'Upvotes Received'} metric={score?.breakdown?.upvote_received} />
    </div>
  );
};

export default EngagementMetrics;
