import { Editor, ReactRenderer } from '@tiptap/react';
import tippy, { Instance as TippyInstance } from 'tippy.js';
import MentionList, { MentionListProps, MentionListHandle } from './mention-list';
import { RefAttributes } from 'react';
import { ContextUserType } from '@ui/constants/context';

interface MentionPluginProps {
  query: string;
  userType: ContextUserType;
  editor: Editor;
  clientRect?: () => DOMRect;
}

const Suggestion = {
  render: () => {
    let component: ReactRenderer<
      MentionListHandle,
      MentionListProps & RefAttributes<MentionListHandle>
    > | null = null;
    let popup: TippyInstance[] | null = null;

    return {
      onStart: (props: MentionPluginProps) => {
        if (!props.clientRect) {
          return;
        }

        component = new ReactRenderer(MentionList, {
          props: {
            ...props,
            query: props.query,
            userType: props.userType,
          },
          editor: props.editor,
        });

        if (!props.clientRect) {
          return;
        }

        popup = tippy('body', {
          getReferenceClientRect: props.clientRect,
          appendTo: () => document.body,
          content: component.element,
          showOnCreate: true,
          interactive: true,
          trigger: 'manual',
          placement: 'bottom-start',
        });
      },

      onUpdate(props: MentionPluginProps) {
        component?.updateProps(props);

        if (!props.clientRect) {
          return;
        }

        popup?.[0].setProps({ getReferenceClientRect: props.clientRect });
      },

      onKeyDown(props: { event: KeyboardEvent }) {
        if (props.event.key === 'Escape') {
          popup?.[0].hide();
          return true;
        }

        return component?.ref?.onKeyDown(props);
      },

      onExit() {
        popup?.[0].destroy();
        component?.destroy();
      },
    };
  },
} as any;

export default Suggestion;
