'use client';

import React, { useId } from 'react';

import { Button, IButtonSize, IButtonType } from '@ui/components/atoms';

export interface IInputFile {
  buttonParams?: {
    type?: IButtonType;
    size?: IButtonSize;
  };
  file: File | null;
  className?: string;
  disabled?: boolean;
  onFileUpload(file: File | null): void;
}

export function InputFile({
  file,
  buttonParams,
  className,
  children,
  disabled,
  onFileUpload,
}: React.PropsWithChildren<IInputFile>) {
  const id = useId();

  // TODO: issue with select same after clear
  // TODO: file format check
  function handleFileChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (disabled) {
      return;
    }
    const selectedFile = e.target.files ? e.target.files[0] : null;
    onFileUpload(selectedFile);
  }

  return (
    <>
      <input
        type="file"
        id={id}
        disabled={disabled}
        onChange={handleFileChange}
        className="hidden"
      />
      <Button
        className={className}
        disabled={disabled}
        type={buttonParams && buttonParams.type ? buttonParams.type : 'secondary'}
        size={buttonParams && buttonParams.size ? buttonParams.size : undefined}>
        <label htmlFor={id} className={'cursor-pointer'}>
          {file ? 'Change' : children}
        </label>
      </Button>
    </>
  );
}
