'use client';
import { useEffect } from 'react';
import { PostForm } from './components/post-form';
import { PostList } from './components/posts/post-list';
import { useConversationContext, useMessageContext } from '@ui/components';
import { useLoader } from '@ui/contexts';

export const CreatorCommunityView = () => {
  const conversationState = useConversationContext();
  const messageState = useMessageContext('creator');
  const { openLoader, closeLoader } = useLoader();

  useEffect(() => {
    openLoader();

    const shouldCreateCommunity =
      conversationState.communitySets.length === 0 &&
      conversationState.isCommunityConversationSuccess &&
      !conversationState.isLoadingCommunityConversations;

    if (shouldCreateCommunity) {
      conversationState.onCreateConversation({
        creator_id: conversationState.creator_id,
        name: 'Default Community',
        description: 'A dynamic space for creators and fans to share, connect, and grow together.',
        conversation_type: 'community',
        restrictions: {
          fans_react: true,
          fans_reply: true,
          fans_report: true,
        },
        public: true,
        ready: true,
      });

      return;
    }

    if (conversationState.communitySets.length > 0) {
      const communitySet = conversationState.communitySets[0];
      messageState.onSetCreator(communitySet.conversation.creator_id);
      messageState.onSetThread(communitySet.conversation.main_thread);
      messageState.onSetConversation(communitySet.conversation.id);
      messageState.onSetFan(communitySet.conversation.creator_id);
      conversationState.onSelectConversation(communitySet);
      closeLoader();
    }
  }, [
    conversationState.isCommunityConversationSuccess,
    conversationState.isLoadingCommunityConversations,
    conversationState.communitySets.length,
  ]);

  return (
    <div className="mx-auto flex w-full max-w-[756px] flex-col gap-4">
      <PostForm />
      <PostList
        communitySets={conversationState.communitySets}
        isCommunityConversationSuccess={conversationState.isCommunityConversationSuccess}
        isLoadingCommunityConversations={conversationState.isLoadingCommunityConversations}
        userType="creator"
        emptyState={{
          title: 'No posts yet',
          message:
            'It looks a bit empty here. Start by creating your first post to engage with your fans!',
        }}
      />
    </div>
  );
};
