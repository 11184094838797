import { twMerge } from 'tailwind-merge';

function BorderType4(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 325 324" fill="none" {...props}>
      <path
        fill={props.fill || '#FF6200'}
        d="M151.472 2.646c6.719-3.202 14.371-3.322 21.123-.187 16.489 7.658 50.222 26.086 86.816 62.13 38.123 37.55 56.279 72.842 63.15 88.675 2.447 5.638 2.594 11.878.303 17.581-6.333 15.76-23.591 50.626-63.453 88.566-37.857 36.032-70.389 54.228-86.541 61.917-6.907 3.288-14.778 3.115-21.637-.274-17.064-8.428-51.953-28.012-86.644-61.643-34.748-33.686-53.881-69.091-61.941-86.471-3.228-6.959-3.115-14.879.237-21.779 8.396-17.282 27.992-52.606 61.704-86.572 33.954-34.21 69.655-53.732 86.883-61.943Z"
      />
    </svg>
  );
}

function BorderType3(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="310"
      height="310"
      viewBox="0 0 310 310"
      fill={props.fill || '#FF6200'}
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <circle cx="155" cy="155" r="155" fill={props.fill || '#FF6200'} />
    </svg>
  );
}

function BorderType2(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 310 310"
      {...props}
      className={twMerge(props.className, 'rotate-45')}>
      <path
        fill={props.fill || '#FF6200'}
        d="M268.086 18.656c11.3 3.763 20.108 12.322 23.938 23.6 7.101 20.907 17.351 60.098 17.751 112.959.422 55.744-11.269 96.288-18.634 116.217-3.409 9.225-10.361 16.516-19.504 20.138-19.441 7.703-59.167 19.62-116.422 18.205-53.564-1.323-91.785-10.933-112.41-17.703-11.63-3.818-20.377-12.95-24.089-24.615-6.983-21.946-17.289-62.46-18.061-112.242-.774-49.883 10.061-90.46 17.56-112.375C22.19 31.226 31.3 22.37 42.976 18.576 65.068 11.396 105.866.84 155.214.656c49.82-.188 90.927 10.692 112.871 18Z"
      />
    </svg>
  );
}

function BorderType1(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 310 310" {...props}>
      <path
        fill={props.fill || '#FF6200'}
        d="M268.086 18.656c11.3 3.763 20.108 12.322 23.938 23.6 7.101 20.907 17.351 60.098 17.751 112.959.422 55.744-11.269 96.288-18.634 116.217-3.409 9.225-10.361 16.516-19.504 20.138-19.441 7.703-59.167 19.62-116.422 18.205-53.564-1.323-91.785-10.933-112.41-17.703-11.63-3.818-20.377-12.95-24.089-24.615-6.983-21.946-17.289-62.46-18.061-112.242-.774-49.883 10.061-90.46 17.56-112.375C22.19 31.226 31.3 22.37 42.976 18.576 65.068 11.396 105.866.84 155.214.656c49.82-.188 90.927 10.692 112.871 18Z"
      />
    </svg>
  );
}

const borderMap = {
  1: BorderType1,
  2: BorderType2,
  3: BorderType3,
  4: BorderType4,
};

interface IBadgeBorder extends React.SVGProps<SVGSVGElement> {
  badgeType: 1 | 2 | 3 | 4;
}

export function BadgeBorder({ badgeType, ...props }: IBadgeBorder) {
  const Component = borderMap[badgeType];

  return <Component {...props} />;
}
