'use client';
import { InputForm, InputPassword, Text, Title, CustomLink, Button } from '@ui/components';
import { IAuthErrorCallback, ISignUpForm, useSignUpState } from './sign-up.state';
import { LEGAL_LINKS } from '@ui/constants';
import { sendGAEvent } from '@next/third-parties/google';

interface ISignUp {
  title: string;
  description: string | React.ReactNode;
  type: 'creator' | 'fan';
  onSignUp(form: ISignUpForm, errorCb: IAuthErrorCallback): void;
  toDefault?: () => void;
  toMobileDefault?: () => void;
  oAuth?: boolean;
}

export function SignUpComponent({
  title,
  description,
  type,
  onSignUp,
  toDefault,
  toMobileDefault,
  oAuth = false,
}: ISignUp) {
  const {
    isLoading,
    nameInput,
    emailInput,
    phoneInput,
    displayNameInput,
    passwordInput,
    checkBoxInput,
    isFormValid,
    isError,
    onSubmit,
  } = useSignUpState(onSignUp, type);

  const handleSignUpClick = () => {
    if (type === 'fan') {
      sendGAEvent('event', 'fanSignupClicked', {
        signup_time: new Date().toISOString(),
      });
    } else if (type === 'creator') {
      sendGAEvent('event', 'creatorSignupClicked', {
        signup_time: 'Creator',
      });
    }
    onSubmit();
  };

  return (
    <div className="flex h-full w-full flex-col justify-center">
      <Title type="l" className="mb-4 text-center lg:text-left">
        {title}
      </Title>
      <Text type="l" className="mb-6 text-center lg:mb-10 lg:text-left">
        {description}
      </Text>
      <InputForm disabled={isLoading} label="Full Name" placeholder="Name" form={nameInput} />
      {type === 'creator' && (
        <InputForm
          disabled={isLoading}
          label="Display Name"
          placeholder="Display Name"
          form={displayNameInput}
        />
      )}
      <InputForm disabled={isLoading} label="Email" placeholder="Email" form={emailInput} />
      {type === 'fan' && (
        <InputForm
          disabled={isLoading}
          label="Phone (optional)"
          placeholder="+123-450-671"
          form={phoneInput}
        />
      )}
      <InputPassword
        disabled={isLoading}
        label="Password"
        placeholder="Password"
        form={passwordInput}
      />
      <div className="mb-12 mt-4 flex justify-center sm:mb-10 ">
        <InputForm disabled={isLoading} form={checkBoxInput} type="checkbox" />
        <Text type="s">
          I have read and agreed to KOR Crew&apos;s{' '}
          <CustomLink type="s" href={LEGAL_LINKS.terms} target="_blank">
            Terms of Service
          </CustomLink>
          {' and '}
          <CustomLink type="s" href={LEGAL_LINKS.privacy} target="_blank">
            Privacy Policy
          </CustomLink>
        </Text>
      </div>
      <Button
        isLoading={isLoading}
        disabled={!isFormValid || isError}
        type="primary"
        onClick={handleSignUpClick}
        className="mb-4 w-full">
        Continue
      </Button>
      {oAuth && (
        <Button
          type="secondary"
          onClick={() => {
            window.innerWidth < 1020 ? toMobileDefault?.() : toDefault?.();
          }}>
          Back
        </Button>
      )}
    </div>
  );
}
