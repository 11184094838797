import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogTitle,
  AlertDialogDescription,
  AlertDialogCancel,
  AlertDialogAction,
  useMessageContext,
  IMessage,
  ContextUserType,
} from '@ui/components';
import { Spinner } from '@ui/components/atoms';
import { AlertDialogHeader, AlertDialogFooter } from '@ui/components/ui';
import { useClickOutside } from '@ui/hooks';
import { useEffect, useMemo } from 'react';
import { useRouter, useSearchParams } from 'next/navigation';
interface Props {
  post: IMessage;
  userType: ContextUserType;
  isReply?: boolean;
  open: boolean;
  setOpen: (open: boolean) => void;
}
export const PostItemDeleteActionModal = (props: Props) => {
  const router = useRouter();
  const params = useSearchParams();
  const messageState = useMessageContext(props.userType);
  const ref = useClickOutside(() => props.setOpen(false));

  const handleDelete = async () => {
    await messageState.onDelete(props.post, props.isReply ? 'reply' : 'comment');
    if (params.get('slug') === props.post.slug) {
      router.push('/dashboard/community');
    }
  };

  const preventInteractionOutside = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };

  useEffect(() => {
    if (messageState.isDeleteMessageSuccess) {
      props.setOpen(false);
    }
  }, [messageState.isDeleteMessageSuccess]);

  return (
    <AlertDialog open={props.open}>
      <div onClick={preventInteractionOutside}>
        <AlertDialogContent ref={ref}>
          <AlertDialogHeader>
            <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
            <AlertDialogDescription>
              This action cannot be undone. This will permanently delete your post.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel onClick={() => props.setOpen(false)}>Cancel</AlertDialogCancel>
            <AlertDialogAction
              onClick={handleDelete}
              disabled={messageState.isDeletingMessage}
              className="bg-primary hover:bg-primaryHover">
              {messageState.isDeletingMessage && (
                <Spinner className="mr-2 animate-spin" fill="#2D3139" />
              )}
              Delete
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </div>
    </AlertDialog>
  );
};
