import { Note, Text } from '../text';
import { Icon } from '../icon';
import { ToastIcon, IToastIcon } from './toast-icon';
import { IToastPayload } from './toast';
import { ToastContentProps } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import './toast.style.css';

interface IToastComponent extends IToastPayload {
  type: IToastIcon;
}

export function ToastComponent({
  title,
  message,
  type,
  closeToast,
}: ToastContentProps & IToastComponent) {
  return (
    <div className="flex min-w-[400px] max-w-[512px] items-center justify-between gap-2 rounded-full p-2">
      <div className="flex items-center gap-2">
        <ToastIcon type={type} />
        <div className="flex flex-col gap-0.5">
          <Text type="m" className="font-semibold">
            {title}
          </Text>
          <Note type="s" className="line-clamp-2 break-words">
            {message}
          </Note>
        </div>
      </div>

      <button
        onClick={closeToast}
        className="hover:bg-bgGrey active:bg-inputActive flex aspect-square w-10 items-center justify-center rounded-full">
        <Icon type="close" />
      </button>
    </div>
  );
}
