import {
  ConversationBackground,
  ConversationHeader,
  MessageList,
  ConversationMessageInput,
  IConversationSet,
} from '@ui/components';

interface Props {
  conversationSet: IConversationSet | null;
}
export const ConversationMessagesPane = ({ conversationSet }: Props) => {
  return (
    <ConversationBackground>
      <div className="relative flex h-full flex-col overflow-hidden">
        <ConversationHeader />
        <MessageList
          className="flex-grow overflow-y-auto"
          contextUserType="creator"
          restrictions={conversationSet?.restrictions_list}
        />
        <div className="flex-shrink-0">
          <ConversationMessageInput />
        </div>
      </div>
    </ConversationBackground>
  );
};
