'use client';
import { EmptyScreen, IEmptyScreen } from '@ui/components';
import { LeaderboardList, ILeaderboardList } from './components/leaderboard-list';
import { TopFanAvatar } from './components/top-fan-avatar';
import { LeaderboardLoader } from './components/leaderboard-loader';
import { twMerge } from 'tailwind-merge';
import { TViewState } from '@ui/constants';

export interface ILeaderboardProps extends ILeaderboardList {
  status: TViewState;
  emptyState: IEmptyScreen;
  className?: string;
  fanId?: string | null;
  infiniteScrollTargetId?: string;
}

export const Leaderboard = ({
  status,
  fans,
  fanId,
  emptyState,
  className,
  hasMore,
  isLoading,
  currentFan,
  isBadgeLeaderboard,
  onNextPage,
  onFanOpen,
  infiniteScrollTargetId,
  isCustom,
}: ILeaderboardProps) => {
  const RenderMap: Record<string, () => JSX.Element> = {
    empty: () => <EmptyScreen {...emptyState} className="h-full" />,
    list: () => (
      <>
        <div className="mb-10 flex items-end justify-center gap-6 lg:gap-10">
          {fans[1] && <TopFanAvatar place={2} fan={fans[1]} onFanOpen={onFanOpen} />}
          {fans[0] && <TopFanAvatar place={1} fan={fans[0]} onFanOpen={onFanOpen} />}
          {fans[2] && <TopFanAvatar place={3} fan={fans[2]} onFanOpen={onFanOpen} />}
        </div>
        <LeaderboardList
          infiniteScrollTargetId={infiniteScrollTargetId}
          currentFan={currentFan}
          fans={fans}
          fanId={fanId}
          hasMore={hasMore}
          isLoading={isLoading}
          isBadgeLeaderboard={isBadgeLeaderboard}
          onNextPage={onNextPage}
          onFanOpen={onFanOpen}
          isCustom={isCustom}
        />
      </>
    ),
    loading: () => <LeaderboardLoader />,
  };

  return (
    <div
      className={twMerge(
        'flex w-full flex-grow flex-col items-center rounded-t-xl bg-white',
        className,
      )}>
      {RenderMap[status]()}
    </div>
  );
};
