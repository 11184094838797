'use client';
import React, { createContext, useContext, useState, ReactNode, useCallback, useRef } from 'react';
import {
  Button,
  Drawer,
  DrawerContent,
  DrawerDescription,
  DrawerHeader,
  DrawerTitle,
  FLAGGED_MESSAGE_CATEGORIES,
  IMessage,
} from '@ui/components';
import { useClickOutside } from '@ui/hooks';

interface ReportContentContextType {
  openReportContent: (message: IMessage) => void;
  onReasonSelected: (cb: (reason: string, message: IMessage) => void) => void;
}

const ReportContentContext = createContext<ReportContentContextType | undefined>(undefined);

interface ReportContentProviderProps {
  children: ReactNode;
}

export const ReportContentProvider: React.FC<ReportContentProviderProps> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState<IMessage | null>(null);
  const [reason, setReason] = useState<string>('');
  const ref = useClickOutside(() => setIsOpen(false));
  const reasonCallback = useRef<(reason: string, message: IMessage) => void>();

  const closeReportContent = useCallback(() => setIsOpen(false), []);
  const openReportContent = useCallback((message: IMessage) => {
    setMessage(message);
    setIsOpen(true);
  }, []);

  const onReasonSelected = useCallback((cb: (reason: string, message: IMessage) => void) => {
    reasonCallback.current = cb;
  }, []);

  const handleReportMessage = useCallback(
    (category: string) => {
      setReason(category);
      setIsOpen(false);
      if (message && reasonCallback.current) {
        reasonCallback.current(category, message);
      }
    },
    [message],
  );

  return (
    <ReportContentContext.Provider value={{ openReportContent, onReasonSelected }}>
      {children}

      <Drawer open={isOpen} onClose={closeReportContent}>
        <DrawerContent className="m-auto focus-visible:outline-none md:max-w-sm" ref={ref}>
          <DrawerHeader className="border-b">
            <DrawerTitle>Report Content</DrawerTitle>
            <DrawerDescription>Select a reason to report this message</DrawerDescription>
          </DrawerHeader>

          <div className="mt-4 h-full max-h-96 overflow-y-auto">
            {FLAGGED_MESSAGE_CATEGORIES.map((category, index) => (
              <Button
                type="secondary"
                key={index}
                onClick={() => handleReportMessage(category)}
                className="w-full rounded-none bg-white text-sm">
                {category}
              </Button>
            ))}
          </div>
        </DrawerContent>
      </Drawer>
    </ReportContentContext.Provider>
  );
};

export const useReportContent = (): ReportContentContextType => {
  const context = useContext(ReportContentContext);
  if (context === undefined) {
    throw new Error('useReportContent must be used within a ReportContentProvider');
  }
  return context;
};
