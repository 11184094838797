export const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10 MB
export const ALLOWED_FILE_TYPES = [
  // Image MIME Types
  'image/jpeg', // .jpeg
  'image/jpg', // .jpg
  'image/png', // .png
  'image/gif', // .gif
  'image/svg+xml', // .svg
  'image/tiff', // .tif, .tiff
  'image/bmp', // .bmp
  'image/webp', // .webp

  // Document MIME Types
  // 'application/msword', // .doc
  // 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
  // 'application/pdf', // .pdf

  // Audio MIME Types
  // 'audio/mpeg', // .mp3
  // 'audio/wav', // .wav
  // 'audio/aac', // .aac
  // 'audio/ogg', // .ogg
  // 'audio/flac', // .flac

  // Video MIME Types
  // 'video/mp4', // .mp4
  // 'video/x-msvideo', // .avi
  // 'video/quicktime', // .mov
  // 'video/x-ms-wmv', // .wmv
  // 'video/x-flv', // .flv
  // 'video/x-matroska', // .mkv
  // 'video/webm', // .webm
] as const;

export const MAX_FILES = 4;
