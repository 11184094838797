import { useEffect, useMemo } from 'react';
import posthog from 'posthog-js';

interface UserIdentity {
  distinctId: string;
  properties?: {
    [key: string]: any;
  };
}

export function usePostHogIdentity(userIdentity?: UserIdentity) {
  const memoizedUserIdentity = useMemo(() => userIdentity, [JSON.stringify(userIdentity)]);

  useEffect(() => {
    if (
      memoizedUserIdentity?.distinctId &&
      posthog.has_opted_in_capturing() &&
      posthog.get_distinct_id() !== memoizedUserIdentity.distinctId
    ) {
      posthog.identify(memoizedUserIdentity.distinctId, memoizedUserIdentity.properties);
    } else {
      if (!memoizedUserIdentity?.distinctId) {
        console.error(
          '[usePostHogIdentity] distinctId is missing, unable to identify user in PostHog.',
        );
      } else if (!posthog.has_opted_in_capturing()) {
        console.error('[usePostHogIdentity] PostHog is not ready to capture events.');
      }
    }
  }, [memoizedUserIdentity]);
}
