const ToastIconMap = {
  success: (
    <div className="flex aspect-square w-16 items-center justify-center rounded-full bg-[#E6F9ED]">
      <svg xmlns="http://www.w3.org/2000/svg" width={28} height={28} fill="none">
        <path
          fill="#07CA4B"
          fillRule="evenodd"
          d="M14 24.791C5.908 24.791 3.21 22.093 3.21 14S5.907 3.208 14.001 3.208c8.093 0 10.791 2.699 10.791 10.792S22.094 24.79 14.001 24.79Zm4.685-13.03a.75.75 0 0 0-1.061-1.06l-5.007 5.007-2.24-2.239a.75.75 0 0 0-1.06 1.061l2.77 2.769a.75.75 0 0 0 1.06 0l5.538-5.537Z"
          clipRule="evenodd"
        />
      </svg>
    </div>
  ),
  error: (
    <div className="flex aspect-square w-16 items-center justify-center rounded-full bg-[#F9E6E6]">
      <svg xmlns="http://www.w3.org/2000/svg" width={28} height={28} fill="none">
        <path
          fill="#F54F4E"
          fillRule="evenodd"
          d="M3.209 14c0-8.093 2.698-10.792 10.792-10.792 8.093 0 10.791 2.699 10.791 10.792S22.094 24.79 14.001 24.79C5.907 24.791 3.209 22.093 3.209 14Zm10.792-5.295a.75.75 0 0 1 .75.75V14a.75.75 0 0 1-1.5 0V9.455a.75.75 0 0 1 .75-.75Zm-.756 9.378a.75.75 0 0 1 .75-.75h.01a.75.75 0 0 1 0 1.5h-.01a.75.75 0 0 1-.75-.75Z"
          clipRule="evenodd"
        />
      </svg>
    </div>
  ),
  warning: (
    <div className="flex aspect-square w-16 items-center justify-center rounded-full bg-[#FAF0D1]">
      <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none">
        <path
          fill="#F5CB4E"
          fillRule="evenodd"
          d="M12 21c-6.494 0-9.043-.461-9.456-2.797-.414-2.335 2.23-6.726 3.044-8.175C8.313 5.184 10.164 3 12 3c1.836 0 3.687 2.184 6.412 7.028.814 1.45 3.458 5.84 3.044 8.175C21.044 20.54 18.494 21 12 21Z"
          clipRule="evenodd"
        />
        <path
          stroke="#FCFCFC"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="M12 8.5v3.895M11.995 15.895h.01"
        />
      </svg>
    </div>
  ),
};

export type IToastIcon = keyof typeof ToastIconMap;

export function ToastIcon({ type }: { type: IToastIcon }) {
  const statusIcon = ToastIconMap[type];

  return statusIcon;
}
