import { LoadingSkeleton } from '@ui/components';

export function LeaderboardLoader() {
  return (
    <>
      <div className="mb-10 flex items-end justify-center gap-10">
        <div className="flex flex-col items-center gap-2">
          <LoadingSkeleton className="aspect-square w-20 rounded-full" />
          <LoadingSkeleton className="h-4 w-20" />
        </div>
        <div className="flex flex-col items-center gap-2">
          <LoadingSkeleton className="aspect-square w-28 rounded-full" />
          <LoadingSkeleton className="h-4 w-20" />
        </div>
        <div className="flex flex-col items-center gap-2">
          <LoadingSkeleton className="aspect-square w-20 rounded-full" />
          <LoadingSkeleton className="h-4 w-20" />
        </div>
      </div>
      <div className="flex w-full flex-col items-center gap-4 px-2">
        <LoadingSkeleton className="h-16 w-full max-w-[600px] md:w-2/3" />
        <LoadingSkeleton className="h-16 w-full max-w-[600px] md:w-2/3" />
        <LoadingSkeleton className="h-16 w-full max-w-[600px] md:w-2/3" />
        <LoadingSkeleton className="h-16 w-full max-w-[600px] md:w-2/3" />
        <LoadingSkeleton className="h-16 w-full max-w-[600px] md:w-2/3" />
        <LoadingSkeleton className="h-16 w-full max-w-[600px] md:w-2/3" />
      </div>
    </>
  );
}
