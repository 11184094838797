export const LinktreeIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 40 40"
    fill={props.color || '#707B8F'}
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.9551 27.46H23.5344V39.9993H17.9551V27.46Z"
      fill={props.color || '#707B8F'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.059 14.624H14.5396L7.78966 8.2352L11.5092 4.44485L17.9311 11.0131V1.66699H23.5104V11.0107L29.9299 4.44249L33.6495 8.23284L26.8995 14.6217H36.3825V19.906H26.8405L33.6212 26.4742L29.9016 30.1772L20.6901 20.9515L11.4785 30.1772L7.75898 26.4742L14.5396 19.906H5V14.624H5.059Z"
      fill={props.color || '#707B8F'}
    />
  </svg>
);
