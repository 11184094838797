export const InfoIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} viewBox="0 0 20 20" fill="none" {...props}>
    <path
      stroke={props.fill || props.stroke || '#707B8F'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M19.25 10c0 6.937-2.313 9.25-9.25 9.25C3.063 19.25.75 16.937.75 10 .75 3.063 3.063.75 10 .75c6.937 0 9.25 2.313 9.25 9.25Z"
      clipRule="evenodd"
    />
    <path
      stroke={props.stroke || '#707B8F'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M10 13.895V10M10.005 6.5h-.01"
    />
  </svg>
);
