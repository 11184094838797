import React from 'react';
import Link from 'next/link';

interface PostItemAuthorWrapProp {
  children: React.ReactNode;
  url: string | null;
}

const PostItemAuthorWrap: React.FC<PostItemAuthorWrapProp> = ({ children, url }) => {
  const commonProps = {
    className: 'mt-2 flex items-center gap-2',
  };

  return url ? (
    <Link href={url} {...commonProps}>
      {children}
    </Link>
  ) : (
    <div {...commonProps}>{children}</div>
  );
};

export default PostItemAuthorWrap;
