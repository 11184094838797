import { twMerge } from 'tailwind-merge';
import { IBadgeClaimBadge, IBadgeClaimCreators } from '../../../badge-claim.typings';
import { IBadgeClaimState, IBadgeClaimType } from '../../../badge-claim.typings';
import { badgeClaimClassName } from '../../../badge-claim.style';
import { BadgeClaimInfoLoader } from './badge-claim-info-loader';
import { BadgeClaimInfoDetails } from './badge-claim-info-details';
import { BadgeClaimOutOfStockDetails } from './badge-claim-out-of-stock-details';

interface IBadgeClaimInfoPanel {
  badge: IBadgeClaimBadge;
  claimType: IBadgeClaimType;
  badgeState: IBadgeClaimState;
  creators: IBadgeClaimCreators;
  children: React.ReactNode;
  currentTab?: string;
  isClaimExpired?: boolean;
  creatorHasCommunity?: boolean;
  badgeType?: number | null;
  gotoForum?(): void;
}

export function BadgeClaimInfoPanel({
  claimType,
  badgeState,
  creators,
  badge,
  children,
  currentTab,
  gotoForum,
  isClaimExpired,
  badgeType,
  creatorHasCommunity,
}: IBadgeClaimInfoPanel) {
  const className = badgeClaimClassName[claimType];

  return (
    <div
      className={twMerge(
        'flex rounded-t-lg bg-white lg:flex-1',
        className.badgeDetails.infoContainer,
      )}>
      <div
        className={twMerge(
          'flex w-full flex-col justify-between px-9 py-12 lg:px-20',
          className.badgeDetails.info,
        )}>
        {badgeState === 'loading' ? (
          <BadgeClaimInfoLoader />
        ) : badgeState !== 'out-of-stock' ? (
          <BadgeClaimInfoDetails
            isClaimExpired={isClaimExpired}
            badgeAmount={badge.amount}
            badgeNumber={badge.badgeNumber}
            transferDate={badge.transferDate}
            gotoForum={gotoForum}
            platform={badge.platform}
            publicLink={badge.publicLink}
            isAvailable={badge.isAvailable}
            currentTab={currentTab}
            badgeType={badgeType}
            creatorHasCommunity={creatorHasCommunity}
          />
        ) : (
          <BadgeClaimOutOfStockDetails
            badgeAmount={badge.amount}
            badgeNumber={badge.badgeNumber}
            creators={creators}
            platform={badge.platform}
            publicLink={badge.publicLink}
            isAvailable={badge.isAvailable}
            currentTab={currentTab}
            badgeType={badgeType}
          />
        )}

        {children}
      </div>
    </div>
  );
}
