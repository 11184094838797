'use client';
import { useController, RegisterOptions, Control, FieldValues, Path } from 'react-hook-form';
import { Select, ISelectProps } from '../ui';
import { FormItem } from '../atoms/form-item';

export interface ISelectFormProps<T extends FieldValues> extends ISelectProps {
  form: {
    control: Control<T>;
    name: Path<T>;
    rules?: RegisterOptions<T, Path<T>>;
    error?: string;
  };
  label?: string;
  tooltip?: string;
  wrapperClassName?: string;
}

export function SelectForm<T extends FieldValues>({
  form: { control, name, rules },
  label,
  tooltip,
  wrapperClassName,
  ...select
}: ISelectFormProps<T>) {
  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController<T>({
    control,
    name,
    rules,
  });

  return (
    <FormItem label={label} tooltip={tooltip} error={error?.message} className={wrapperClassName}>
      <Select {...select} error={!!error?.message} value={value} onChange={onChange} />
    </FormItem>
  );
}
