export const BagIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" fill="none" {...props}>
    <path
      stroke={props.color || '#707B8F'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M12.126 6.893V4.366a3.151 3.151 0 1 0-6.302-.014v2.541"
    />
    <path
      stroke={props.color || '#707B8F'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M1.459 10.84c0-4.412 1.88-5.883 7.516-5.883s7.516 1.47 7.516 5.882c0 4.411-1.88 5.882-7.516 5.882-5.637 0-7.516-1.47-7.516-5.882Z"
      clipRule="evenodd"
    />
  </svg>
);
