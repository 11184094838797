import { IConversation, Input } from '@ui/components';

interface IProps {
  conversation: IConversation;
  onChangeDetails: (conversation: IConversation) => void;
}
export const UpdateConversationDetails = ({ conversation, onChangeDetails }: IProps) => {
  return (
    <div className="h-full w-full px-4">
      <div>
        <p className="text-sm font-medium">Name</p>
        <Input
          value={conversation.name}
          onChange={(e: any) => onChangeDetails({ ...conversation, name: e.target.value })}
        />
      </div>
      <div>
        <p className="text-sm font-medium">Description</p>
        <Input
          isTextArea
          value={conversation.description}
          onChange={(e: any) => onChangeDetails({ ...conversation, description: e.target.value })}
        />
      </div>
    </div>
  );
};
