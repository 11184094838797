'use client';
import { Title, Note, Text, SourceItem, Icon } from '@ui/components';
import { BadgeSource, BadgeType } from '@ui/constants';
import { useMemo } from 'react';
import { twMerge } from 'tailwind-merge';

interface IBadgeClaimInfo {
  badgeNumber: number;
  badgeAmount: number;
  transferDate: string;
  platform: BadgeSource;
  publicLink: string;
  isAvailable?: string | boolean;
  currentTab?: string;
  isClaimExpired?: boolean;
  badgeType?: number | null;
  creatorHasCommunity?: boolean;
  gotoForum?(): void;
}

export function BadgeClaimInfoDetails({
  badgeAmount,
  badgeNumber,
  transferDate,
  platform,
  publicLink,
  isAvailable,
  gotoForum,
  currentTab,
  isClaimExpired,
  badgeType,
  creatorHasCommunity,
}: IBadgeClaimInfo) {
  function onPublicLink() {
    if (!publicLink) {
      return;
    }
    window.open(publicLink, '_blank');
  }

  const shouldRenderContainer = useMemo(() => {
    return (
      (isClaimExpired &&
        (badgeType === BadgeType.Engagement || badgeType === BadgeType.Community)) ||
      badgeType === BadgeType.Prize ||
      (badgeType === BadgeType.Community && creatorHasCommunity) ||
      (publicLink && !isClaimExpired)
    );
  }, [isClaimExpired, badgeType, creatorHasCommunity, publicLink]);

  return (
    <div className="mb-4 flex w-full flex-col gap-2">
      <div className="bg-input flex flex-col items-center rounded p-3">
        <Note type="xs" className={`text-[#534944] ${!isAvailable && badgeAmount === 0 && 'mb-2'}`}>
          {!isAvailable &&
          (currentTab === 'My Badges' ||
            currentTab === 'All Badges' ||
            currentTab === 'Leaderboards') ? (
            <>{badgeAmount === 0 ? 'Badge Number' : 'Badges Remaining'}</>
          ) : (
            'Badge Number'
          )}
        </Note>
        <Title type="mm">
          {!isAvailable &&
          (currentTab === 'My Badges' ||
            currentTab === 'All Badges' ||
            currentTab === 'Leaderboards')
            ? `#${badgeAmount == 0 ? badgeNumber : 0}`
            : `#${badgeNumber}`}
        </Title>
        <div
          className={`bg-inputActive rounded-full px-2 py-1 ${
            !isAvailable && badgeAmount === 0 && 'hidden'
          }`}>
          <Note type="m" className="text-[#534944]">
            {badgeAmount === 0 ? `${badgeNumber}` : `of ${badgeAmount}`}
          </Note>
        </div>
      </div>

      <div className="flex gap-2">
        <div
          className={`bg-input flex ${
            shouldRenderContainer ? 'basis-1/2' : 'w-full'
          } flex-col items-center justify-center rounded p-3`}>
          <Note type="xs" className="text-[#534944]">
            Transfer Date
          </Note>
          <Text type="m" className="font-semibold">
            {transferDate}
          </Text>
        </div>

        {shouldRenderContainer && (
          <div
            onClick={isClaimExpired ? undefined : onPublicLink}
            className={twMerge(
              'bg-input flex basis-1/2 items-center justify-between gap-2 rounded p-3',
              publicLink && !isClaimExpired
                ? 'hover:bg-inputActive active:bg-secondaryHover cursor-pointer'
                : '',
            )}>
            {isClaimExpired &&
              (badgeType === BadgeType.Engagement || badgeType === BadgeType.Community) && (
                <div className="w-full items-center text-center">
                  <Text type="m" className="font-semibold text-[#534944]">
                    Expired
                  </Text>
                </div>
              )}

            {badgeType === BadgeType.Prize && (
              <div className="w-full flex items-center justify-center">
                <Icon type="trophy" className="items-center" />
              </div>
            )}

            {badgeType === BadgeType.Community && creatorHasCommunity && (
              <div
                className="w-full flex items-center gap-2 justify-center cursor-pointer 
                active:scale-95 transition-transform duration-150 hover:bg-gray-100 rounded-md p-2"
                onClick={gotoForum}>
                <Text type="m" className="font-semibold">
                  Community
                </Text>
                <Icon type="people" />
              </div>
            )}

            {publicLink && !isClaimExpired && (
              <div
                className="w-full flex items-center justify-between gap-2 cursor-pointer"
                onClick={onPublicLink}>
                <div className="w-6" />
                <SourceItem source={platform} />
                <Icon type="link" />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
