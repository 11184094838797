'use client';
import { useId, useMemo, useState, useCallback } from 'react';
import {
  CommentItem,
  IMessage,
  IMessagesQueryParams,
  INormalizedMessagesOfThreadResponse,
  useMessageContext,
} from '@ui/components';
import { ContextUserType } from '@ui/constants/context';
import InfiniteScroll from 'react-infinite-scroll-component';
import { CommentItemSkeleton } from '../comments/comment-item-skeleton';

interface Props {
  userType: ContextUserType;
  comment: IMessage;
  creator_id: string;
  user_id: string;
}

export const ReplyList = ({ userType, comment, creator_id, user_id }: Props) => {
  const id = useId();
  const messageState = useMessageContext(userType);
  const [query, setQuery] = useState<IMessagesQueryParams>({
    ordering: 'asc',
    page: 1,
    pageSize: 12,
    thread_id: comment.child_thread_id,
    conversation_id: comment.conversation_id,
    creator_id,
    fan_id: user_id,
  });

  const { data, isLoading, isSuccess } = messageState.useGetMessagesOfThreadQuery(query, {
    skip: !query.thread_id || !query.creator_id || !query.conversation_id,
    selectFromResult: ({ data, ...params }: { data: INormalizedMessagesOfThreadResponse }) => ({
      data: {
        ...data,
        messages: messageState.messagesSelector.selectAll(
          data ? data.messages : messageState.messagesAdapter.getInitialState(),
        ),
      },
      ...params,
    }),
  });

  const replies = useMemo(() => data.messages, [data.messages]);
  const hasMorePages = useMemo(
    () => (isSuccess && data ? query.page < data.totalPages : false),
    [data, query.page, isSuccess],
  );

  const onNextPage = useCallback(() => setQuery((prev) => ({ ...prev, page: prev.page + 1 })), []);

  if (isLoading && replies.length === 0) {
    return (
      <div className="flex flex-col gap-y-6 pt-4">
        <CommentItemSkeleton />
        <CommentItemSkeleton />
      </div>
    );
  }

  return (
    <div>
      <InfiniteScroll
        className=" pace-y-2"
        dataLength={replies.length}
        next={onNextPage}
        hasMore={hasMorePages}
        height={replies.length < 11 ? 'fit-content' : 700}
        scrollableTarget={id}
        loader={
          <div className="py-4">
            <CommentItemSkeleton />
          </div>
        }>
        {replies.map((reply: IMessage) => (
          <CommentItem
            key={reply.id}
            comment={reply}
            userType={userType}
            user_id={messageState.user_id}
            creator_id={creator_id}
            enableReply={false}
            query={query}
            reportLabel="Report reply"
            isReply
            main_post_ref={''}
          />
        ))}
      </InfiniteScroll>
    </div>
  );
};
