'use client';
import { IConversationState } from '@ui/components';
import { createContext, useContext, ReactNode, useMemo } from 'react';

const ConversationContext = createContext<IConversationState | undefined>(undefined);

interface ConversationProviderProps {
  children: ReactNode;
  state: IConversationState;
}
export const ConversationProvider = ({ children, state }: ConversationProviderProps) => {
  const memoizedState = useMemo(() => state, [state]);
  return (
    <ConversationContext.Provider value={memoizedState}> {children}</ConversationContext.Provider>
  );
};

export const useConversationContext = () => {
  const context = useContext(ConversationContext);
  if (context === undefined) {
    throw new Error('useConversationContext must be used within a ConversationProvider');
  }
  return context;
};
