import { useState, useEffect } from 'react';
import { IEngagementScoreResponse } from '../engagement-typings';

export const useScoreIncrease = (score: IEngagementScoreResponse) => {
  const [prevScore, setPrevScore] = useState(score?.total_engagement_score);
  const [prevCreator, setPrevCreator] = useState(score?.creator_id);
  const [showIncrease, setShowIncrease] = useState(false);
  const [increaseAmount, setIncreaseAmount] = useState(0);

  useEffect(() => {
    if (score?.total_engagement_score && prevScore && score.total_engagement_score > prevScore) {
      setIncreaseAmount(score.total_engagement_score - prevScore);
      if (score?.creator_id === prevCreator) {
        setShowIncrease(true);
      }
      setTimeout(() => setShowIncrease(false), 1000);
    }
    setPrevScore(score?.total_engagement_score);
    setPrevCreator(score?.creator_id);
  }, [score?.total_engagement_score, prevScore, prevCreator, score?.creator_id]);

  return { showIncrease, increaseAmount };
};
