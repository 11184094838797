import { toast } from 'sonner';
import { createImageUpload, ImageUploadOptions } from '../plugins';

export const uploadFn = (onUploadImage: ImageUploadOptions['onUploadImage']) =>
  createImageUpload({
    onUploadImage,
    validateFn: (file) => {
      if (!file.type.includes('image/')) {
        toast.error('File type not supported.');
        return false;
      }
      if (file.size / 1024 / 1024 > 20) {
        toast.error('File size too big (max 20MB).');
        return false;
      }
      return true;
    },
  });
