import { ToastContainer, ToastContentProps, ToastOptions, toast } from 'react-toastify';
import { IToastIcon } from './toast-icon';
import { ToastComponent } from './toast-component';

export interface IToastPayload {
  title: string;
  message?: string;
}

interface IToastOptions extends ToastOptions {
  type: IToastIcon;
}

export function createToast({ title, message }: IToastPayload, options: IToastOptions) {
  return toast(
    (props: ToastContentProps) => (
      <ToastComponent {...props} title={title} message={message} type={options.type} />
    ),
    {
      autoClose: 2000,
      icon: false,
      closeOnClick: false,
      hideProgressBar: true,
      ...options,
    },
  );
}

function useToast() {
  return createToast;
}

export { ToastContainer, useToast };
