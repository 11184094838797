export const badgeClaimClassName = {
  'preview-desktop': {
    container: {
      main: 'lg:min-h-0 rounded-xl w-[1024px]',
      image: 'lg:h-auto flex-grow',
      imageInternal: 'lg:static lg:h-full lg:w-full',
      badge: 'rounded-r-xl',
      badgeInternal: '',
    },
    badgeImage: {
      container: '',
      header: '',
      buttons: '',
    },
    badgeDetails: {
      background: '',
      badgeName: 'px-12 py-4',
      creators: 'lg:px-12 lg:py-4',
      info: 'lg:px-12 lg:py-8',
      infoContainer: '',
      tabPanel: '',
    },
  },
  'preview-mobile': {
    container: {
      main: 'lg:min-h-0 lg:flex-col rounded-xl w-[512px]',
      image: 'lg:h-auto flex-grow lg:w-full',
      imageInternal: 'lg:static lg:h-full lg:w-full',
      badge: 'rounded-xl lg:w-full',
      badgeInternal: 'lg:px-0 lg:basis-1 rounded-b-xl',
    },
    badgeImage: {
      container: 'lg:p-4',
      header: 'lg:flex',
      buttons: 'lg:flex',
    },
    badgeDetails: {
      background: 'lg:hidden',
      badgeName: 'lg:hidden',
      creators: 'lg:px-10',
      info: 'lg:px-10 rounded-b-xl',
      infoContainer: 'rounded-b-xl',
      tabPanel: 'lg:hidden',
    },
  },
  default: {
    container: {
      main: '',
      image: '',
      imageInternal: '',
      badge: '',
      badgeInternal: 'lg:min-h-screen',
      badgeImage: '',
    },
    badgeImage: {
      container: '',
      header: '',
      buttons: '',
    },
    badgeDetails: {
      background: '',
      badgeName: '',
      creators: '',
      info: '',
      infoContainer: '',
      tabPanel: '',
    },
  },
  loading: {
    container: {
      main: '',
      image: '',
      imageInternal: '',
      badge: '',
      badgeInternal: 'lg:min-h-screen',
      badgeImage: '',
    },
    badgeImage: {
      container: '',
      header: '',
      buttons: '',
    },
    badgeDetails: {
      background: '',
      badgeName: '',
      creators: '',
      info: '',
      infoContainer: '',
      tabPanel: '',
    },
  },
};
