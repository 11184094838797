'use client';
// import Lottie from 'lottie-react';
import dynamic from 'next/dynamic';
import animationData from '../../assets/animations/lottie.json';
const Lottie = dynamic(() => import('lottie-react'), {
  ssr: false,
});

export function CongratsScreen({ children }: React.PropsWithChildren) {
  return (
    <div className="relative flex h-fit min-h-screen flex-col items-center overflow-hidden">
      <div className="absolute bottom-0 left-0 right-0 top-0 -z-10  bg-[#fbefc9]">
        <Lottie animationData={animationData} loop={true} autoplay={true} />
      </div>
      {children}
    </div>
  );
}
