'use client';
import { twMerge } from 'tailwind-merge';
import { IMessage } from '../../message.typings';
import { MessageItemImages } from './message-item-images';

interface IProps {
  files: IMessage['files'];
  className?: string;
  isPinned?: boolean;
}
export const MessageItemFiles = ({ files, className, isPinned }: IProps) => {
  return (
    <div className={twMerge('max-w-[300px]', className)}>
      {files && <MessageItemImages images={files.images} isPinned={isPinned} />}
    </div>
  );
};
