'use client';
import { Separator } from '@ui/components/ui';
import PostItem from './post-item';
import { IMessage, IMessagesQueryParams } from '@ui/components/organisms/message';
import { ContentType, ContextUserType } from '@ui/constants/context';
import { useSearchParams } from 'next/navigation';
import { useMemo } from 'react';

interface Props {
  mainPostRef: IMessage;
  userType: ContextUserType;
  userId: string;
  communityPostRoute: string;
  communityCommentRoute: string;
  communityReplyRoute: string;
  query: IMessagesQueryParams;
  contentType: ContentType;
  fullDiscussionRoute?: string;
}

export const PostItemMainRef = (props: Props) => {
  const searchParams = useSearchParams();

  const parentThreadRoute = useMemo(() => {
    if (props?.fullDiscussionRoute) {
      const params = new URLSearchParams(searchParams);
      params.set('slug', props.fullDiscussionRoute);

      let baseRoute;

      if (props.contentType === 'comment') {
        baseRoute = props.communityPostRoute;
      } else if (props.contentType === 'reply') {
        baseRoute = props.communityCommentRoute;
      }

      return `${baseRoute}/?${params.toString()}`;
    }
    return null;
  }, [props.fullDiscussionRoute]);

  return (
    <div>
      <PostItem
        key={props.mainPostRef.id}
        post={props.mainPostRef}
        userType={props.userType}
        userId={props.userId}
        creator_id={props.mainPostRef.creator_id}
        communityPostRoute={props.communityPostRoute}
        isOwnPage
        query={props.query}
        contentType={props.contentType}
      />
      {props?.fullDiscussionRoute && parentThreadRoute && (
        <div className="flex items-center gap-4 mt-8">
          <Separator className="flex-auto bg-primaryActive" />
          <a href={parentThreadRoute}>
            <p className="text-xs whitespace-nowrap text-primaryActive">See full discussion</p>
          </a>
          <Separator className="flex-auto bg-primaryActive" />
        </div>
      )}
    </div>
  );
};
