export const MentionNotification = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <circle cx="10" cy="10" r="10" fill="#88B86D" />
    <g clipPath="url(#clip0_1474_144071)">
      <path
        d="M9.99984 12.3307C11.2885 12.3307 12.3332 11.2861 12.3332 9.9974C12.3332 8.70873 11.2885 7.66406 9.99984 7.66406C8.71117 7.66406 7.6665 8.70873 7.6665 9.9974C7.6665 11.2861 8.71117 12.3307 9.99984 12.3307Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.3332 7.66407V10.5807C12.3332 11.0449 12.5176 11.49 12.8457 11.8182C13.1739 12.1464 13.619 12.3307 14.0832 12.3307C14.5473 12.3307 14.9924 12.1464 15.3206 11.8182C15.6488 11.49 15.8332 11.0449 15.8332 10.5807V9.9974C15.8332 8.68333 15.3895 7.40775 14.574 6.37733C13.7585 5.34691 12.619 4.62203 11.3401 4.32012C10.0612 4.0182 8.71776 4.15695 7.52754 4.71389C6.33733 5.27082 5.37002 6.21331 4.78235 7.38866C4.19468 8.564 4.02107 9.90333 4.28965 11.1897C4.55823 12.476 5.25327 13.634 6.26215 14.476C7.27103 15.3179 8.53465 15.7946 9.84828 15.8288C11.1619 15.8629 12.4486 15.4525 13.4998 14.6641"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1474_144071">
        <rect width="14" height="14" fill="white" transform="translate(3 3)" />
      </clipPath>
    </defs>
  </svg>
);
