export const LeaderboardIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={23} height={19} fill="none" {...props}>
    <path
      stroke="#2D3139"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M11.223 17.618c-3.24 0-6.006-.49-6.006-2.452s2.749-3.67 6.006-3.67c3.239 0 6.006 1.692 6.006 3.653s-2.75 2.47-6.006 2.47ZM11.223 8.699a3.85 3.85 0 1 0-3.85-3.849 3.837 3.837 0 0 0 3.823 3.849h.027Z"
      clipRule="evenodd"
    />
    <path
      stroke="#2D3139"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M17.612 7.642a2.901 2.901 0 0 0-.166-5.643M18.193 10.795c1.754 0 3.252 1.189 3.252 2.25 0 .626-.517 1.307-1.3 1.49M4.834 7.642a2.901 2.901 0 0 1 .165-5.643M4.252 10.795C2.498 10.795 1 11.984 1 13.045c0 .626.517 1.307 1.302 1.49"
    />
  </svg>
);
