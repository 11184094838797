export const ImagePlaceholderIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={80} height={80} fill="none" {...props}>
    <path
      stroke="#FF600D"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={4.5}
      d="M3 40.002c0 27.748 9.252 37 37 37 27.748 0 37-9.252 37-37 0-27.748-9.252-37-37-37-27.748 0-37 9.252-37 37Z"
      clipRule="evenodd"
    />
    <path
      stroke="#FF600D"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={4.5}
      d="M34.394 27.14a7.044 7.044 0 1 1-14.087 0 7.044 7.044 0 0 1 14.087 0Z"
      clipRule="evenodd"
    />
    <path
      stroke="#FF600D"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={4.5}
      d="M76.48 50.669c-3.524-3.624-8.508-10.948-17.664-10.948-9.156 0-9.356 16.152-18.7 16.152-9.348 0-13.112-5.484-19.204-2.62-6.088 2.86-11.048 14.244-11.048 14.244"
    />
  </svg>
);
