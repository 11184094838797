'use client';
import { IMessageState, TFanMessageState } from '@ui/components';
import { createContext, useContext, ReactNode, useMemo } from 'react';

type MessageContextType = IMessageState | TFanMessageState;

const MessageContext = createContext<MessageContextType | undefined>(undefined);

interface ProviderCommonProps {
  children: ReactNode;
}

interface CreatorProviderProps extends ProviderCommonProps {
  userType: 'creator';
  state: IMessageState;
}

interface FanProviderProps extends ProviderCommonProps {
  userType: 'fan';
  state: TFanMessageState;
}

type ProviderProps = CreatorProviderProps | FanProviderProps;

export const MessageProvider = ({ children, state }: ProviderProps) => {
  const memoizedState = useMemo(() => state, [state]);
  return <MessageContext.Provider value={memoizedState}>{children}</MessageContext.Provider>;
};

export type ContextUserType = 'creator' | 'fan';

export const useMessageContext = <T extends ContextUserType>(
  userType: T,
): T extends 'creator' ? IMessageState : TFanMessageState => {
  const context = useContext(MessageContext);
  if (context === undefined) {
    throw new Error('useMessageContext must be used within a MessageProvider');
  }

  return context as T extends 'creator' ? IMessageState : TFanMessageState;
};
