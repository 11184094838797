import { ContextUserType } from '@ui/components';
import React, { useEffect, useRef, memo } from 'react';
import { createPortal } from 'react-dom';
import { useMessageUpdateFormContext } from '@ui/contexts/message-update-form-context';
import { MessageUpdateForm } from './message-update-form';

interface Props {
  userType: ContextUserType;
  main_post_ref: string;
}

const Portal = memo(
  ({ children, targetRef }: { children: React.ReactNode; targetRef: HTMLDivElement }) => {
    return createPortal(children, targetRef);
  },
);
Portal.displayName = 'MessageUpdatePortal';

export const MessageUpdateFormPortal = memo((props: Props) => {
  const { targetMessage, setTargetMessage } = useMessageUpdateFormContext();
  const initialDisplayRef = useRef<string | null>(null);
  const firstChildRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    if (targetMessage?.targetRef) {
      const firstChild = targetMessage.targetRef.firstElementChild as HTMLElement;
      if (firstChild && firstChildRef.current !== firstChild) {
        firstChildRef.current = firstChild;
        initialDisplayRef.current = firstChild.style.display;
        firstChild.style.display = 'none';
      }

      return () => {
        if (firstChildRef.current) {
          firstChildRef.current.style.display = initialDisplayRef.current || '';
          firstChildRef.current = null;
        }
      };
    }
  }, [targetMessage]);

  const onDismiss = () => {
    setTargetMessage(null);
    if (firstChildRef.current) {
      firstChildRef.current.style.display = initialDisplayRef.current || '';
    }
    firstChildRef.current = null;
  };

  if (!targetMessage?.targetRef) return null;

  return (
    <Portal targetRef={targetMessage.targetRef}>
      <MessageUpdateForm
        message={targetMessage}
        type={targetMessage.type}
        userType={props.userType}
        onDismiss={onDismiss}
        query={targetMessage.query}
        main_post_ref={props.main_post_ref}
      />
    </Portal>
  );
});

MessageUpdateFormPortal.displayName = 'MessageUpdateFormPortal';
