import { useEffect, useRef, useState } from 'react';

interface IntersectionObserverOptions {
  threshold?: number;
  rootMargin?: string;
}

const useIntersectionObserver = (
  callback?: () => void,
  options: IntersectionObserverOptions = {},
) => {
  const { threshold = 1.0, rootMargin = '0px' } = options;
  const targetRef = useRef<HTMLDivElement | null>(null);
  const [hasIntersected, setHasIntersected] = useState(false);

  useEffect(() => {
    if (!targetRef.current || hasIntersected) return;

    const handleIntersect = (entries: IntersectionObserverEntry[]) => {
      const [entry] = entries;
      if (entry.isIntersecting && !hasIntersected && callback) {
        setHasIntersected(true);
        callback();
      }
    };

    const observer = new IntersectionObserver(handleIntersect, {
      root: null,
      rootMargin,
      threshold,
    });

    observer.observe(targetRef.current);

    return () => {
      if (targetRef.current) {
        observer.unobserve(targetRef.current);
      }
    };
  }, [targetRef, callback, threshold, rootMargin, hasIntersected]);

  return targetRef;
};

export default useIntersectionObserver;
