'use client';
import { Title, Button, Text, InputForm, InputPassword, OauthButton } from '@ui/components';
import { useLoginState, LoginForm } from './login.state';

interface ILogin {
  title: string;
  description?: string | React.ReactNode;
  buttonText?: string;
  isDevelopment: boolean;
  onForgot(): void;
  onTestLogin?(): void;
  onLogin(form: LoginForm, errorCb: (e: string) => void): void;
  toDefault?: () => void;
  toMobileDefault?: () => void;
  oAuth?: boolean;
}

export function LoginComponent({
  title,
  description,
  isDevelopment,
  buttonText,
  onLogin,
  onTestLogin,
  onForgot,
  oAuth = false,
  toDefault,
  toMobileDefault,
}: ILogin) {
  const { email, password, isLoading, onSubmit } = useLoginState(onLogin);

  return (
    <div className="flex h-full w-full flex-col justify-center">
      <Title type="l" className="mb-4 text-center lg:text-left">
        {title}
      </Title>

      {description && (
        <Text type="m" className="mb-6 text-center lg:mb-10 lg:text-left">
          {description}
        </Text>
      )}

      <div className="mb-10 w-full gap-2 space-y-2">
        <InputForm label="Email" form={email} disabled={isLoading} />
        <InputPassword
          label="Password"
          form={password}
          disabled={isLoading}
          labelComponent={
            <div onClick={onForgot} className="h-auto w-fit cursor-pointer px-3">
              <Text type="s" className="text-[#1064D6] underline">
                Forgot your password?
              </Text>
            </div>
          }
        />
      </div>

      <Button onClick={onSubmit} type="primary" isLoading={isLoading}>
        {buttonText || 'Login'}
      </Button>
      {/* {oAuth && <OauthButton type="google" url="/auth/google" />} */}
      {/* {isDevelopment ? ( */}
      {oAuth && (
        <Button
          type="secondary"
          className="mt-4"
          onClick={() => {
            window.innerWidth < 1020 ? toMobileDefault?.() : toDefault?.();
          }}>
          Back
        </Button>
      )}
      {/* ) : null} */}
    </div>
  );
}
