export const GraphIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="47"
    height="35"
    viewBox="0 0 47 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M45.2083 29.1667H5.83333V1.45833C5.83333 0.652604 5.18073 0 4.375 0H1.45833C0.652604 0 0 0.652604 0 1.45833V32.0833C0 33.6939 1.30612 35 2.91667 35H45.2083C46.0141 35 46.6667 34.3474 46.6667 33.5417V30.625C46.6667 29.8193 46.0141 29.1667 45.2083 29.1667ZM42.2917 2.91667H31.531C29.5823 2.91667 28.6061 5.27279 29.9842 6.65091L32.9374 9.60404L26.25 16.2923L19.5626 9.60495C18.4233 8.46563 16.5767 8.46563 15.4383 9.60495L9.17747 15.8658C8.60781 16.4354 8.60781 17.3587 9.17747 17.9284L11.2392 19.9901C11.8089 20.5598 12.7322 20.5598 13.3018 19.9901L17.5 15.791L24.1874 22.4784C25.3267 23.6177 27.1733 23.6177 28.3117 22.4784L37.0617 13.7284L40.0148 16.6815C41.393 18.0596 43.7491 17.0835 43.7491 15.1348V4.375C43.75 3.56927 43.0974 2.91667 42.2917 2.91667Z"
      fill="black"
    />
  </svg>
);
