'use client';
import { useState, useEffect } from 'react';
import { ALLOWED_FILE_TYPES, MAX_FILE_SIZE } from './constants';
import { IFile } from './types';
import { generateUniqueId } from '@ui/constants';

const initialFileState: IFile = {
  src: '',
  file: null,
  id: '',
  meta: null,
};

export function useFile(callback?: (payload: File | null) => void) {
  const [file, setImage] = useState<IFile>(initialFileState);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (file.file) {
      setError(null);
    }
  }, [file]);

  function onFileUpload(file: File) {
    if (!ALLOWED_FILE_TYPES.includes(file?.type as any)) {
      setError(`Unsupported file type. Acceptable formats: PNG, JPG, JPEG, GIF.`);
      return;
    }

    if (file?.size > MAX_FILE_SIZE) {
      setError('File size exceeds 10MB. Please upload a smaller file.');
      return;
    }
    callback && callback(file);
    const uniqueId = generateUniqueId();
    const src = URL.createObjectURL(file);

    setImage({
      src,
      file,
      id: uniqueId,
      meta: null,
    });
  }

  function onDiscardFile() {
    callback && callback(null);
    setImage(initialFileState);
  }

  function setFile(file: File | null) {
    if (!file) {
      return;
    }
    callback && callback(file);
    const src = URL.createObjectURL(file);
    const uniqueId = generateUniqueId();

    setImage({
      src,
      file,
      id: uniqueId,
      meta: null,
    });
  }

  return {
    file,
    onFileUpload,
    onDiscardFile,
    setFile,
    error,
  };
}
