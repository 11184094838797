import { twMerge } from 'tailwind-merge';
import { Title, Avatar } from '@ui/components/atoms';
import { IBadgeClaimCreators, IBadgeClaimType } from '../../badge-claim.typings';
import { badgeClaimClassName } from '../../badge-claim.style';

interface IBadgeClaimCreatorsProps extends IBadgeClaimCreators {
  claimType: IBadgeClaimType;
  children: React.ReactNode;
  isLoading?: boolean;
  isCreatorSuccess?: boolean;
  isLoadingBadgeCollaborator?: boolean;
}

export function BadgeClaimCreators({
  name,
  avatar,
  isCollaboration,
  collaboratorAvatar,
  collaboratorName,
  claimType,
  children,
  isLoading,
  isLoadingBadgeCollaborator,
  isCreatorSuccess,
}: IBadgeClaimCreatorsProps) {
  const className = badgeClaimClassName[claimType];

  return (
    <div
      className={twMerge(
        'flex flex-col rounded-t-lg bg-black lg:flex-1',
        className.badgeDetails.infoContainer,
      )}>
      <div
        className={twMerge(
          'flex items-center gap-3 px-9 py-7 lg:px-20',
          className.badgeDetails.creators,
        )}>
        <div className="flex">
          <Avatar size="sm" src={avatar} isLoading={!isCreatorSuccess} />
          {isCollaboration ? (
            <Avatar
              size="sm"
              src={collaboratorAvatar}
              wrapperClassName="-translate-x-3 -mr-3"
              isLoading={isLoadingBadgeCollaborator}
            />
          ) : null}
        </div>

        <Title type="sm" className="text-white">
          {name} {isCollaboration ? `& ${collaboratorName}` : ''}
        </Title>
      </div>
      {children}
    </div>
  );
}
