'use client';
import { useController, RegisterOptions, Control, FieldValues, Path } from 'react-hook-form';
import { IInput, Input } from './input';

export interface IInputForm<T extends FieldValues> extends IInput {
  form: {
    control: Control<T>;
    name: Path<T>;
    rules?: RegisterOptions<T, Path<T>>;
    error?: string;
  };
}

export function InputForm<T extends FieldValues>({
  form: { control, name, rules },
  ...input
}: IInputForm<T>) {
  const {
    field: { value, onChange, onBlur },
    fieldState: { error },
  } = useController<T>({
    control,
    name,
    rules,
  });

  return (
    <Input {...input} value={value} onChange={onChange} onBlur={onBlur} error={error?.message} />
  );
}
