import { Avatar, Button } from '@ui/components/atoms';
import { TConversationParticipantSource } from '../conversation.typings';
import { useMemo } from 'react';
import { SquarePen } from 'lucide-react';

interface IProp {
  selection: TConversationParticipantSource;
}
export const ConversationParticipantSelectionCount = ({ selection }: IProp) => {
  const avatars = useMemo(() => {
    return new Array(selection.count > 3 ? 3 : selection.count)
      .fill(0)
      .map((_, i) => <Avatar key={i} size="sm" />);
  }, [selection]);

  return (
    <div className="bg-input flex cursor-pointer flex-row items-center rounded-sm p-4 text-sm shadow-sm">
      <div className="h-full w-[1px] border border-white bg-white" />
      <div className="flex flex-row items-center gap-4">
        {selection.count > 0 && <div className="flex flex-row -space-x-8">{avatars}</div>}

        <div className="flex flex-row items-center gap-2">
          <div>
            <p className="text-base">{selection.label}</p>
            {/* <p className="text-sm font-light">
              {selection.count === 0 ? 'No' : <strong>{selection.count}</strong>} participants in
              the {selection.type} selected
            </p> */}
          </div>
          <Button type="icon-primary" className="mn" size="smallIcon">
            <SquarePen />
          </Button>
        </div>
      </div>
    </div>
  );
};
