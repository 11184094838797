import { twMerge } from 'tailwind-merge';

interface IRadioButtonProps {
  value: number | string;
  isActive: boolean;
  className?: string;
  onClick(value: number | string): void;
}
export function RadioButton({ value, isActive, className, onClick }: IRadioButtonProps) {
  function onActivate() {
    onClick(value);
  }
  return (
    <button
      className={twMerge(
        'bg-input hover:bg-inputActive flex h-6 w-6 items-center justify-center rounded-full border border-transparent transition-colors',
        className,
      )}
      onClick={onActivate}>
      {isActive ? (
        <div className="bg-primary hover:bg-primaryHover flex h-4 w-4 items-center justify-center rounded-full">
          <div className="h-2 w-2 rounded-full bg-white" />
        </div>
      ) : null}
    </button>
  );
}
