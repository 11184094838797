export const ShowIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={27} height={24} fill="none" {...props}>
    <g
      stroke="#707B8F"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      clipRule="evenodd">
      <path d="M16.724 12.052c0 1.746-1.562 3.162-3.487 3.162-1.926 0-3.487-1.416-3.487-3.162 0-1.747 1.561-3.162 3.487-3.162 1.925 0 3.487 1.415 3.487 3.162Z" />
      <path d="M3.033 12.052c0 3.28 4.568 7.302 10.204 7.302 5.635 0 10.204-4.019 10.204-7.302 0-3.283-4.57-7.302-10.204-7.302-5.636 0-10.204 4.022-10.204 7.302Z" />
    </g>
  </svg>
);
