export const BeaconsIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 40 40"
    fill={props.color || '#707B8F'}
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <g clipPath="url(#clip0_8955_131861)">
      <path
        d="M16.7606 0.300282C8.26063 1.74028 1.68063 8.36028 0.300625 16.9003C-0.079375 19.1803 -0.079375 20.8203 0.300625 23.1003C1.52063 30.6003 6.88063 36.8003 14.1006 39.0603C23.2206 41.9003 33.2006 37.7403 37.6406 29.2603C42.8606 19.2803 38.9206 7.20028 28.8006 2.10028C25.2206 0.300282 20.7606 -0.379719 16.7606 0.300282ZM18.8406 8.98028C20.6206 9.74028 21.7806 11.1203 22.2006 13.0203C23.0406 16.8203 19.6406 20.3403 15.7606 19.7003C14.5606 19.5003 13.8606 19.1003 12.7606 18.0403C9.92063 15.3003 10.9206 10.4803 14.6206 8.98028C15.8006 8.50028 17.7206 8.50028 18.8406 8.98028ZM28.2206 10.9003C30.0606 11.8603 30.0606 14.8603 28.2206 15.7803C27.2806 16.2803 26.4006 16.3203 25.6006 15.9003C24.4806 15.3203 23.7806 13.8803 24.0806 12.7203C24.2406 12.1003 25.1406 11.0203 25.6806 10.8003C26.3606 10.5203 27.5806 10.5803 28.2206 10.9003ZM29.1206 18.5203C33.0606 20.9403 32.8606 26.6403 28.7606 28.8403C28.0406 29.2203 27.5806 29.3003 26.1006 29.3003C24.1406 29.3003 23.4606 29.0403 22.0406 27.7203C20.0606 25.9003 19.7406 22.6603 21.2806 20.3403C22.5406 18.4803 24.0406 17.7603 26.5206 17.8603C27.9006 17.9203 28.2806 18.0203 29.1206 18.5203ZM12.3206 19.3003C14.0006 20.4003 13.9806 23.0203 12.3006 24.1203C11.5806 24.5803 9.90063 24.5603 9.22063 24.0603C8.42063 23.4603 7.96063 22.4403 8.06063 21.4203C8.28063 19.3203 10.5806 18.1603 12.3206 19.3003ZM17.3406 24.6203C18.0206 24.8803 18.9406 25.7203 19.3206 26.4403C20.1006 27.9203 19.3606 30.1203 17.8206 30.9803C14.8806 32.6003 11.5606 29.6203 12.8806 26.5403C13.5806 24.8803 15.6606 23.9803 17.3406 24.6203Z"
        fill="#707B8F"
      />
    </g>
  </svg>
);
