'use client';
import { ReactNode, useMemo, useState } from 'react';
import { Loader } from 'lucide-react';
import { useConversationContext } from '../conversation.context';
import {
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  ICustomLeaderboard,
  IOption,
  SearchableMultiSelect,
  TConversationParticipantSource,
} from '@ui/components';

interface IProps {
  onAddMembers: (members: TConversationParticipantSource) => void;
  children: ReactNode;
}
export const CustomLeaderboardSelectionModal = ({ onAddMembers, children }: IProps) => {
  const state = useConversationContext();
  const [selections, setSelections] = useState<IOption<ICustomLeaderboard>[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  const isDisabled = useMemo(() => {
    return state.isCustomLeaderboardLoading || isFetching || selections.length === 0;
  }, [state.isCustomLeaderboardLoading, isFetching]);

  // async function fetchBadges() {
  //   setIsFetching(true);

  //   const leaderboardIds = Array.from(
  //     new Set(
  //       selections.flatMap((s) => {
  //         return s.value;
  //       }),
  //     ),
  //   );

  //   const leaderboardRequests = leaderboardIds.map((leaderboardId) =>
  //     state
  //       .getCustomLeaderboardFans({ leaderboardId: leaderboardId, top_n: 1000 })
  //       .then((response) =>
  //         state.fansSelector.selectAll(
  //           state.customLeaderboardData ? response.data.fans : state.fansAdapter.getInitialState(),
  //         ),
  //       ),
  //   );

  //   const members: IUser[] = (await Promise.all(leaderboardRequests)).flat().map((f) => ({
  //     avatar: f.avatarUrl,
  //     name: f.name,
  //     id: f.id,
  //     displayName: f.name,
  //     banner: f.avatarUrl,
  //     email: '',
  //   }));

  //   // Prevent duplicates
  //   const uniqueMembers = Array.from(
  //     members
  //       .reduce((map, member) => {
  //         if (member && member.id !== state.userId) {
  //           map.set(member.id, member);
  //         }
  //         return map;
  //       }, new Map<string, IUser>())
  //       .values(),
  //   );

  //   onAddMembers(uniqueMembers);
  //   setIsFetching(false);
  //   setIsOpen(false);
  // }

  const options = useMemo(
    () => state.customLeaderboardData as IOption[],
    [state.customLeaderboardData],
  );

  const onAdd = async () => {
    onAddMembers({
      type: 'leaderboard',
      custom_leaderboard_id: selections[0].value,
      count: selections[0].meta?.fans_quantity || 0,
      label: selections[0].label,
    });
    setIsOpen(false);
  };

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        <span>{children}</span>
      </DialogTrigger>
      <DialogContent
        className="bg-white p-6 sm:max-w-lg"
        style={{ cursor: state.isCustomLeaderboardLoading ? 'progress' : 'default' }}>
        <DialogHeader>
          <DialogTitle className="text-2xl">Select Leaderboard</DialogTitle>
        </DialogHeader>

        <SearchableMultiSelect
          isFormControlled={false}
          placeholder="Search leaderboard..."
          className="w-full max-w-[calc(32rem-50px)]"
          options={options}
          setValue={setSelections}
          maxSelection={1}
        />

        <DialogFooter className="justify-end">
          <Button
            type="primary"
            size="small"
            disabled={isDisabled && selections.length === 0}
            onClick={onAdd}>
            {isFetching && <Loader className="mr-1 animate-spin" />}{' '}
            {isFetching ? 'Please wait...' : 'Add leaderboards'}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
