import React from 'react';
import { motion } from 'framer-motion';

interface AnimatedShadowWrapperProps {
  children: React.ReactNode;
  showAnimation: boolean;
  hasBorder?: boolean;
  className?: string;
}

export const AnimatedShadowWrapper: React.FC<AnimatedShadowWrapperProps> = ({
  children,
  showAnimation,
  hasBorder = false,
  className = '',
}) => {
  return (
    <motion.div
      className={className}
      style={{
        boxShadow: showAnimation && hasBorder ? '0 0 10px rgba(255, 0, 255, 0.5)' : 'none',
      }}
      animate={{
        boxShadow:
          showAnimation && hasBorder
            ? [
                '0 0 12px rgba(255, 0, 255, 0.5)',
                '0 0 17px rgba(255, 0, 255, 0.8)',
                '0 0 12px rgba(255, 0, 255, 0.5)',
              ]
            : 'none',
      }}
      transition={{ duration: 5, repeat: showAnimation ? Infinity : 0 }}>
      {children}
    </motion.div>
  );
};
