export const LeaderboardsIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M6.91797 14.8533L9.91097 10.9643L13.325 13.6443L16.254 9.86426"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.6681 2.34961C20.7301 2.34961 21.5901 3.20961 21.5901 4.27161C21.5901 5.33261 20.7301 6.19361 19.6681 6.19361C18.6061 6.19361 17.7461 5.33261 17.7461 4.27161C17.7461 3.20961 18.6061 2.34961 19.6681 2.34961Z"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.7552 9.26873C20.8882 10.1637 20.9492 11.1717 20.9492 12.3027C20.9492 19.2407 18.6372 21.5527 11.6992 21.5527C4.76222 21.5527 2.44922 19.2407 2.44922 12.3027C2.44922 5.36573 4.76222 3.05273 11.6992 3.05273C12.8092 3.05273 13.8002 3.11173 14.6822 3.23973"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
