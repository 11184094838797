import { Editor } from '@tiptap/react';
import { IFile } from '@ui/hooks/file/types';
import { Bold, Italic, Strikethrough, List, ListOrdered, Image } from 'lucide-react';
import { Toggle, Separator } from '@ui/components/ui';
import { InputFileWithTrigger } from '@ui/components/moleculas';
import { twMerge } from 'tailwind-merge';

interface RichTextEditorToolbarProps {
  editor: Editor;
  exceededFileLimit: boolean;
  files: IFile[];
  maxFiles: number;
  hideFileUpload?: boolean;
  minimized?: boolean;
  showLabel?: boolean;
  onSelectFiles?: (fileList: FileList) => void;
}
export const RichTextEditorToolbarList = (props: RichTextEditorToolbarProps) => {
  const toolbars = [
    <Toggle
      key="toolbar-bold"
      className={twMerge(
        'flex flex-row items-center justify-start',
        props.minimized ? 'hidden' : '',
      )}
      size="sm"
      pressed={props.editor.isActive('bold')}
      onPressedChange={() => props.editor.chain().focus().toggleBold().run()}>
      <Bold className="h-4 w-4" />
      {props.showLabel && <span className="ml-4">Bold</span>}
    </Toggle>,
    <Toggle
      key="toolbar-italic"
      className={twMerge(
        'flex flex-row items-center justify-start',
        props.minimized ? 'hidden' : '',
      )}
      size="sm"
      pressed={props.editor.isActive('italic')}
      onPressedChange={() => props.editor.chain().focus().toggleItalic().run()}>
      <Italic className="h-4 w-4" />
      {props.showLabel && <span className="ml-4">Italic</span>}
    </Toggle>,
    <Toggle
      key="toolbar-strikethrough"
      className={twMerge(
        'flex flex-row items-center justify-start',
        props.minimized ? 'hidden' : '',
      )}
      size="sm"
      pressed={props.editor.isActive('strike')}
      onPressedChange={() => props.editor.chain().focus().toggleStrike().run()}>
      <Strikethrough className="h-4 w-4" />
      {props.showLabel && <span className="ml-4">Strikethrough</span>}
    </Toggle>,
    ...(!props.minimized
      ? [
          <Separator
            key="toolbar-Separator"
            orientation="vertical"
            className="h-8 w-[1px] hidden md:block"
          />,
        ]
      : []),
    <Toggle
      key="toolbar-bulletList"
      className={twMerge(
        'flex flex-row items-center justify-start',
        props.minimized ? 'hidden' : '',
      )}
      size="sm"
      pressed={props.editor.isActive('bulletList')}
      onPressedChange={() => props.editor.chain().focus().toggleBulletList().run()}>
      <List className="h-4 w-4" />
      {props.showLabel && <span className="ml-4">BulletList</span>}
    </Toggle>,
    <Toggle
      key="toolbar-orderedList"
      className={twMerge(
        'flex flex-row items-center justify-start',
        props.minimized ? 'hidden' : '',
      )}
      size="sm"
      pressed={props.editor.isActive('orderedList')}
      onPressedChange={() => props.editor.chain().focus().toggleOrderedList().run()}>
      <ListOrdered className="h-4 w-4" />
      {props.showLabel && <span className="ml-4">Ordered List</span>}
    </Toggle>,
  ];

  if (!props.hideFileUpload) {
    toolbars.push(
      <InputFileWithTrigger
        key="toolbar-Image"
        className="flex h-[32px] items-center"
        maxFiles={props.maxFiles}
        exceededFileLimit={props.files.length >= props.maxFiles}
        onSelectFiles={props.onSelectFiles}>
        <Toggle size="sm" pressed={false}>
          <Image className="h-4 w-4" />
          {props.showLabel && <span className="ml-4">Image</span>}
        </Toggle>
      </InputFileWithTrigger>,
    );
  }

  return toolbars;
};
