import dayjs from 'dayjs';

export const LEADERBOARD_START_DATE = '2023-01-01';

export const statusConditions = (initialDate: Date, finalDate: Date) => {
  const now = dayjs();
  return [
    { condition: dayjs(finalDate).isBefore(now), color: 'bg-red-600' }, // Expired
    { condition: dayjs(initialDate).isAfter(now), color: 'bg-yellow-600' }, // Scheduled
    {
      condition: dayjs(initialDate).isBefore(now) && dayjs(finalDate).isAfter(now),
      color: 'bg-green-600',
    }, // Active
  ];
};

export const fallbackLeaderboardSelection = 'global-leaderboard';
