'use client';
import './rte-editor.css';
import { useEditor, EditorContent, type Editor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { Bold, Strikethrough, Italic, List, ListOrdered, Image } from 'lucide-react';
import { Toggle, Separator, Popover, PopoverContent, PopoverTrigger } from '@ui/components/ui';
import { useEffect, useMemo, useState } from 'react';
import { Avatar, Button } from '@ui/components/atoms';
import Placeholder from '@tiptap/extension-placeholder';
import { ContextUserType } from '@ui/constants/context';
import { FilePreview } from '@ui/components/moleculas';
import { IFile } from '@ui/hooks/file/types';
import { sendGAEvent } from '@next/third-parties/google';
import { twMerge } from 'tailwind-merge';
import {
  Link,
  HorizontalRule,
  CodeBlockLowlight,
  Selection,
  Color,
  UnsetAllMarks,
} from './extensions';
import { TextStyle } from '@tiptap/extension-text-style';
import { Typography } from '@tiptap/extension-typography';
import Mention from '@tiptap/extension-mention';
import Suggestion from './extensions/mentions/suggestion';
import { useMobile, useScoreAnimation } from '@ui/hooks';
import AnimatedScoreIncrease from '@ui/components/organisms/engagement-score/components/animated-score-increase';
import { cn } from '@ui/lib/utils';
import CharacterCount from '@tiptap/extension-character-count';
import { CharacterCounterProgress } from './extensions/character-counter/character-counter-progress';
import { Ellipsis } from 'lucide-react';
import { RichTextEditorToolbarList } from './_component/rte-editor-toolbar-list';

interface ICommonProps {
  files: IFile[];
  exceededFileLimit: boolean;
  hideFileUpload?: boolean;
  onSelectFiles?: (fileList: FileList) => void;
}

interface RichTextEditorProps extends ICommonProps {
  characterLimit?: number;
  buttonLabel?: string;
  placeholder: string;
  editorLabel: string;
  authorAvatarURL?: string;
  value: string;
  userType: ContextUserType;
  currentUser?: ContextUserType;
  isSubmitButtonDisabled: boolean;
  editorOnly?: boolean;
  isLoading?: boolean;
  maxFiles: number;
  activateToolbarOnFocus?: boolean;
  showCancelButton: boolean;
  isFocused?: boolean;
  enableSuggestions?: boolean;
  initialMention?: {
    id: string;
    label: string;
  };
  editorClassName?: string;
  deleteFile?: (id: string) => void;
  onChange: (value: string, mentions: string[], clearContent: () => void) => void;
  onEditorChange?: (value: string, editor: Editor) => void;
  onCancel: () => void;
}

export const RichTextEditor = ({
  placeholder,
  editorLabel,
  buttonLabel,
  onCancel,
  enableSuggestions,
  ...rest
}: RichTextEditorProps) => {
  const [content, setContent] = useState('');
  const [isFocused, setIsFocused] = useState(false);

  const { scoreAnimation, triggerScoreAnimation } = useScoreAnimation();

  const editor = useEditor({
    immediatelyRender: true,
    editorProps: {
      attributes: {
        autocomplete: 'off',
        autocorrect: 'off',
        autocapitalize: 'off',
        class: twMerge(
          'max-h-[600px] w-full bg-transparent text-sm ring-offset-background placeholder:text-muted-foreground focus-visible:outline-none disabled:cursor-not-allowed overflow-auto',
          rest.editorClassName,
        ),
      },
    },
    extensions: [
      StarterKit.configure({
        horizontalRule: false,
        codeBlock: false,
        paragraph: { HTMLAttributes: { class: 'text-node' } },
        heading: { HTMLAttributes: { class: 'heading-node' } },
        blockquote: { HTMLAttributes: { class: 'block-node' } },
        orderedList: { HTMLAttributes: { class: 'list-node list-decimal pl-4' } },
        bulletList: { HTMLAttributes: { class: 'list-node list-disc pl-4' } },
        code: { HTMLAttributes: { class: 'inline', spellcheck: 'false' } },
        dropcursor: { width: 2, class: 'ProseMirror-dropcursor border' },
      }),
      Link,
      Color,
      TextStyle,
      Selection,
      Typography,
      UnsetAllMarks,
      HorizontalRule,
      CodeBlockLowlight,
      Placeholder.configure({ placeholder: () => placeholder }),
      Mention.configure({
        HTMLAttributes: {
          class: 'text-primary font-semibold',
        },
        suggestion: enableSuggestions ? Suggestion : undefined,
      }).extend({
        addOptions() {
          return {
            ...this.parent?.(),
            userType: rest.userType,
          };
        },
      }),
      CharacterCount.configure({
        limit: rest.characterLimit,
      }),
    ],
    content: rest.value,
    onUpdate: ({ editor }) => {
      const html = editor.getHTML();
      setContent(html);
      if (rest.onEditorChange) {
        rest.onEditorChange(html, editor);
      }
    },
  });

  function getAllNodesAttributesByType(doc: any, nodeType: string) {
    const result: string[] = [];

    doc.descendants((node: any) => {
      if (node.type.name === nodeType) {
        result.push(node.attrs.id);
      }
    });

    return result;
  }

  const submit = () => {
    const mentions = getAllNodesAttributesByType(editor.state.doc, 'mention');
    rest.onChange(content, mentions, () => {
      editor.commands.clearContent();
      setContent('');
    });
  };

  const onDismissEditorContent = () => {
    editor.commands.clearContent();
    setContent('');
    onCancel();
    setIsFocused(false);
  };

  const handleSubmit = () => {
    if (buttonLabel === 'Post') {
      sendGAEvent('event', 'PostButtonClicked', {
        event_label: 'Post Button clicked',
      });
    } else if (buttonLabel === 'Comment') {
      sendGAEvent('event', 'CommentButtonClicked', {
        event_label: 'Comment Button clicked',
      });
    }

    triggerScoreAnimation(2, 'comment');

    submit();
  };

  const FilesRender = useMemo(() => {
    return rest.files.map((file) => {
      return (
        <FilePreview
          className="h-14 w-14"
          key={file.id}
          file={file}
          onDelete={() => {
            if (rest.deleteFile) {
              rest.deleteFile(file.id);
            }
          }}
        />
      );
    });
  }, [rest.files]);

  const isEditorOnlyCss = rest.editorOnly ? 'mt-0 ml-0 pl-0' : '';

  useEffect(() => {
    setContent(rest.value);
    const editorText = editor.getText();
    if (Boolean(rest.value) && !Boolean(editorText)) {
      editor.chain().focus().insertContent(rest.value).run();
    }

    return () => {
      const editorText = editor.getText();
      if (editor && !Boolean(editorText)) {
        editor.destroy();
      }
    };
  }, [rest.value, editor]);

  useEffect(() => {
    if (rest?.initialMention && editor) {
      const existingMentions = getAllNodesAttributesByType(editor.state.doc, 'mention');
      if (!existingMentions.includes(rest.initialMention.id)) {
        editor
          .chain()
          .focus()
          .insertContent({
            type: 'mention',
            attrs: rest.initialMention,
            text: rest.initialMention.label,
          })
          .insertContent(' ')
          .run();
      }
    }
  }, [editor]);

  const isButtonDisabled = useMemo(() => {
    return !Boolean(content) || content?.length < 8 || rest.isSubmitButtonDisabled;
  }, [rest.isSubmitButtonDisabled, rest.isLoading, rest.exceededFileLimit, content]);

  return (
    <div className="bg-input h-fit rounded-md">
      <div className="flex flex-col gap-1 py-3 pl-4">
        {!rest.editorOnly && (
          <div className="mt-2 flex items-center gap-2">
            {rest.authorAvatarURL && (
              <Avatar size="s" src={rest.authorAvatarURL} type={rest.userType} />
            )}
            {editorLabel && <p className="text-md font-nunito font-semibold">{editorLabel}</p>}
          </div>
        )}
        <EditorContent
          editor={editor}
          className={twMerge(
            'editor-content z-10 -mt-1 ml-3 border-none bg-transparent pl-8',
            isEditorOnlyCss,
            !editorLabel ? 'ml-0 pl-0' : '',
          )}
          placeholder={placeholder}
          onFocus={() => setIsFocused(true)}
        />
      </div>

      {rest.files.length > 0 && (
        <div className="flex w-full flex-row gap-2 overflow-x-auto p-4">{FilesRender}</div>
      )}

      <div className="bg-korLightOrange/50 rounded-bl-md rounded-br-md">
        {editor && (
          <div
            className=" flex flex-row items-center justify-between px-2 py-1"
            style={{
              display: rest.activateToolbarOnFocus ? (isFocused ? 'flex' : 'none') : 'flex',
            }}>
            <RichTextEditorToolbar editor={editor} {...rest} />
            <div className="flex gap-2">
              {rest.showCancelButton && (
                <Button
                  onClick={onDismissEditorContent}
                  type="secondary"
                  size="small"
                  className="h-8 w-fit min-w-fit">
                  Cancel
                </Button>
              )}
              <div className="relative">
                <AnimatedScoreIncrease
                  className={cn(
                    'absolute right-0 text-green-500',
                    scoreAnimation.position === 'comment' && 'top-[-25px]',
                  )}
                  scoreAnimation={scoreAnimation}
                  visiblePositions={['comment']}
                  userType={rest.currentUser!}
                />
                {buttonLabel && (
                  <Button
                    onClick={handleSubmit}
                    isLoading={rest.isLoading}
                    type="primary"
                    size="small"
                    className="h-8 w-fit min-w-fit"
                    disabled={isButtonDisabled}>
                    {buttonLabel}
                  </Button>
                )}
              </div>
            </div>
          </div>
        )}

        {rest.characterLimit && <Separator className="bg-gray-300" />}

        {rest.characterLimit && (
          <CharacterCounterProgress editor={editor} characterLimit={rest.characterLimit} />
        )}
      </div>
    </div>
  );
};

interface RichTextEditorToolbarProps {
  editor: Editor;
  exceededFileLimit: boolean;
  files: IFile[];
  maxFiles: number;
  hideFileUpload?: boolean;
  onSelectFiles?: (fileList: FileList) => void;
}
const RichTextEditorToolbar = (props: RichTextEditorToolbarProps) => {
  const isMobile = useMobile('(max-width: 500px)');
  return (
    <div className="flex flex-row items-center gap-1 p-1">
      {RichTextEditorToolbarList({ ...props, minimized: isMobile }).map((item, index) => (
        <div key={index}>{item}</div>
      ))}

      <Popover>
        <PopoverTrigger>
          <Toggle className="md:hidden" size="sm">
            <Ellipsis className="h-4 w-4" />
          </Toggle>
        </PopoverTrigger>
        <PopoverContent className="flex flex-col gap-4 w-[182px] ml-4">
          {RichTextEditorToolbarList({ ...props, minimized: false, showLabel: true }).map(
            (item, index) => (
              <div key={index}>{item}</div>
            ),
          )}
        </PopoverContent>
      </Popover>
    </div>
  );
};
