'use client';
import { twMerge } from 'tailwind-merge';
import { Title, Note } from '@ui/components/atoms';
import { IBadgeClaimType } from '../../badge-claim.typings';
import { badgeClaimClassName } from '../../badge-claim.style';

interface IBadgeClaimNameDesktop {
  name: string;
  caption: string;
  claimType: IBadgeClaimType;
}

export function BadgeClaimNamePanel({ name, caption, claimType }: IBadgeClaimNameDesktop) {
  return (
    <div
      className={twMerge(
        'hidden rounded-t-3xl px-20 py-8 lg:block',
        badgeClaimClassName[claimType].badgeDetails.badgeName,
      )}>
      <Title
        type={claimType === 'default' ? 'mm' : 'm'}
        className="mb-3 line-clamp-2 h-16 truncate whitespace-pre-wrap">
        {name}
      </Title>
      <Note type="l">{caption}</Note>
    </div>
  );
}
