import { twMerge } from 'tailwind-merge';

interface IFormProps {
  className?: string;
  internalClassName?: string;
  style?: React.CSSProperties;
}

export function FormPage({ children, className, style }: React.PropsWithChildren<IFormProps>) {
  return (
    <div className={twMerge('flex flex-col lg:min-h-screen lg:flex-row', className)} style={style}>
      {children}
    </div>
  );
}

// TODO: fix for overscroll
export function FormImage({
  children,
  className,
  internalClassName,
  style,
}: React.PropsWithChildren<IFormProps>) {
  return (
    <div className={twMerge('flex h-fit w-full lg:relative lg:h-screen lg:w-1/2', className)}>
      <div
        className={twMerge(
          'h-fit w-full lg:fixed lg:bottom-0 lg:left-0 lg:right-0 lg:top-0 lg:h-screen lg:w-1/2',
          internalClassName,
        )}
        style={style}>
        {children}
      </div>
    </div>
  );
}

export function FormWrapper({
  children,
  style,
  className,
  internalClassName,
}: React.PropsWithChildren<IFormProps>) {
  return (
    <div className={twMerge('lg:min-h-screen lg:w-1/2', className)} style={style}>
      <div
        className={twMerge(
          'flex h-full flex-col items-center rounded-t-lg bg-white px-9 pb-10 pt-5 lg:items-start lg:rounded-none lg:px-20 lg:py-12',
          internalClassName,
        )}>
        {children}
      </div>
    </div>
  );
}

export function FormSubmit({
  children,
  className,
  internalClassName,
}: React.PropsWithChildren<IFormProps>) {
  return (
    <div className={twMerge('mt-auto', className)}>
      <div className={twMerge('flex w-full justify-between', internalClassName)}>{children}</div>
    </div>
  );
}

interface IProps {
  className?: string;
}
export function FormDivider({ className }: IProps) {
  return <div className={twMerge('border-border my-10 border-b', className)} />;
}
