import {
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerDescription,
  DrawerTitle,
  Button,
  COMMON_REACTIONS,
  IMessage,
  TMessageAction,
} from '@ui/components';
import { useClickOutside, useMobile } from '@ui/hooks';
import { Flag, Plus } from 'lucide-react';
import { useEffect } from 'react';

interface IProps {
  message: IMessage;
  open: boolean;
  setMessage: (message: IMessage, action: TMessageAction) => void;
  setOpen: (value: boolean) => void;
  onEmojiSelect: (emoji: string, message: IMessage) => void;
}

export const MessageItemMobileMoreMenuActionSheet = ({
  message,
  setMessage,
  setOpen,
  open,
  onEmojiSelect,
}: IProps) => {
  const ref = useClickOutside(() => setOpen(false));
  const isMobile = useMobile();

  useEffect(() => {
    if (!isMobile && open) {
      setOpen(false);
    }
  }, [isMobile, open, setOpen]);

  return (
    <Drawer open={open && isMobile} onClose={() => setOpen(false)}>
      <DrawerContent className="m-auto md:max-w-sm" ref={ref}>
        <DrawerHeader className="border-b">
          <DrawerTitle>
            <div className="flex w-full flex-row justify-between gap-2">
              {COMMON_REACTIONS.map((emoji) => (
                <Button
                  key={`emoji-${emoji}`}
                  type="icon-primary"
                  className="hover:bg-inputActive/50 h-10 w-10 rounded-sm border-none bg-transparent text-4xl"
                  onClick={() => {
                    onEmojiSelect(emoji, message);
                    setOpen(false);
                  }}>
                  {emoji}
                </Button>
              ))}
              <Button
                type="icon-primary"
                className="hover:bg-inputActive/50 h-10 w-10 rounded-sm border-none bg-transparent text-4xl"
                onClick={() => {
                  setMessage(message, 'react');
                  setOpen(false);
                }}>
                <Plus className="h-8 w-8" />
              </Button>
            </div>
          </DrawerTitle>
          <DrawerDescription className="font-nunito text-xs font-light">
            React to comment
          </DrawerDescription>
        </DrawerHeader>

        <div className="mt-4 h-full max-h-96 overflow-y-auto">
          <Button
            type="secondary"
            onClick={() => {
              setMessage(message, 'report');
              setOpen(false);
            }}
            className="w-full rounded-none bg-white text-sm">
            <Flag className="h-4 w-4 text-red-800" />{' '}
            <span className="text-sm">Report comment</span>
          </Button>
        </div>
      </DrawerContent>
    </Drawer>
  );
};
