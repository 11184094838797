export const MessageIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
    <path
      stroke={props.stroke || '#fff'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M11.493 12.438S-.484 9.96 3.678 7.558C7.191 5.53 19.294 2.045 20.986 2.946c.9 1.69-2.585 13.795-4.613 17.307-2.402 4.162-4.88-7.815-4.88-7.815Z"
      clipRule="evenodd"
    />
    <path
      stroke={props.stroke || '#fff'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="m11.493 12.438 9.493-9.493"
    />
  </svg>
);
