export const mutateUserVoteAction = (
  user_votes: { [key: string]: 'downvote' | 'upvote' | null },
  userId: string,
  downvote?: number,
  upvote?: number,
) => {
  if (downvote && user_votes[userId] === 'downvote') {
    user_votes[userId] = null;
  } else if (upvote && user_votes[userId] === 'upvote') {
    user_votes[userId] = null;
  } else if (downvote && !user_votes[userId]) {
    user_votes[userId] = 'downvote';
  } else if (upvote && !user_votes[userId]) {
    user_votes[userId] = 'upvote';
  } else if (downvote && user_votes[userId] === 'upvote') {
    user_votes[userId] = 'downvote';
  } else if (upvote && user_votes[userId] === 'downvote') {
    user_votes[userId] = 'upvote';
  }

  return user_votes;
};
