import { LoadingSkeleton } from '@ui/components/atoms';

export const PostItemSkeleton = () => {
  return (
    <div className="flex w-full flex-col gap-7">
      <div className="flex items-center gap-2">
        <LoadingSkeleton className="h-10 w-10" />
        <div className="flex flex-col gap-1">
          <LoadingSkeleton className="h-4 w-[15vw] max-w-[250px] rounded-sm" />
          <LoadingSkeleton className="h-4 w-[13vw] max-w-[250px] rounded-sm" />
        </div>
      </div>
      <div className="flex w-full flex-col gap-5">
        <LoadingSkeleton className="h-8 w-full max-w-[500px] rounded-sm" />
        <div className="flex items-center gap-2">
          <LoadingSkeleton className="h-[45vh] w-full rounded-sm" />
        </div>
        <div className="flex items-center gap-1">
          <LoadingSkeleton className="h-7 w-[100px] rounded-sm" />
        </div>
      </div>
    </div>
  );
};
