export const CircleSquareIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" fill="none" {...props}>
    <path
      stroke={props.color || '#707B8F'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M16.501 4.561a3.062 3.062 0 1 1-6.124 0 3.062 3.062 0 0 1 6.124 0ZM7.622 4.561a3.061 3.061 0 1 1-6.123 0 3.061 3.061 0 0 1 6.123 0ZM16.501 13.385a3.061 3.061 0 1 1-6.122-.001 3.061 3.061 0 0 1 6.122 0ZM7.622 13.385a3.06 3.06 0 1 1-6.121 0 3.06 3.06 0 0 1 6.121 0Z"
      clipRule="evenodd"
    />
  </svg>
);
