export const EditIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} fill="none" {...props}>
    <path
      stroke={props.stroke || '#2D3139'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M10.335 16.508h6.377"
    />
    <path
      stroke={props.stroke || '#2D3139'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M13.058 1.859v0a3.042 3.042 0 0 0-4.258.607L2.048 11.46c-1.74 2.318-.094 5.19-.094 5.19s3.244.746 4.958-1.54l6.752-8.994a3.042 3.042 0 0 0-.606-4.258Z"
      clipRule="evenodd"
    />
    <path
      stroke={props.stroke || '#2D3139'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="m7.504 4.211 4.864 3.651"
    />
  </svg>
);
