import { Text, CustomTooltip } from '@ui/components';
import { twMerge } from 'tailwind-merge';

export interface IFormItemProps {
  label?: string;
  error?: string;
  tooltip?: string;
  className?: string;
  labelComponent?: React.ReactNode;
  children?: React.ReactNode;
  isHideError?: boolean;
  isHidePadding?: boolean;
  formType?: string;
}

export function FormItem({
  label,
  error,
  tooltip,
  className,
  labelComponent,
  children,
  isHidePadding,
  isHideError,
  formType,
}: IFormItemProps) {
  return (
    <div
      className={twMerge(
        `relative flex w-full flex-col gap-2`,
        className,
        formType === 'checkbox' && 'mr-3 h-[40px] w-[40px] gap-0',
      )}>
      {(labelComponent || label) && (
        <div
          className={twMerge(
            'flex w-full justify-between',
            isHidePadding && !label ? 'hidden' : '',
          )}>
          {label && (
            <div className="flex gap-2">
              <Text type="s" className={`text-subtitle font-medium ${!!error ? 'text-error' : ''}`}>
                {label}
              </Text>
              {tooltip && <CustomTooltip place="right" message={tooltip} />}
            </div>
          )}
          {labelComponent}
        </div>
      )}
      {children}
      {error && (
        <Text
          type="xs"
          className={twMerge(
            `text-error font-semibold`,
            !error ? 'invisible' : '',
            isHideError ? 'hidden' : isHidePadding && !error ? 'hidden' : '',
            formType === 'checkbox' && 'mt-6 w-[80vw] sm:mt-1',
          )}>
          {error || 'Valid'}
        </Text>
      )}
    </div>
  );
}
