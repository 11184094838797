'use client';
import { ChangeEvent, useRef } from 'react';
import { twMerge } from 'tailwind-merge';
import { FormItem, IFormItemProps } from '@ui/components/atoms/form-item';

export interface IInput extends IFormItemProps {
  onChange?(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void;
  onBlur?(e: ChangeEvent): void;
  onKeyUp?(e: React.KeyboardEvent<any>): void;
  value?: string;
  placeholder?: string;
  disabled?: boolean;
  isTextArea?: boolean;
  wrapperClassName?: string;
  inputClassName?: string;
  beforeInputComponent?: React.ReactNode;
  isHidePadding?: boolean;
  children?: React.ReactNode;
  maxLength?: number;
  type?: 'text' | 'number' | 'password' | 'checkbox';
  readOnly?: boolean;
  postFixComponent?: React.ReactNode;
  preFixComponent?: React.ReactNode;
  min?: number;
  max?: number;
  step?: number;
  rawInputClassName?: string;
}

export function Input({
  value,
  error,
  placeholder,
  label,
  disabled,
  wrapperClassName,
  inputClassName,
  isTextArea,
  children,
  tooltip,
  type = 'text',
  labelComponent,
  beforeInputComponent,
  isHidePadding,
  isHideError,
  maxLength,
  onChange,
  onKeyUp,
  onBlur,
  readOnly,
  postFixComponent,
  preFixComponent,
  min,
  max,
  step,
  rawInputClassName,
}: IInput) {
  const inputRef = useRef<HTMLInputElement>(null);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const handleWrapperClick = () => {
    if (isTextArea) {
      // Focus the textarea when the wrapper is clicked
      if (textAreaRef.current) {
        textAreaRef.current.focus();
      }
    } else {
      // Focus the input when the wrapper is clicked
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }
  };
  const params = {
    readOnly,
    placeholder,
    value,
    disabled,
    type,
    maxLength,
    onChange,
    onBlur,
    onKeyUp,
    className: twMerge(
      'bg-input placeholder:text-note p-2 placeholder:font-inter text-black outline-none resize-none border-transparent w-full h-full',
      disabled
        ? 'opacity-80 cursor-not-allowed'
        : 'hover:bg-inputActive group-hover:bg-inputActive focus:bg-inputActive',
      rawInputClassName,
    ),
    min,
    max,
    step,
  };

  return (
    <FormItem
      label={label}
      labelComponent={labelComponent}
      tooltip={tooltip}
      error={error}
      className={wrapperClassName}
      isHidePadding={isHidePadding}
      isHideError={isHideError}
      formType={type}>
      <div
        className={twMerge(
          'bg-input focus-within:bg-inputActive  group h-full w-full rounded-md border border-transparent px-3 py-2',
          disabled ? 'cursor-not-allowed opacity-60' : 'hover:bg-inputActive focus:bg-inputActive',
          inputClassName,
          error && 'border-error',
          type === 'checkbox' && 'px-2 py-2',
          readOnly && 'opacity-50',
        )}
        onClick={handleWrapperClick}>
        {beforeInputComponent}
        <div className="flex w-full items-center h-full">
          {preFixComponent}
          {isTextArea ? (
            <textarea ref={textAreaRef} {...params} />
          ) : (
            <input ref={inputRef} {...params} />
          )}
          {postFixComponent}
        </div>
      </div>
      {children}
    </FormItem>
  );
}
