import { LoadingSkeleton } from '@ui/components/atoms';
import { twMerge } from 'tailwind-merge';

interface IFanProfileBadgeLoader {
  className?: string;
}
export function FanProfileBadgeLoader({ className }: IFanProfileBadgeLoader) {
  return (
    <div className={twMerge('flex flex-col items-center gap-2', className)}>
      <LoadingSkeleton className="my-2 h-14 w-14 rounded" />
      <LoadingSkeleton className="h-10 w-20 rounded-sm" />
      <LoadingSkeleton className="h-6 w-16" />
      <LoadingSkeleton className="h-6 w-16" />
    </div>
  );
}
