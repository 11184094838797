import { Button, Icon, Text } from '@ui/components/atoms';
import { InputFile, IInputFile } from '.';
import { twMerge } from 'tailwind-merge';

interface IInputFormFile extends IInputFile {
  onCancel(): void;
  fileName?: string;
  fileError?: string;
}

export function InputFormFile({
  onFileUpload,
  onCancel,
  buttonParams,
  disabled,
  file,
  fileName,
  fileError,
  children,
  className,
}: React.PropsWithChildren<IInputFormFile>) {
  const name = (file && file.name) || fileName;
  return (
    <div className="flex w-full flex-col">
      <div
        className={twMerge(
          name ? 'flex w-full flex-col items-center justify-between gap-2 lg:flex-row' : '',
          className,
        )}>
        {name && <span className="text-sm font-semibold">{name}</span>}
        <div className={name ? 'flex w-full items-center justify-between lg:w-auto' : ''}>
          <InputFile
            disabled={disabled}
            className={name ? 'mr-2' : ''}
            file={file}
            buttonParams={buttonParams}
            onFileUpload={onFileUpload}>
            {children}
          </InputFile>
          {name && (
            <Button
              disabled={disabled}
              type="icon-secondary"
              className="rounded-full"
              onClick={onCancel}>
              <Icon type="delete" />
            </Button>
          )}
        </div>
      </div>
      {fileError && (
        <div className="flex w-full justify-center">
          <Text type="s" className="text-center font-bold text-red-500">
            {fileError}
          </Text>
        </div>
      )}
    </div>
  );
}
