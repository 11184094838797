import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

export const TimezoneService = {
  getFormattedLocalTime(): string {
    const offset = -new Date().getTimezoneOffset();
    const sign = offset >= 0 ? '' : '-';
    const hours = Math.floor(Math.abs(offset) / 60)
      .toString()
      .padStart(2, '0');
    const minutes = (Math.abs(offset) % 60).toString().padStart(2, '0');
    return `${sign}${hours}:${minutes}`;
  },
  getUserLocalTimezoneWithOffset(): string {
    const timezoneName = dayjs.tz.guess();
    const currentTime = dayjs().tz(timezoneName);

    let abbreviation = currentTime.format('z');
    const utcOffset = currentTime.format('Z');

    if (abbreviation === 'z') {
      abbreviation = timezoneName.split('/')[1]?.replace('_', ' ') || 'GMT';
    }

    return `${abbreviation} (UTC${utcOffset})`;
  },
};
