export const TicketIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 16" fill="none" {...props}>
    <path
      stroke={props.color || '#707B8F'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M16.867 9.662c-2.217 0-2.217-3.429 0-3.429 0-3.972 0-5.39-7.829-5.39S1.21 2.26 1.21 6.233c2.217 0 2.217 3.429 0 3.429 0 3.973 0 5.391 7.83 5.391 7.828 0 7.828-1.418 7.828-5.391Z"
      clipRule="evenodd"
    />
    <path
      stroke={props.color || '#707B8F'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M9.039 5.643c-.57 0-.624.907-.962 1.229-.34.32-1.392-.046-1.538.523-.146.57.801.764.919 1.252.116.489-.462 1.176.032 1.518.495.342 1.028-.431 1.549-.431.52 0 1.053.773 1.548.431.495-.342-.084-1.029.033-1.518.117-.488 1.065-.682.919-1.252-.147-.569-1.2-.203-1.539-.523-.338-.322-.392-1.23-.961-1.23Z"
      clipRule="evenodd"
    />
  </svg>
);
