'use client';
import {
  ConversationDeleteActionModal,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
  Icon,
  TooltipContent,
  TooltipTrigger,
  TooltipProvider,
  Tooltip,
  useConversationContext,
} from '@ui/components';
import { Button } from '@ui/components/ui/button';
import { ConversationSettings } from './conversation-settings/conversation-settings';
import { EllipsisVertical, Trash } from 'lucide-react';
import { useState } from 'react';

export const ConversationHeader = () => {
  const state = useConversationContext();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  return (
    <>
      <ConversationDeleteActionModal
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
      />

      <div className="sticky top-0 flex flex-row items-center justify-between border-b bg-white p-2">
        <div className="flex flex-grow items-center gap-2">
          <div
            className="bg-border flex h-[44px] w-[44px] items-center justify-center rounded-full"
            style={{ background: 'linear-gradient(153deg, #56CCF2 16.67%, #D356F2 100%)' }}>
            <Icon type="chat" />
          </div>
          <span className="block max-w-[calc(40vw)] truncate font-bold">
            {state.selectedConversationSet?.conversation.name}
          </span>
        </div>

        <div className="flex items-center">
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <div className="mr-3 flex items-center gap-1">
                  <Icon type="openMessage" className="h-4 w-4" />
                  <p>{state.selectedConversationSet?.last_message_read_ratio?.toFixed(0) ?? 0}%</p>
                </div>
              </TooltipTrigger>
              <TooltipContent className="mr-2 w-60">
                <span>
                  This data shows the percentage of recipients in the conversation who have read the
                  most recent message. It updates with each new message sent.
                </span>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>

          <ConversationSettings>
            <Button size="icon" variant="ghost" className="rounded">
              <Icon type="settings" className="h-4 w-4" />
            </Button>
          </ConversationSettings>

          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button size="icon" variant="ghost" className="rounded">
                <EllipsisVertical className="h-4 w-4 " />
              </Button>
            </DropdownMenuTrigger>

            <DropdownMenuContent className="w-40">
              <DropdownMenuGroup>
                <DropdownMenuItem className="space-x-2" onClick={() => setOpenDeleteModal(true)}>
                  <Trash className="h-4 w-4" />
                  <span>Delete</span>
                </DropdownMenuItem>
              </DropdownMenuGroup>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </div>
    </>
  );
};
