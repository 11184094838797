import { twMerge } from 'tailwind-merge';
import './button.css';
import Link from 'next/link';
import { Spinner } from '../spinner';

const ButtonStyles = {
  primary:
    'btn-primary justify-center min-w-[150px] bg-primary hover:bg-primaryHover active:bg-primaryActive text-white',
  secondary:
    'btn-secondary min-w-[150px] bg-input text-black hover:bg-secondaryHover active:bg-secondaryActive',
  outline:
    'flex justify-center items-center gap-5 px-4 py-6  bg-transparent border border-border hover:bg-input active:bg-inputActive',
  notActive:
    'btn-secondary min-w-[150px] bg-border hover:bg-border active:bg-border cursor-default text-white',
  icon: 'btn-icon h-12 w-12 lg:h-16 lg:w-16 justify-center bg-transparent border border-border hover:bg-input active:bg-inputActive',
  ['icon-secondary']: 'h-14 w-14 justify-center hover:bg-secondaryHover active:bg-secondaryActive',
  ['input-active']: 'px-4 rounded-sm bg-subtitle text-white hover:bg-title active:bg-subtitle',
  ['icon-primary']:
    'rounded-full bg-tabActive text-primary hover:bg-inputActive active:bg-secondaryActive',

  input:
    'px-4 btn-input rounded-sm bg-border text-black hover:bg-[#C8CBD0] active:bg-secondaryActive',
};

const ButtonSizes = {
  regular: 'px-8 py-4',
  small: 'py-3 px-4 text-sm',
  smallIcon: 'lg:h-12 lg:w-12 p-0',
  none: '',
};

export type IButtonType = keyof typeof ButtonStyles;
export type IButtonSize = keyof typeof ButtonSizes;

interface IButton {
  type: IButtonType;
  size?: IButtonSize;
  className?: string;
  href?: string;
  disabled?: boolean;
  isLoading?: boolean;
  behavior?: 'button' | 'submit';
  onClick?(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void;
}

const WITHOUT_SPACING = ['icon', 'icon-secondary', 'icon-primary', 'input', 'input-active'];

export function Button({
  children,
  disabled,
  type, // this should be called variant not type
  className,
  size,
  href,
  isLoading,
  onClick,
  behavior = 'submit',
}: React.PropsWithChildren<IButton>) {
  const buttonSpacing = size
    ? ButtonSizes[size]
    : WITHOUT_SPACING.includes(type)
    ? ButtonSizes.none
    : ButtonSizes.regular;

  const style = twMerge(
    `flex-shrink-0 font-nunito flex cursor-pointer items-center justify-center rounded-lg text-lg transition-colors disabled:cursor-not-allowed disabled:opacity-60`,
    ButtonStyles[type],
    buttonSpacing,
    isLoading ? 'cursor-wait' : '',
    `${className || ''}`,
  );

  const content = (
    <>
      {isLoading ? (
        <Spinner
          className="mr-2 animate-spin"
          fill={type === 'primary' || type === 'input-active' ? '#fff' : '#2D3139'}
        />
      ) : null}
      {children}
    </>
  );

  return href ? (
    <Link href={href} className={style}>
      {content}
    </Link>
  ) : (
    <button type={behavior} className={style} disabled={disabled || isLoading} onClick={onClick}>
      {content}
    </button>
  );
}
