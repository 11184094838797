export const BackArrow = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
    <path
      stroke={props.stroke || '#2D3139'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M4.25 12.274h15M10.3 18.299s-6.05-3.261-6.05-6.023c0-2.764 6.05-6.026 6.05-6.026"
    />
  </svg>
);
