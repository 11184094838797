'use client';
import { ReactNode, useState, useMemo } from 'react';
import {
  Badge,
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTrigger,
  EasyTab,
  EasyTabContent,
  EasyTabItem,
  IEngagementScoreResponse,
} from '@ui/components';
import { Gem, BadgeCheckIcon } from 'lucide-react';
import EngagementMetrics from './engagement-metric-wrapper';
import ScoreWidget from './score-widget';
import ModalScore from './modal-score';
import badges from '../../../../assets/images/badges.png';

interface EngagementScoreModalProps {
  children: ReactNode;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  score: IEngagementScoreResponse;
  isCreator?: boolean;
  isProfile?: boolean;
  isOtherProfile?: boolean;
}
export const EngagementScoreModal: React.FC<EngagementScoreModalProps> = ({
  children,
  isOpen,
  setIsOpen,
  score,
  isCreator,
  isProfile,
  isOtherProfile,
}: EngagementScoreModalProps) => {
  const tabItemClassname = 'p-2 w-[45%] border-primaryLight rounded-lg';
  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        <span>{children}</span>
      </DialogTrigger>
      <DialogContent className="bg-white p-6 sm:max-w-lg">
        <DialogHeader className="mb-4">
          <ScoreWidget score={score} border size="lg" Title="Overall score" />
        </DialogHeader>
        <EasyTab defaultTab="Engagement" className="">
          <div className="flex justify-between space-x-2 w-full mb-4">
            <EasyTabItem
              label="Engagement"
              className="flex justify-center p-3 w-[48%] border border-primaryLight rounded-lg "
              useBottomActiveBorder={false}
              useFullBorderWhenActive
              icon={Gem}
            />
            <EasyTabItem
              label="Badges"
              className="flex justify-center p-3 w-[48%] border border-primaryLight rounded-lg"
              useBottomActiveBorder={false}
              useFullBorderWhenActive
              icon={BadgeCheckIcon}
            />
          </div>
          <EasyTabContent label="Engagement" className="min-h-fit">
            <div className="w-full mb-4">
              <ModalScore
                score={score?.total_engagement_score}
                label={isOtherProfile ? 'Engagement score' : 'Your engagement score'}
                allScoreResponse={score}
              />
            </div>
            <div className="max-h-[350px] min-h-[350px] overflow-auto scrollbar-none">
              <EngagementMetrics score={score} />
            </div>
          </EasyTabContent>
          <EasyTabContent label="Badges" className="min-h-fit">
            <div className="w-full mb-4">
              <ModalScore
                score={score?.badges_score}
                label={isOtherProfile ? 'Badge score' : 'Your badge score'}
                link="View all badges"
                allScoreResponse={score}
                setIsOpen={setIsOpen}
                isProfile={isProfile}
              />
            </div>
            <div className="max-h-[350px] min-h-[350px] w-full rounded-sm flex justify-center items-center">
              <img
                src={badges.src}
                alt="badges"
                className="h-[280px] md:h-[330px] object-cover rounded-sm w-[100%]"
              />
            </div>
          </EasyTabContent>
        </EasyTab>
      </DialogContent>
    </Dialog>
  );
};
