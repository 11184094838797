'use client';
import { twMerge } from 'tailwind-merge';
import { Button } from './button';
import { Icon } from './icon';
import { ModalWrapper } from './modal-wrapper';
import { Title, TitleSizes } from './text';

interface IModal {
  isOpen: boolean;
  onClose?(e: React.MouseEvent): void;
  className?: string;
  title?: string;
  size?: keyof typeof TitleSizes;
}
export function Modal({
  className,
  title,
  isOpen,
  children,
  size = 'mm',
  onClose,
}: React.PropsWithChildren<IModal>) {
  function onBodyClick(e: React.MouseEvent) {
    e.persist();
    e.stopPropagation();
  }

  return (
    <ModalWrapper isOpen={isOpen} type="center" onClose={onClose}>
      <div
        onClick={onBodyClick}
        className={twMerge('rounded-lg bg-white p-8 lg:rounded-xl', className)}>
        <div className="flex w-full items-center justify-between gap-4">
          <Title type={size}>{title}</Title>
          <div className="flex justify-end">
            <Button type="icon-secondary" onClick={onClose}>
              <Icon type="close" />
            </Button>
          </div>
        </div>

        {children}
      </div>
    </ModalWrapper>
  );
}
