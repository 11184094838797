import React from 'react';
import { IFile } from '@ui/hooks/file/types';
import { Minus } from 'lucide-react';
import { twMerge } from 'tailwind-merge';

interface FilePreviewProps {
  className?: string;
  file: IFile;
  onDelete: (id: string) => void;
}

export const FilePreview: React.FC<FilePreviewProps> = ({ file, onDelete, className }) => {
  return (
    <div className="relative">
      <div>
        <img
          src={file.src}
          width={80}
          height={80}
          className={twMerge('h-8 w-8 rounded-sm object-cover', className)}
          alt="preview"
        />
        <Minus
          onClick={() => onDelete(file.id)}
          className="text-primary bg-secondaryHover absolute -right-1 -top-2 h-4 w-4 cursor-pointer rounded-md text-xs"
        />
      </div>
    </div>
  );
};
