'use client';
import { useEffect, useRef, useState } from 'react';

import { Note, NoteSizeType } from './text';
import { twMerge } from 'tailwind-merge';

interface IReadMore {
  text: string;
  textSize?: NoteSizeType;
  className?: string;
}

export function ReadMore({ className, text, textSize }: IReadMore) {
  const [isShouldExpand, setIsShouldExpand] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const ref = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    if (!ref.current) {
      return;
    }
    const element = ref.current;
    setIsShouldExpand(element.scrollHeight > element.clientHeight);
  }, [text]);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  return (
    <div className="h-full">
      <Note
        ref={ref}
        type={textSize || 'l'}
        className={twMerge(
          `mr-2 truncate whitespace-pre-wrap`,
          expanded ? '' : 'line-clamp-2',
          className,
        )}>
        {text}
      </Note>

      {isShouldExpand && (
        <button
          className="hover:text-primary font-semibold text-black transition-colors focus:outline-none"
          onClick={toggleExpand}>
          {expanded ? 'Read Less' : 'Read More'}
        </button>
      )}
    </div>
  );
}
