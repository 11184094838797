import { MobileHeaderBackButton, Title } from '@ui/components';
import { twMerge } from 'tailwind-merge';

interface IMobileHeaderContent {
  onBack(): void;
  title: string;
  subTitle?: string;
  leftItem?: JSX.Element;
  titleClassName?: string;
  subTitleClassName?: string;
  backStroke?: string;
  theme?: 'light' | 'dark';
}
export function MobileHeaderContent({
  title,
  onBack,
  leftItem,
  titleClassName,
  backStroke,
  theme = 'light',
  subTitle,
  subTitleClassName,
}: IMobileHeaderContent) {
  const isDark = theme === 'dark';
  return (
    <>
      <div className="basis-1/5">
        <MobileHeaderBackButton isDark={isDark} onBack={onBack} backStroke={backStroke} />
      </div>
      <div className="w-full">
        <Title
          type="sm"
          className={twMerge('text-center font-bold', isDark ? 'text-white' : '', titleClassName)}>
          {title}
        </Title>
        {subTitle && (
          <Title
            type="xs"
            className={twMerge('text-center', isDark ? 'text-white' : '', subTitleClassName)}>
            {subTitle}
          </Title>
        )}
      </div>
      <div className="flex basis-1/5 justify-end">{leftItem}</div>
    </>
  );
}
