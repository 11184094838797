export const POLLING_INTERVAL = {
  tenSeconds: 1000 * 10,
  fifteenSeconds: 1000 * 15,
  thirtySeconds: 1000 * 30,
  oneMinute: 1000 * 60,
  twoMins: 1000 * 60 * 2,
  fiveMins: 1000 * 60 * 5,
  tenMins: 1000 * 60 * 10,
  fifteenMins: 1000 * 60 * 15,
  thirtyMins: 1000 * 60 * 30,
  oneHour: 1000 * 60 * 60,
  fullDay: 1000 * 60 * 60 * 24,
};
