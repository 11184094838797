import { Button, Icon } from '@ui/components';
import { twMerge } from 'tailwind-merge';

interface IMobileBackButton {
  isDark?: boolean;
  onBack?(): void;
  backStroke?: string;
  showButton?: boolean;
}

export function MobileHeaderBackButton({
  isDark = false,
  onBack,
  backStroke,
  showButton = true,
}: IMobileBackButton) {
  return showButton ? (
    <Button
      type="icon"
      className={twMerge(
        'bg-bgGrey hover:bg-inputActive h-12 w-12 border-transparent',
        isDark
          ? 'border-transparent bg-transparent hover:border-white hover:bg-transparent active:border-white active:bg-transparent'
          : '',
      )}
      onClick={onBack}>
      <Icon type="backArrow" stroke={backStroke || isDark ? '#fff' : undefined} />
    </Button>
  ) : (
    <div className="h-12 w-12" />
  );
}
