import { Icon, LoadingSkeleton } from '../atoms';
import { getBadgeBackground } from '../organisms';
import { BadgeImageInfo, IBadgeImageInfo } from './badge-image-info';

interface IBadgeImageCircle extends IBadgeImageInfo {
  isLoading?: boolean;
}
export function BadgeImageCircle(props: IBadgeImageCircle) {
  const background = !props.isLoading && props.color ? getBadgeBackground(props.color) : '';

  return (
    <div
      className="relative flex items-center justify-center overflow-hidden py-8 lg:h-full lg:w-full"
      style={{ backgroundColor: background }}>
      <div className="absolute top-1/2 flex w-full justify-center lg:hidden">
        <div className="aspect-square w-4/5 rounded-t-full bg-white/70"></div>
      </div>
      <div className="flex aspect-square flex-col items-center justify-center rounded-full bg-transparent lg:w-4/5 lg:flex-row lg:bg-white/70">
        <div className="mb-4 lg:absolute lg:left-16 lg:top-16 lg:mb-0">
          <Icon type="fanLogoBlack" width={150} height={50} />
        </div>
        {props.isLoading ? (
          <div className="relative z-10 flex h-full flex-col items-center justify-center py-4">
            <LoadingSkeleton className="mb-6 aspect-square w-32 lg:w-64" />
            <LoadingSkeleton className="mb-4 h-10 w-52 lg:h-12 lg:w-64" />
            <LoadingSkeleton className="h-8 w-56 lg:h-10 lg:w-72" />
          </div>
        ) : (
          <>
            <div className="hidden lg:block">
              <BadgeImageInfo {...props} size="xl" className="lg:p-0" imageClassName="lg:mb-2" />
            </div>
            <div className="relative z-10 lg:hidden">
              <BadgeImageInfo {...props} type={1} size="xs" className="p-0" />
            </div>
          </>
        )}
      </div>
    </div>
  );
}
