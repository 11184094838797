'use client';
import { twMerge } from 'tailwind-merge';
import { Note, Avatar, LoadingSkeleton } from '@ui/components';
import { IFan } from '../leaderboard.typings';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@ui/components';
import './leaderboard-list.css';
import LeaderboardScoreInfo from './leaderboard-score-info';
import { LeaderoardAccordionItem, LeaderoardScoreItem } from './leaderoard-score-item';

export interface ILeaderboardList {
  currentFan?: IFan;
  fans: IFan[];
  fanId?: string | null;
  isLoading: boolean;
  hasMore: boolean;
  isBadgeLeaderboard?: boolean;
  isCustom?: boolean;
  infiniteScrollTargetId?: string;
  onNextPage(): void;
  onFanOpen?(id: string, rank: number, totalFans?: number): void;
}

export const LeaderboardList = ({
  fans,
  fanId,
  hasMore,
  isLoading,
  isBadgeLeaderboard,
  onNextPage,
  onFanOpen,
  currentFan,
  infiniteScrollTargetId,
  isCustom = false,
}: ILeaderboardList) => {
  const fanAmount = fans[0]?.rankOutOf;
  function onClick(id: string, rank: number, totalFans?: number) {
    if (onFanOpen) {
      onFanOpen(id, rank, totalFans);
    }
  }
  return (
    <InfiniteScroll
      scrollableTarget={infiniteScrollTargetId}
      className="flex w-full flex-col items-center gap-2 overflow-auto px-2"
      dataLength={fans.length}
      next={onNextPage}
      hasMore={hasMore}
      loader={null}
      // height={window.innerHeight * 0.7}
    >
      {currentFan?.rank &&
        (isCustom ? (
          <div
            className={twMerge(
              'bg-bgGrey w-full max-w-[600px] rounded-full p-4 transition md:w-4/5',
              isBadgeLeaderboard ? 'md:w-full' : '',
              'bg-[#FFD197]',
              onFanOpen ? 'hover:bg-inputActive active:bg-border cursor-pointer' : '',
            )}
            onClick={() =>
              onClick(
                currentFan?.userId ?? currentFan?.reference_id ?? '',
                currentFan.rank,
                fanAmount,
              )
            }
            key={currentFan?.rank}>
            <div className="flex items-center space-x-4">
              <div className="flex-shrink-0">
                <Avatar
                  size="s"
                  src={currentFan?.avatarUrl || currentFan.avatar_url || currentFan?.avatar}
                />
              </div>
              <div className="min-w-0 flex-1">
                <Note type="m" className="text-subtitle truncate">
                  Your Current Rank
                </Note>
                <Note type="xs" className="block truncate">
                  {currentFan?.score &&
                    !isNaN(Number(currentFan?.score)) &&
                    Number(currentFan?.score).toFixed(4)}
                </Note>
              </div>
              <div className="text-subtitle items-center rounded-full bg-white px-3 py-1 text-base">
                {currentFan?.rank}
              </div>
            </div>
          </div>
        ) : (
          <Accordion
            type="single"
            collapsible
            className={twMerge(
              'bg-bgGrey w-full max-w-[600px] p-4 transition md:w-4/5 rounded-2xl',
              isBadgeLeaderboard ? 'md:w-full' : '',
              'bg-[#FFD197]',
              onFanOpen ? 'hover:bg-inputActive active:bg-border cursor-pointer' : '',
            )}
            key={currentFan?.rank}>
            <AccordionItem
              value={currentFan?.id + 'currentFan'}
              className="flex flex-col border-none w-full">
              <AccordionTrigger className="w-full hover:no-underline p-1">
                <div className="flex items-center justify-between space-x-2 w-full">
                  <div
                    className="flex items-center space-x-2"
                    onClick={() =>
                      onClick(
                        currentFan?.userId ?? currentFan?.reference_id ?? '',
                        currentFan.rank,
                        fanAmount,
                      )
                    }>
                    <Avatar
                      size="s"
                      src={currentFan?.avatarUrl || currentFan.avatar_url || currentFan?.avatar}
                    />
                    <Note type="m" className="text-subtitle font-bold truncate">
                      Your Current Rank
                    </Note>
                  </div>
                  <div className="text-subtitle rounded-full bg-white px-3 py-1 text-base">
                    {currentFan?.rank}
                  </div>
                </div>
              </AccordionTrigger>
              <AccordionContent className="text-xs px-2 py-2 w-full">
                <div className="w-full flex space-x-2">
                  {/* <Note type="xs" className="block truncate">
                  {currentFan?.score &&
                    !isNaN(Number(currentFan?.score)) &&
                    Number(currentFan?.score).toFixed(4)}
                  </Note> */}
                  <LeaderboardScoreInfo
                    label="Badges"
                    score={
                      (currentFan?.score &&
                        !isNaN(Number(currentFan?.score)) &&
                        Number(currentFan?.score).toFixed(4)) ||
                      '-'
                    }
                  />
                  <LeaderboardScoreInfo
                    label="Engagement"
                    score={
                      (currentFan?.engagement_score &&
                        Number(currentFan?.engagement_score).toFixed(4)) ??
                      '-'
                    }
                  />
                  <LeaderboardScoreInfo
                    label="Overall Score"
                    score={
                      (currentFan?.overall_score && Number(currentFan?.overall_score).toFixed(4)) ??
                      '-'
                    }
                  />
                </div>
              </AccordionContent>
            </AccordionItem>
          </Accordion>
        ))}
      {currentFan?.rank && (
        <hr className="my-4 w-full max-w-[600px] border-2 border-t border-[#FFD197] transition md:w-4/5" />
      )}
      <div className="w-full max-w-[600px] md:w-4/5 space-y-3">
        {fans.map((fan, key) => {
          return isCustom ? (
            <div className="">
              <LeaderoardScoreItem
                fan={fan}
                fanId={fanId}
                onFanOpen={onFanOpen}
                isBadgeLeaderboard={isBadgeLeaderboard}
              />
            </div>
          ) : (
            <div className="">
              <LeaderoardAccordionItem
                fan={fan}
                fanId={fanId}
                onFanOpen={onFanOpen}
                isBadgeLeaderboard={isBadgeLeaderboard}
              />
            </div>
          );
        })}
      </div>
      {isLoading && (
        <>
          <LoadingSkeleton className="h-16 w-full max-w-[600px] md:w-4/5" />
          <LoadingSkeleton className="h-16 w-full max-w-[600px] md:w-4/5" />
          <LoadingSkeleton className="h-16 w-full max-w-[600px] md:w-4/5" />
        </>
      )}
    </InfiniteScroll>
  );
};
