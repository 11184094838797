'use client';
import { Title, Button, InputPassword } from '@ui/components';
import { useResetPasswordState, IForgotPassword } from './reset-password.state';

export function ResetPasswordComponent(props: IForgotPassword) {
  const { password, isLoading, onSubmit } = useResetPasswordState(props);
  return (
    <div className="flex h-full w-full flex-col justify-center">
      <div className="w-full">
        <Title type="l" className="mb-6 text-center lg:mb-10 lg:text-left">
          Enter your new password
        </Title>

        <div className="mb-10 lg:mb-20">
          <InputPassword label="Password" form={password} disabled={isLoading} />
        </div>

        <Button type="primary" className="w-full" onClick={onSubmit} isLoading={isLoading}>
          Reset Password
        </Button>
      </div>
    </div>
  );
}
