'use client';
import { useEffect, useMemo, useState } from 'react';
import {
  ContextUserType,
  EmptyScreen,
  IConversationRestrictionOptions,
  IMessage,
  ISelectedEmoji,
  TMessageAction,
  useMessageContext,
} from '@ui/components';
import { MessageItem } from './message-item';
import { twMerge } from 'tailwind-merge';
import { EmojiPickerActionSheet } from '@ui/components/moleculas/emoji-picker/emoji-picker-action-sheet';
import { MessageItemReportMessageActionSheet } from './message-item-report-message-action-sheet';
import { MessageItemMobileMoreMenuActionSheet } from './message-item-mobile-more-menu-action-sheet';
import { MessageListSkeleton } from './message-list-skeleton';
import useIntersectionObserver from '@ui/hooks/intersection-observer/intersection-observer';

interface Props {
  contextUserType: ContextUserType;
  restrictions: IConversationRestrictionOptions | undefined;
  className?: string;
  onScrollToBottom?: () => void;
}
export const MessageList = ({
  className,
  contextUserType,
  restrictions,
  onScrollToBottom,
}: Props) => {
  const [selectedMessage, setSelectedMessage] = useState<IMessage | null>(null);
  const [openEmojiPicker, setOpenEmojiPicker] = useState(false);
  const [openReportMenu, setOpenReportMenu] = useState(false);
  const [openMobileMoreMenu, setOpenMobileMoreMenu] = useState(false);
  const messageState = useMessageContext(contextUserType);
  const messagesEndRef = useIntersectionObserver(onScrollToBottom, { threshold: 1.0 });

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const onReactToMessage = (emoji: ISelectedEmoji) => {
    if (selectedMessage) {
      messageState.onReactToMessage(
        selectedMessage.id,
        selectedMessage.slug,
        'reaction',
        emoji.native,
        messageState.query,
      );
      setSelectedMessage(null);
    }
  };

  const onReactToMessageWithNativeEmoji = (emoji: string) => {
    if (selectedMessage) {
      messageState.onReactToMessage(
        selectedMessage.id,
        selectedMessage.slug,
        'reaction',
        emoji,
        messageState.query,
      );
      setSelectedMessage(null);
    }
  };

  const onCloseEmojiPicker = () => {
    setSelectedMessage(null);
    setOpenEmojiPicker(false);
  };

  const onCloseReport = () => {
    setSelectedMessage(null);
    setOpenReportMenu(false);
  };

  const onCloseMobileMenu = () => {
    setOpenMobileMoreMenu(false);
  };

  useEffect(() => {
    if (messageState.messages.length > 0 && !messageState.isLoading) {
      setTimeout(() => {
        scrollToBottom();
      }, 100);
    }
  }, [messageState.messages]);

  const handleMessageSelection = (message: IMessage, action: TMessageAction) => {
    setSelectedMessage(message);

    if (action === 'react') {
      setOpenEmojiPicker(true);
    }

    if (action === 'report') {
      setOpenReportMenu(true);
    }

    if (action === 'mobile-more-menu') {
      setOpenMobileMoreMenu(true);
    }
  };

  const onReportMessage = (report: string, message: IMessage) => {
    messageState.onReportMessage({ message_id: message.id, report });
    setSelectedMessage(null);
  };

  const shouldRender = useMemo(() => {
    return messageState.query.thread_id === messageState?.messages![0]?.thread_id;
  }, [messageState.query.thread_id, messageState?.messages]);

  return (
    <>
      <div className={twMerge('h-full flex-grow space-y-4 overflow-y-auto p-2', className)}>
        {contextUserType === 'creator' &&
          !messageState.isLoading &&
          messageState.messages.length === 0 &&
          messageState.conversationMiscellaneous?.cohortInfo && (
            <div className="mx-auto max-w-sm">
              <EmptyScreen
                className="bg-transparent lg:bg-transparent"
                titleClassName="text-gray-500"
                title="You have not created any messages for this cohort yet."
              />
            </div>
          )}

        {messageState.isLoading ? (
          <MessageListSkeleton />
        ) : (
          <>
            {shouldRender &&
              messageState.messages.map((message, key) => (
                <MessageItem
                  restrictions={restrictions}
                  setSelectedMessage={handleMessageSelection}
                  key={key}
                  message={message}
                  isCreator={messageState.user_id === message.creator_id}
                />
              ))}
          </>
        )}
        <div ref={messagesEndRef} />
      </div>

      <EmojiPickerActionSheet
        message={selectedMessage as IMessage}
        open={openEmojiPicker}
        setOpen={onCloseEmojiPicker}
        onEmojiSelect={onReactToMessage}
      />

      <MessageItemReportMessageActionSheet
        message={selectedMessage as IMessage}
        open={openReportMenu}
        setOpen={onCloseReport}
        onReportMessage={onReportMessage}
      />

      <div className="md:hidden">
        <MessageItemMobileMoreMenuActionSheet
          message={selectedMessage as IMessage}
          setMessage={handleMessageSelection}
          open={openMobileMoreMenu}
          setOpen={onCloseMobileMenu}
          onEmojiSelect={onReactToMessageWithNativeEmoji}
        />
      </div>
    </>
  );
};
