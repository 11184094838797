'use client';
import Link from 'next/link';
import { twMerge } from 'tailwind-merge';
import { IText, commonClassName, TextTypes } from './text';

interface ILink extends IText {
  href?: string;
  target?: '_blank';
  onClick?(): void;
}

export function CustomLink({
  children,
  href,
  target,
  onClick,
  type,
  className,
}: React.PropsWithChildren<ILink>) {
  const styleClass =
    twMerge(
      commonClassName,
      'inline cursor-pointer font-inter hover:primaryHover active:primaryActive',
      TextTypes[type],
      className,
    ) + ' text-primary'; // TODO: issue with color/size
  return href ? (
    <Link href={href} target={target || '_self'} className={styleClass}>
      {children}
    </Link>
  ) : (
    <button onClick={onClick} className={styleClass}>
      {children}
    </button>
  );
}
