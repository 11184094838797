'use client';
import { LoadingSkeleton } from '@ui/components';

export const LeaderboardItemLoader = () => {
  return (
    <div className="flex justify-between p-3">
      <div className="flex gap-3">
        <div className="flex flex-col justify-center gap-2">
          <LoadingSkeleton className="h-4 w-24" />
          <LoadingSkeleton className="h-3.5 w-40" />
        </div>
      </div>
      <LoadingSkeleton className="w-18 h-4" />
    </div>
  );
};
