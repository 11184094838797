import { GENERIC_FLAGGED_MESSAGE } from '@ui/components/organisms/message';
import Link from 'next/link';

type MessageBlockedProp = {
  userType: string;
};

export const MessageBlocked = ({ userType }: MessageBlockedProp) => {
  return (
    <div className="bg-korLightOrange text-korDarkGrey/30 rounded-md p-4 text-xs shadow-inner">
      {GENERIC_FLAGGED_MESSAGE}{' '}
      <Link
        className="font-bold underline"
        href={userType === 'creator' ? '/dashboard/community-guidelines' : '/community-guidelines'}>
        read guidelines
      </Link>
    </div>
  );
};
