'use client';
import { useImagePreviewContext } from '@ui/hooks/file/ImagePreviewContext';
import { IFileMeta } from '@ui/hooks/file/types';

interface IProps {
  images: IFileMeta[];
  isPinned?: boolean;
}

export const MessageItemImages = ({ images, isPinned }: IProps) => {
  const { setImagePreview } = useImagePreviewContext();

  const getWidthClass = (index: number) => {
    const length = images.length;
    if (length === 4) return index === 0 ? 'w-full max-h-[400px]' : 'w-1/3 max-h-[200px]';
    if (length === 3) return index === 0 ? 'w-full max-h-[400px]' : 'w-1/2 w-1/3 max-h-[200px]';
    if (length === 2) return 'w-1/2';
    return 'w-full max-h-[400px]';
  };

  const onClickImage = (event: any, source: string) => {
    event.preventDefault();
    event.stopPropagation();
    setImagePreview(source);
  };

  if (isPinned) {
    return (
      <div className="flex flex-wrap">
        {images?.map((image, index) => (
          <div
            key={index}
            className="flex items-center "
            onClick={(e) => onClickImage(e, image.source)}>
            <img
              src={image.source}
              alt={image.name}
              className={`h-[100px] w-[100px] cursor-pointer rounded-md  object-cover p-1
              }`}
              loading="eager"
            />
          </div>
        ))}
      </div>
    );
  }

  return (
    <div className="flex flex-wrap">
      {images?.map((image, index) => (
        <div
          key={index}
          className={`${getWidthClass(index)} ${images.length === 1 ? 'relative' : ''} p-1`}
          onClick={(e) => onClickImage(e, image.source)}>
          {images.length === 1 && (
            <div className="absolute inset-0 hidden max-h-[400px] w-full overflow-hidden sm:block">
              <img
                src={image.source}
                alt={image.name}
                className=" h-full w-full object-cover blur-md"
                loading="eager"
              />
            </div>
          )}
          <img
            src={image.source}
            alt={image.name}
            className={`h-full w-full cursor-pointer rounded ${
              images.length === 1 ? ' relative z-10 object-contain' : 'object-cover'
            }`}
            loading="eager"
          />
        </div>
      ))}
    </div>
  );
};
