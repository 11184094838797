'use client';
import {
  createContext,
  useContext,
  useState,
  ReactNode,
  useCallback,
  useMemo,
  useEffect,
} from 'react';
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogCancel,
  AlertDialogFooter,
  AlertDialogAction,
  AlertDialogTitle,
  AlertDialogDescription,
  IMessage,
  IMessagesQueryParams,
} from '@ui/components';
import { useRouter } from 'next/navigation';

type TMessage = IMessage & {
  type: 'post' | 'comment' | 'reply';
  isPost: boolean;
  targetRef: HTMLDivElement;
  query: IMessagesQueryParams;
};

type MessageUpdateContextType = {
  targetMessage: TMessage | null;
  uniqueFlag: string | null;
  setTargetMessage: (targetMessage: TMessage | null) => void;
  setUniqueFlag: (flag: string | null) => void;
};

const Context = createContext<MessageUpdateContextType | undefined>(undefined);

type ProviderProps = {
  children: ReactNode;
};

export const MessageUpdateFormProvider = ({ children }: ProviderProps) => {
  const [targetMessage, setTargetMessage] = useState<TMessage | null>(null);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [uniqueFlag, setUniqueFlag] = useState<string | null>(null);
  const router = useRouter();

  const handleSetTargetMessage = useCallback(
    (newMessage: TMessage | null) => {
      if (uniqueFlag && newMessage) {
        setShowConfirmDialog(true);
      } else {
        setTargetMessage(newMessage);
        setUniqueFlag(newMessage?.type ?? null);
      }
    },
    [uniqueFlag],
  );

  const handleConfirmDiscard = useCallback(() => {
    setTargetMessage(null);
    setUniqueFlag(null);
    setShowConfirmDialog(false);
  }, []);

  const handleCancelDiscard = useCallback(() => {
    setShowConfirmDialog(false);
  }, []);

  const value = useMemo(
    () => ({
      targetMessage,
      setTargetMessage: handleSetTargetMessage,
      showConfirmDialog,
      handleConfirmDiscard,
      handleCancelDiscard,
      setShowConfirmDialog,
      uniqueFlag,
      setUniqueFlag,
    }),
    [
      targetMessage,
      handleSetTargetMessage,
      showConfirmDialog,
      handleConfirmDiscard,
      handleCancelDiscard,
      uniqueFlag,
    ],
  );

  useEffect(() => {
    return () => {
      setTargetMessage(null);
      setUniqueFlag(null);
    };
  }, [router]);

  return (
    <Context.Provider value={value}>
      {children}
      <AlertDialog open={showConfirmDialog} onOpenChange={setShowConfirmDialog}>
        <AlertDialogContent>
          <AlertDialogTitle>Discard Changes?</AlertDialogTitle>
          <AlertDialogDescription>
            You will lose any unsaved changes to your message.
          </AlertDialogDescription>
          <AlertDialogFooter>
            <AlertDialogCancel onClick={handleCancelDiscard}>Keep Editing</AlertDialogCancel>
            <AlertDialogAction
              onClick={handleConfirmDiscard}
              className="bg-primary hover:bg-primaryHover">
              Discard
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </Context.Provider>
  );
};

export const useMessageUpdateFormContext = (): MessageUpdateContextType => {
  const context = useContext(Context);
  if (context === undefined) {
    throw new Error('useMessageUpdateContext must be used within a MessageUpdateProvider');
  }
  return context;
};
