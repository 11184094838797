'use client';
import dayjs from 'dayjs';
import { useCallback, useEffect, useState } from 'react';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);
export const MESSAGE_QUERY_KEYS = {
  messages: 'messages',
  singleMessage: 'single-message',
};

export const formatMessageDate = (updated_at: string): string => {
  const date = dayjs(updated_at);
  const isToday = date.isSame(dayjs(), 'day');

  if (isToday) {
    return date.format('h:mm A'); // 12-hour format with AM/PM
  }

  const currentYear = dayjs().year();
  return date.year() === currentYear ? date.format('MMM DD') : date.format('MMM DD, YYYY');
};

export const formattedDateWithDateAgo = (date: string) => {
  const now = dayjs();
  const inputDate = dayjs(date);

  // If the date is less than 24 hours ago
  if (now.diff(inputDate, 'hour') < 24) {
    return inputDate.fromNow();
  }

  // If the date is not within the current year
  if (inputDate.year() !== now.year()) {
    return inputDate.format('MMM DD, YYYY');
  }

  // If the date is past 24 hours but within the current year
  return inputDate.format('MMM DD');
};

export const useLongPress = (callback: () => void, ms: number = 300) => {
  const [startLongPress, setStartLongPress] = useState(false);

  useEffect(() => {
    let timerId: NodeJS.Timeout | undefined;
    if (startLongPress) {
      timerId = setTimeout(callback, ms);
    } else {
      if (timerId) {
        clearTimeout(timerId);
      }
    }

    return () => {
      if (timerId) {
        clearTimeout(timerId);
      }
    };
  }, [startLongPress, callback, ms]);

  const start = useCallback(() => setStartLongPress(true), []);
  const stop = useCallback(() => setStartLongPress(false), []);

  return {
    onMouseDown: start,
    onMouseUp: stop,
    onMouseLeave: stop,
    onTouchStart: start,
    onTouchEnd: stop,
  };
};

export const FLAGGED_MESSAGE_CATEGORIES = [
  'Nudity or sexual activity',
  'Hate speech or symbols',
  'Violence or dangerous organizations',
  'Bullying or harassment',
  'False information',
  'Spam',
  'Terrorism',
  'Child abuse',
  'Suicide or self-injury',
  'Animal abuse',
  'Copyright infringement',
  'Sale of illegal goods',
  'Scam or fraud',
  'Intellectual property violation',
  'Impersonation',
  // "Other"
];

export const COMMON_REACTIONS = ['❤️', '😂', '😩', '😱', '🔥', '🤩', '🙏'];

export const GENERIC_FLAGGED_MESSAGE =
  'This message has been flagged for violating our community policy and is no longer available.';
