export const Challenge = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M14.5 17.5L3 6V3H6L17.5 14.5"
      stroke={props.stroke || '#2D3139'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13 19L19 13"
      stroke={props.stroke || '#2D3139'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16 16L20 20"
      stroke={props.stroke || '#2D3139'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19 21L21 19"
      stroke={props.stroke || '#2D3139'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.5 6.5L18 3H21V6L17.5 9.5"
      stroke={props.stroke || '#2D3139'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5 14L9 18"
      stroke={props.stroke || '#2D3139'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7 17L4 20"
      stroke={props.stroke || '#2D3139'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3 19L5 21"
      stroke={props.stroke || '#2D3139'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
