import React, { useMemo } from 'react';
import { IMessage } from '../../message.typings';
import { ContextUserType, useMessageContext } from '@ui/components';
import { twMerge } from 'tailwind-merge';

interface IProps {
  message: IMessage;
  contextUserType: ContextUserType;
}

export const MessageItemReactions: React.FC<IProps> = React.memo(({ message, contextUserType }) => {
  const messageState = useMessageContext(contextUserType);

  const onReactToMessage = (emoji: string) => {
    messageState.onReactToMessage(message.id, message.slug, 'reaction', emoji, messageState.query);
  };

  const reactionList = useMemo(() => {
    if (!message.reaction || !message.reaction.reactions_list) {
      return null;
    }

    return Object.entries(message.reaction.reactions_list).map(([emoji, count]) => (
      <div
        onClick={() => onReactToMessage(emoji)}
        key={emoji}
        className="flex cursor-pointer flex-row items-center space-x-1 rounded-full border bg-white p-1 text-xs">
        <span>{emoji}</span>
        {count > 1 && <span className="text-xs font-medium">{count}</span>}
      </div>
    ));
  }, [message.reaction]);

  if (!reactionList) {
    return null;
  }

  return (
    <div
      className={twMerge(
        'flex max-w-[300px] flex-wrap -space-x-1',
        reactionList.length ? '-mt-1' : '',
      )}>
      {reactionList}
    </div>
  );
});

MessageItemReactions.displayName = 'MessageItemReactions';
