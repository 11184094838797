'use client';
import { Button, Icon, Input } from '@ui/components';
import { createContext, useContext, useState, useEffect, ReactNode, HTMLAttributes } from 'react';
import { twMerge } from 'tailwind-merge';

interface AdaptiveFlexPanelContextProps {
  selectedItemId: string | null;
  handleItemClick: (itemId: string) => void;
  handleBackClick: () => void;
  showBackButton?: boolean;
}

const AdaptiveFlexPanelContext = createContext<AdaptiveFlexPanelContextProps | undefined>(
  undefined,
);

const useAdaptiveFlexPanelContext = () => {
  const context = useContext(AdaptiveFlexPanelContext);
  if (!context) {
    throw new Error('useAdaptiveFlexPanelContext must be used within an AdaptiveFlexPanelProvider');
  }
  return context;
};

interface AdaptiveFlexPanelProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
  selectedItemId?: string | null;
  onItemSelected?: (itemId: string) => void;
  onBack?: () => void;
  showBackButton?: boolean;
}

export const AdaptiveFlexPanel = ({
  className,
  children,
  selectedItemId: controlledSelectedItemId,
  onItemSelected,
  onBack,
  showBackButton,
}: AdaptiveFlexPanelProps) => {
  const [selectedItemId, setSelectedItemId] = useState<string | null>(
    controlledSelectedItemId || null,
  );

  useEffect(() => {
    if (controlledSelectedItemId !== undefined) {
      setSelectedItemId(controlledSelectedItemId);
    }
  }, [controlledSelectedItemId]);

  const handleItemClick = (itemId: string) => {
    setSelectedItemId(itemId);
    if (onItemSelected) {
      onItemSelected(itemId);
    }
  };

  const handleBackClick = () => {
    setSelectedItemId(null);
    if (onBack) {
      onBack();
    }
  };

  return (
    <AdaptiveFlexPanelContext.Provider
      value={{ selectedItemId, handleItemClick, handleBackClick, showBackButton }}>
      <div
        className={twMerge(
          'border-t-border flex h-full max-h-[73.5vh] w-full flex-row border-t  border-opacity-70',
          className,
        )}>
        {children}
      </div>
    </AdaptiveFlexPanelContext.Provider>
  );
};

interface AdaptiveFlexLeftPaneProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
}

export const AdaptiveFlexLeftPane = ({ className, children }: AdaptiveFlexLeftPaneProps) => {
  const { selectedItemId } = useAdaptiveFlexPanelContext();

  return (
    <div
      className={twMerge(
        `border-r-border h-full w-full border-r border-opacity-70 p-4 lg:w-1/3 ${
          selectedItemId ? 'hidden lg:block' : 'block'
        }`,
        className,
      )}>
      {children}
    </div>
  );
};

interface AdaptiveFlexLeftPaneItemProps extends HTMLAttributes<HTMLDivElement> {
  itemId: string;
  children: ReactNode;
  onClick: () => void;
}

export const AdaptiveFlexLeftPaneItem = ({
  className,
  itemId,
  children,
  onClick,
}: AdaptiveFlexLeftPaneItemProps) => {
  const { selectedItemId, handleItemClick } = useAdaptiveFlexPanelContext();
  const isActive = selectedItemId === itemId;

  return (
    <div
      className={twMerge(
        'hover:bg-input text-korDarkGrey bg-input/50 w-full cursor-pointer rounded-lg p-4',
        isActive ? ' bg-tabActive hover:bg-tabActive text-primary' : '',
        className,
      )}
      onClick={() => {
        handleItemClick(itemId);
        onClick();
      }}>
      {children}
    </div>
  );
};

interface AdaptiveFlexRightPaneProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
  id?: string;
}

export const AdaptiveFlexRightPane = ({ className, children, id }: AdaptiveFlexRightPaneProps) => {
  const { selectedItemId, handleBackClick, showBackButton } = useAdaptiveFlexPanelContext();
  const defaultBgFace = !children ? 'bg-input ' : '';

  return (
    <div className={twMerge('w-full p-4 lg:w-2/3', defaultBgFace, className)} id={id}>
      {selectedItemId ? (
        <>
          {showBackButton && (
            <div className="mb-4 block lg:hidden">
              <button onClick={handleBackClick}>Back</button>
            </div>
          )}
          {children}
        </>
      ) : (
        children
      )}
    </div>
  );
};

interface AdaptiveFlexLeftPaneSearchAndButtonProps extends HTMLAttributes<HTMLDivElement> {
  onSearch?: (query: string) => void;
  onAddButtonClick?: () => void;
  placeholder?: string;
  customButton?: ReactNode;
}

export const AdaptiveFlexLeftPaneSearchAndButton = ({
  onSearch,
  onAddButtonClick,
  placeholder = 'Search...',
  customButton,
  className,
}: AdaptiveFlexLeftPaneSearchAndButtonProps) => {
  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onSearch) {
      onSearch(event.target.value);
    }
  };

  return (
    <div className={twMerge('flex justify-between gap-2', className)}>
      <Input
        beforeInputComponent={<Icon type="search" />}
        placeholder={placeholder}
        onChange={handleSearch}
        inputClassName="flex items-center"
        isHidePadding={true}
      />
      {customButton ? (
        customButton
      ) : (
        <Button
          onClick={onAddButtonClick}
          className="min-w-fit rounded"
          type="primary"
          size="smallIcon">
          <Icon type="plus" />
        </Button>
      )}
    </div>
  );
};

interface AdaptiveFlexLeftPaneStatusBadgeProps extends HTMLAttributes<HTMLDivElement> {
  filters: string[];
  onClickFilter: (status: string) => void;
  selected: string;
}

export const AdaptiveFlexLeftPaneStatusBadge = ({
  filters = [],
  onClickFilter,
  selected,
  className,
}: AdaptiveFlexLeftPaneStatusBadgeProps) => {
  return (
    <div className={twMerge('flex gap-1 overflow-x-auto', className)}>
      {filters.map((status, index) => (
        <Button
          key={index}
          onClick={() => onClickFilter(status)}
          type={status === selected ? 'primary' : 'secondary'}
          size="small"
          className="w-fit min-w-fit text-xs">
          {status}
        </Button>
      ))}
    </div>
  );
};
