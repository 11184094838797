export const MessageNotification = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <circle cx="10" cy="10" r="10" fill="#C89648" />
    <path
      d="M9.825 4.75H5.91667C5.60725 4.75 5.3105 4.87292 5.09171 5.09171C4.87292 5.3105 4.75 5.60725 4.75 5.91667V15.25L7.08333 12.9167H14.0833C14.3928 12.9167 14.6895 12.7937 14.9083 12.575C15.1271 12.3562 15.25 12.0594 15.25 11.75V10.175"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.5 8.25C14.4665 8.25 15.25 7.4665 15.25 6.5C15.25 5.5335 14.4665 4.75 13.5 4.75C12.5335 4.75 11.75 5.5335 11.75 6.5C11.75 7.4665 12.5335 8.25 13.5 8.25Z"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
