export const DownloadIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
    <path
      d="M11.8789 14.791V2.75"
      stroke={props.stroke || '##FF600D'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.7929 11.8643L11.8769 14.7923L8.96094 11.8643"
      stroke={props.stroke || '##FF600D'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.37 7.25879C19.949 7.58879 21.25 8.92879 21.25 14.2588C21.25 21.3588 18.939 21.3588 12 21.3588C5.059 21.3588 2.75 21.3588 2.75 14.2588C2.75 8.92879 4.05 7.58879 7.63 7.25879"
      stroke="#FF600D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
