export const CalendarIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
    <path
      stroke="#707B8F"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M2.75 12.775c0-6.956 2.319-9.274 9.274-9.274 6.956 0 9.275 2.318 9.275 9.274 0 6.956-2.319 9.274-9.275 9.274-6.955 0-9.274-2.318-9.274-9.274Z"
      clipRule="evenodd"
    />
    <path
      stroke="#707B8F"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M3.025 9.323h18.008M16.43 13.26h.008M12.03 13.26h.009M7.621 13.26h.01M16.43 17.112h.008M12.029 17.112h.009M7.621 17.112h.01M16.033 2.05v3.262M8.024 2.05v3.262"
    />
  </svg>
);
